import { api } from "../../../axios/api";

export const fetchAll = async (filter) => {
  return api.get(`gestion-attestations/companies/orders`, {
    params: {
      page: filter?.page || 1,
    },
  });
};

export const fetchOne = async (id) => {
  return api.get(`gestion-attestations/companies/orders/${id}`);
};
export const create = async (data) => {
  return api.post(`gestion-attestations/companies/orders`, data);
};

export const update = async (id, data) => {
  return api.put(`gestion-attestations/companies/orders/${id}`, data);
};

export const deleteOne = async (id) => {
  return api.delete(`gestion-attestations/companies/orders/${id}`);
};
