import React, { useEffect, useState } from "react";
import Page from "../../../../../../components/Page";
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Button,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Segment,
  Table,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import * as garantiesServices from "../../../../../../services/gestion_production/garantiesServices";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import GarantiesModalForm from "../../../../../../components/modalForms/gestion_production/pool/GarantiesModalForm";
import { Link } from "react-router-dom";

function GarantiesListPage() {
  const [garanties, setGaranties] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemUuid, setSelectedItemUuid] = useState(null);

  const getGaranties = () => {
    garantiesServices
      .getAll()
      .then((res) => {
        setGaranties(res.data);
      })
      .catch((err) => {
        toast.error("Opération échouée");
      });
  };

  const handleRemoveClick = (uuid) => {
    Swal.fire({
      title: "Etes-vous sûr?",
      text: "Vous ne pourrez pas revenir en arrière!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer!",
    }).then((result) => {
      if (result.isConfirmed) {
        garantiesServices.deleteOne(uuid).then(
          (res) => {
            getGaranties();
            toast.success("Opération réussie");
          },
          (err) => {
            toast.error("Opération échouée");
          }
        );
      }
    });
  };

  const handleEditClick = (uuid) => {
    setSelectedItemUuid(uuid);
    setIsModalOpen(true);
  };

  useEffect(() => {
    getGaranties();
  }, []);

  return (
    <Page title="Liste des garanties">
      <GridRow className="Souscriptions">
        <Grid.Row className="!mb-5">
          <Grid.Column width={16}>
            <Breadcrumb>
              <BreadcrumbSection link>Pool</BreadcrumbSection>
              <BreadcrumbDivider icon="right chevron" />
              <BreadcrumbSection link>
                Gestion de la production
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection link>
                Paramètres de tarifications
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection active>Liste des garanties</BreadcrumbSection>
            </Breadcrumb>
            <Divider />
          </Grid.Column>
        </Grid.Row>
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid>
              <Grid.Column>
                <Header>
                  <Header.Content>Liste des garanties</Header.Content>
                </Header>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              gap: "10px",
            }}
          >
            <Button
              onClick={() => {
                setSelectedItemUuid(null);
                setIsModalOpen(true);
              }}
            >
              <Icon name="add" />
              Ajouter
            </Button>
          </Grid.Column>
        </Grid>
        <GridRow>
          <Grid.Column className="!mt-6">
            <Segment>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell collapsing>#</Table.HeaderCell>
                    <Table.HeaderCell>Nom de la garantie</Table.HeaderCell>
                    <Table.HeaderCell>Date de création</Table.HeaderCell>
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {garanties?.map((garantie, index) => (
                    <Table.Row key={index}>
                      <Table.Cell collapsing>{index + 1}</Table.Cell>
                      <Table.Cell>{garantie.name}</Table.Cell>
                      <Table.Cell>
                        {dayjs(garantie.created_at).format("DD/MM/YYYY HH:mm")}
                      </Table.Cell>
                      <Table.Cell collapsing>
                        <Dropdown
                          pointing="right"
                          icon={<Icon name="ellipsis vertical" />}
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item
                              icon={<Icon name="edit" />}
                              content="Editer"
                              onClick={() => {
                                handleEditClick(garantie.uuid);
                              }}
                            />
                            <Dropdown.Item
                              icon={<Icon name="eye" />}
                              content="Détails"
                              as={Link}
                              to={`/pool/production/parametres-tarification/liste-garanties/${garantie.uuid}`}
                            />
                            <>
                              <Dropdown.Item
                                icon={<Icon name="trash" />}
                                content="Supprimer"
                                onClick={() => {
                                  handleRemoveClick(garantie.uuid);
                                }}
                              />
                            </>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Segment>
          </Grid.Column>
        </GridRow>
      </GridRow>
      <GarantiesModalForm
        selectedItemId={selectedItemUuid}
        setOpen={setIsModalOpen}
        open={isModalOpen}
        callback={() => {
          setIsModalOpen(false);
          setSelectedItemUuid(null);
          getGaranties();
        }}
        handleCloseModal={() => {
          setIsModalOpen(false);
          setSelectedItemUuid(null);
        }}
      />
    </Page>
  );
}

export default GarantiesListPage;
