import { api } from "../axios/api";

export const fetchAll = async () => {
  return api.get(`zones-circulation`);
};

export const fetchOne = async (id) => {
  return api.get(`zones-circulation/${id}`);
};
export const create = async (data) => {
  return api.post(`zones-circulation`, data);
};

