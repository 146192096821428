import { api } from "../axios/api";

export const fetchAll = async () => {
  return api.get(`compagnies`);
};

export const fetchOne = async (id) => {
  return api.get(`compagnies/${id}`);
};

export const fetchOwner = async () => {
  return api.get(`compagnies/details/owner`);
};

export const fetchDetails = async (id) => {
  return api.get(`compagnies/details/${id}`);
};

export const create = async (data) => {
  return api.post(`compagnies`, data);
};
export const addUser = async (data) => {
  return api.post(`compagnies/user/add`, data);
};
