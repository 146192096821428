import React from "react";
import { Form, Input, Segment } from "semantic-ui-react";
import { LIENS_SOUSCRIPEUR_ASSURE } from "../../../../constants";

function SouscripteurSection({
  formik,
  selectedSouscripteur,
  setOpenSelectSouscripteurModal,
  categoriesContrats,
}) {
  const { values, errors, setFieldValue, handleChange, handleBlur } = formik;
  return (
    <Segment>
      <Form.Group widths={"equal"}>
        <Form.Field required error={"idsouscripteurs" in errors}>
          <label>
            Souscripteur
            {selectedSouscripteur ? `(${selectedSouscripteur?.num})` : ""}
          </label>
          <Form.Input
            value={
              selectedSouscripteur
                ? selectedSouscripteur?.groupe_souscripteur === "ENTREPRISE"
                  ? selectedSouscripteur?.denomination
                  : `${selectedSouscripteur?.last_name} ${selectedSouscripteur?.first_name}`
                : ""
            }
            readOnly
            onClick={() => setOpenSelectSouscripteurModal(true)}
          />
        </Form.Field>
        <Form.Field required error={"lien_assure" in errors}>
          <label>Lien avec l'assuré </label>
          <Form.Select
            readOnly
            value={values.lien_assure}
            onChange={(e, data) => {
              if (isNaN(parseInt(values.idsouscripteurs))) {
                e.preventDefault();
              }
              setFieldValue("lien_assure", data.value, true);
            }}
            options={LIENS_SOUSCRIPEUR_ASSURE}
          />
          <small className="field-error">{errors.lien_assure}</small>
        </Form.Field>

        <Form.Field required error={"idcategories_contrats" in errors}>
          <label>Catégorie </label>
          <Form.Select
            readOnly
            value={values.idcategories_contrats}
            onChange={(e, data) =>
              setFieldValue("idcategories_contrats", data.value, true)
            }
            options={categoriesContrats.map((cat) => ({
              key: `${cat.idcategories_contrats}`,
              text: cat.libelle,
              value: cat.idcategories_contrats,
            }))}
          />
          <small className="field-error">{errors.idcategories_contrats}</small>
        </Form.Field>
      </Form.Group>
      <Form.Group widths={"equal"}>
        <Form.Field error={"numero_police" in errors}>
          <label>Police</label>
          <Input
            readOnly={true}
            placeholder="Autogénéré"
            value={values.numero_police}
            onChange={handleChange("numero_police")}
            onBlur={handleBlur("numero_police")}
          />
          <small className="field-error">{errors.numero_police}</small>
        </Form.Field>
        <Form.Field error={"numero_proposition" in errors}>
          <label>Numéro de proposition</label>
          <Input
            readOnly={true}
            placeholder="Autogénéré"
            value={values.numero_proposition}
            onChange={handleChange("numero_proposition")}
            onBlur={handleBlur("numero_proposition")}
          />
          <small className="field-error">{errors.numero_proposition}</small>
        </Form.Field>
        <Form.Field error={"date_souscription" in errors}>
          <label>Date de souscription</label>
          <Input
            type="date"
            value={values.date_souscription}
            onChange={handleChange("date_souscription")}
            onBlur={handleBlur("date_souscription")}
          />
          <small className="field-error">{errors.date_souscription}</small>
        </Form.Field>
      </Form.Group>
    </Segment>
  );
}

export default SouscripteurSection;
