import React, { useEffect } from "react";
import {
  Button,
  Divider,
  Form,
  Header,
  List,
  ListDescription,
  ListHeader,
  ListItem,
  Modal,
  Table,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as deliveriesService from "../../../services/gestion_attestations/pool/deliveriesService";
import * as poolStockService from "../../../services/gestion_attestations/pool/stockService";
import dayjs from "dayjs";

const validationSchema = yup.object().shape({
  start_serial: yup.string().required("Le numéro de série de début est requis"),
  end_serial: yup
    .string()
    .required("Le numéro de série de fin est requis")
    .test(
      "end_serial",
      "La série de fin doit être supérieure à la série de début",
      function (value) {
        const startSerial = this.parent.start_serial;
        return startSerial && value >= startSerial;
      }
    ),
});
function DeliveryAttestationFormModal({
  callback = () => {},
  open = false,
  setOpen,
  selectedItemId,
  orderId = null,
  handleCloseModal = () => {},
}) {
  const [stock, setStock] = React.useState(null);
  const qtyInputRef = React.useRef(null);
  const formik = useFormik({
    initialValues: {
      start_serial: "",
      end_serial: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (!selectedItemId) {
        values.idattestations_stocks =
          stock?.attestations_stocks?.idattestations_stocks;
      }

      const request = selectedItemId
        ? deliveriesService.update(selectedItemId, values)
        : deliveriesService.create(values);

      request
        .then(() => {
          toast.success(
            `Livraison d'attestation ${
              selectedItemId ? "mise à jour" : "ajoutée"
            }`
          );
          handleCloseModal();
          callback();
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.error ||
              `Une erreur est survenue lors de ${
                selectedItemId ? "la mise à jour" : "l'ajout"
              } de la livraison d'attestation`
          );
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
    validateOnBlur: false,
    validateOnChange: false,
  });

  const { values, errors, handleSubmit, isSubmitting } = formik;

  const fetchStock = (orderId) => {
    poolStockService
      .fetchOnebyOrder(orderId)
      .then((response) => {
        if (typeof response.data === "object") {
          const data = response.data;
          setStock(data);
          if (!selectedItemId) {
            formik.setValues({
              start_serial: data?.usage?.available_start_serial,
            });
          }
        }
      })
      .catch(() => {
        toast.error(
          "Une erreur est survenue lors de la récupération des informations"
        );
      })
      .finally(() => {});
  };

  const fetchDelivery = (selectedItemId) => {
    deliveriesService
      .fetchOne(selectedItemId)
      .then((response) => {
        if (typeof response.data === "object") {
          const data = response.data;
          formik.setValues({
            start_serial: data.start_serial,
            end_serial: data.end_serial,
          });
        }
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.error ||
            "Une erreur est survenue lors de la récupération des informations"
        );
      })
      .finally(() => {});
  };

  useEffect(() => {
    formik.resetForm();
    if (open && orderId) fetchStock(orderId);
  }, [open, orderId]);

  useEffect(() => {
    if (selectedItemId) fetchDelivery(selectedItemId);
  }, [selectedItemId]);

  return (
    <Modal
      onClose={() => {
        handleCloseModal();
      }}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      size="tiny"
      closeIcon
      onUnmount={() => {
        formik.resetForm();
        setStock(null);
      }}
    >
      <Modal.Header>
        {selectedItemId ? "Modifier" : "Ajouter"} une livraison d'attestation
      </Modal.Header>
      <Modal.Content>
        <Modal.Description className="ui form">
          <List horizontal className="w-full">
            <ListItem className="w-[44%]">
              <ListHeader as="a">Quantité disponible</ListHeader>
              <ListDescription className="mt-2">
                {new Intl.NumberFormat().format(
                  stock?.usage?.remainingQty || 0
                )}
              </ListDescription>
            </ListItem>
            <ListItem className="w-[44%] !text-right">
              <ListHeader as="a">Plage disponible</ListHeader>
              <ListDescription className="mt-2">
                {stock?.usage?.remainingQty === 0
                  ? "Aucune plage disponible"
                  : `${stock?.usage?.available_start_serial} - ${stock?.usage?.available_end_serial}`}
              </ListDescription>
            </ListItem>
          </List>
          <Divider />
          <Header as="h6" className="!text-gray-500">
            Dernière livraison
          </Header>
          <Table basic="very" className="!border-none" size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Qté</Table.HeaderCell>
                <Table.HeaderCell>Série</Table.HeaderCell>
                <Table.HeaderCell>Date</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {stock?.last_delivery ? (
                <Table.Row>
                  <Table.Cell>
                    {new Intl.NumberFormat().format(
                      Number(stock?.last_delivery?.end_serial) -
                        Number(stock?.last_delivery?.start_serial) +
                        1
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {`${stock?.last_delivery?.start_serial} - ${stock?.last_delivery?.end_serial}`}
                  </Table.Cell>
                  <Table.Cell>
                    {dayjs(stock?.last_delivery?.created_at).format(
                      "DD/MM/YYYY"
                    )}
                  </Table.Cell>
                </Table.Row>
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={3} className="text-center">
                    Aucune livraison
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
          <Divider />
          <Form.Field width={8}>
            <label>Quantité d'attestations</label>
            <input
              type="number"
              ref={qtyInputRef}
              onChange={(e) => {
                const value = e.target.value;
                if (!isNaN(value)) {
                  formik.setFieldValue(
                    "end_serial",
                    (Number(value) + Number(formik.values.start_serial) - 1)
                      .toString()
                      .padStart(formik.values.start_serial.length, "0")
                  );
                }
              }}
            />
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Input
              required
              label="Série de début"
              placeholder="Série de début"
              name="start_serial"
              value={values.start_serial}
              onChange={(e) => {
                const value = e.target.value;
                if (!isNaN(value)) {
                  formik.setFieldValue("start_serial", value);
                }
                qtyInputRef.current.value = "";
              }}
              error={errors.start_serial}
              min={stock?.usage?.available_start_serial}
              max={stock?.usage?.available_end_serial}
              type="text"
            />
            <Form.Input
              required
              label="Série de fin"
              placeholder="Série de fin"
              name="end_serial"
              value={values.end_serial}
              onChange={(e) => {
                const value = e.target.value;
                if (!isNaN(value)) {
                  formik.setFieldValue("end_serial", value);
                }
                qtyInputRef.current.value = "";
              }}
              error={errors.end_serial}
              max={stock?.usage?.available_end_serial}
              type="text"
            />
          </Form.Group>
          {!isNaN(Number(values.start_serial)) &&
            !isNaN(Number(values.end_serial)) &&
            Number(values.start_serial) <= Number(values.end_serial) && (
              <div className="text-right font-bold text-gray-500">
                Quantité d'attestations:{" "}
                {new Intl.NumberFormat().format(
                  Number(values.end_serial) - Number(values.start_serial) + 1
                )}
              </div>
            )}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          onClick={() => {
            handleCloseModal();
          }}
        >
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          type="submit"
          loading={isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default DeliveryAttestationFormModal;
