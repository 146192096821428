import { api } from "../axios/api";

export const fetchAll = async () => {
  return api.get(`countries`);
};
export const fetchOne = async (id) => {
  return api.get(`countries/${id}`);
};
export const create = async (data) => {
  return api.post(`countries`, data);
};
