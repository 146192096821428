import React, { useState, useEffect } from "react";
import { Form, GridRow, Header, Tab } from "semantic-ui-react";

import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import IndentiteVehicule from "./components/IndentiteVehicule";
import CalculCommission from "./components/CalculCommission";
import ReferencesConditions from "./components/ReferencesConditions";
import * as categoriesContratsService from "../../../services/categoriesContratsService";
import SelectSouscriptorModal from "./components/SelectSouscriptorModal";
import AssurePane from "./components/AssurePane";
import * as souscriptionsService from "../../../services/souscriptionsService";
import * as directivesTarifaireValuesService from "../../../services/gestion_production/directivesTarifairesValueService";
import * as companyGarantiesService from "../../../services/gestion_production/company/garantiesService";
import * as poolInsuranceDurationService from "../../../services/gestion_production/pool/insurancesDurationsService";
import * as tableauGarantiesRcService from "../../../services/gestion_production/tableauGarantiesRcService";
import * as garantiesComplementairesService from "../../../services/gestion_production/company/garantiesComplementairesService";
import * as avariesFacultativesService from "../../../services/gestion_production/pool/avariesFacultativesService";
import * as companyDirectiveValuesService from "../../../services/gestion_production/company/directivesTarifairesValueService";

import GarantiesFacultatives from "./components/GarantiesFacultatives";
import { useRecoilValue } from "recoil";
import { authState } from "../../../atoms/authState";
import SouscripteurSection from "./components/SouscripteurSection";
import GarantiesSection from "./components/GarantiesSection";
import SommaireSection from "./components/SommaireSection";
import { calculateTaxes } from "../../../utils/souscription_helper";
import useCalculateSouscription from "../../../hooks/souscription/useCalculateSouscription";

const validationSchema = yup.object().shape({
  idsouscriptions: yup.number().notRequired(),
  idsouscripteurs: yup.number().required("valeur requise"),
  idcategories_contrats: yup.number().required("valeur requise"),
  idsources_energie: yup.number().required("valeur requise"),
  date_souscription: yup.date().nullable().default(null),
  date_echeance: yup.date().required("valeur requise"),
  lien_assure: yup.string().required("valeur requise"),
  avec_franchise: yup.boolean(),
  montant_commission: yup.number(),
  prime_nette: yup.number(),
  prime_nette_totale: yup.number(),
  prime_nette_iac: yup.number(),
  cout_police: yup.number(),
  taxes: yup.number(),
  montant_gestion_pool: yup.number(),
  carte_rose: yup.number(),
  droit_de_timbre_automobile: yup.number(),
  garanties: yup.array().required("valeur requise").min(1),
  assure: yup
    .object({
      prime_ttc: yup.number(),
      nom_assure: yup.string().required("valeur requise").min(4).max(255),
      phone_assure: yup.string().required("valeur requise").min(7).max(12),
      email_assure: yup.string().email().max(255),
      address_assure: yup.string().max(255),
      idjobs: yup.number(),
    })
    .required("valeur requise"),
  conditions_particulieres: yup
    .object({
      date_echeance: yup.date().required("valeur requise"),
      date_effet: yup.date().required("valeur requise"),
      date_proposition: yup.date().required("valeur requise"),
      type_renouvellement: yup.string().required("valeur requise"),
      type_avenant: yup.string().max(20).required("valeur requise"),
      pool_avaries_facultatives_uuid: yup.string().uuid().nullable(),
    })
    .required("valeur requise"),
  identite_vehicule: yup.object({
    usage_vehicule: yup
      .string()
      .oneOf(["TRAPUBVOY"])
      .required("valeur requise"),
    puissance_fiscale: yup.number().min(1).required("valeur requise"),
    nombre_places: yup.number().min(3).max(100).required("valeur requise"),
    marque_vehicule: yup.string().required("valeur requise"),
    type_vehicule: yup.string().required("valeur requise"),
    genre_vehicule: yup.string().required("valeur requise"),
    date_premiere_mise_en_circulation: yup.date(),
    valeur_a_neuf: yup.number(),
    valeur_venale: yup.number(),
    immatriculation_vehicule: yup.string().required("valeur requise"),
    immatriculation_vehicule_precedente: yup.string(),
    numero_chassis: yup.string().required("valeur requise"),
    numero_permis_conduire: yup.string().required("valeur requise"),
    numero_police: yup.string(),
    date_delivrance_permis: yup.date().required("valeur requise"),
    idzones_circulation: yup.number().required("valeur requise"),
    carrosserie: yup.string().required("valeur requise"),
  }),
  garanties_complementaires: yup.array(
    yup.object({
      uuid: yup.string().required("valeur requise"),
      value: yup.number().min(0).required("valeur requise").nullable(),
    })
  ),
});

function SouscriptionMonoForm() {
  const [categoriesContrats, setCategoriesContrats] = useState([]);
  const [selectedSouscripteur, setSelectedSouscripteur] = useState();
  const [openSelectSouscripteurModal, setOpenSelectSouscripteurModal] =
    useState(false);

  const [directivesTarifairesValues, setDirectivesTarifairesValues] = useState(
    []
  );

  const [companyGaranties, setCompanyGaranties] = useState([]);
  const [loadCompanyGaranties, setLoadCompanyGaranties] = useState(false);
  const [insuranceDurations, setInsuranceDurations] = useState([]);
  const [garantiesComplementaires, setGarantiesComplementaires] = useState([]);
  const [avariesFacultatives, setAvariesFacultatives] = useState([]);
  const [companyDirectiveValues, setCompanyDirectiveValues] = useState([]);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      idsouscriptions: "",
      idsouscripteurs: "",
      avec_franchise: false,
      numero_police: "",
      idcategories_contrats: 1,
      montant_commission: 0,
      prime_nette_iac: 0,
      prime_nette: 0,
      prime_nette_totale: 0,
      cout_police: 0,
      taxes: 0,
      montant_gestion_pool: 0,
      carte_rose: 0,
      droit_de_timbre_automobile: 0,
      prime_ttc: 0,
      date_souscription: "",
      assure: {
        nom_assure: "",
        lien_assure: "",
        phone_assure: "",
        email_assure: "",
        address_assure: "",
        idjobs: "",
      },
      garanties: [],
      conditions_particulieres: {
        date_proposition: dayjs().format("YYYY-MM-DD HH:mm"),
        date_echeance: dayjs()
          .add(12, "months")
          .add(-1, "day")
          .format("YYYY-MM-DD HH:mm"),
        date_effet: dayjs().format("YYYY-MM-DD HH:mm"),
        type_avenant: "AFFAIRE_NOUVELLE",
        type_renouvellement: "",
        pool_avaries_facultatives_uuid: "",
      },
      identite_vehicule: {
        idzones_circulation: "",
        idsources_energie: "",
        usage_vehicule: "TRAPUBVOY",
        puissance_fiscale: "",
        nombre_places: "",
        marque_vehicule: "",
        type_vehicule: "",
        genre_vehicule: "",
        date_premiere_mise_en_circulation: "",
        valeur_a_neuf: "",
        valeur_venale: "",
        immatriculation_vehicule: "",
        immatriculation_vehicule_precedente: "",
        numero_chassis: "",
        numero_permis_conduire: "",
        date_delivrance_permis: "",
        carrosserie: "",
      },
      garanties_complementaires: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      souscriptionsService
        .create(values)
        .then(
          () => {
            toast(`Souscription enregistrée avec succès!.`, {
              type: "success",
              theme: "colored",
            });
            navigate("/company/polices");
            //handleReset();
          },
          (reason) => {
            toast(`${reason?.response?.data || reason?.message}`, {
              type: "error",
              theme: "colored",
            });
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
    validateOnBlur: false,
    validateOnChange: false,
  });

  const { values, setSubmitting, setFieldValue } = formik;

  const calculateSouscription = useCalculateSouscription(
    values,
    setFieldValue,
    insuranceDurations,
    directivesTarifairesValues
  );

  const getDirectivesTarifairesValues = async () => {
    directivesTarifaireValuesService.getByActiveDirectivesTarifaires().then(
      (response) => {
        const directiveValue = (data, name) =>
          data.find((item) => item.name === name);
        setDirectivesTarifairesValues(response.data);
        formik.setFieldValue(
          "prime_nette_iac",
          directiveValue(response.data, "prime_nette_individuelle_conducteur")
            ?.value
        );
        formik.setFieldValue(
          "cout_police",
          directiveValue(response.data, "cout_police")?.value
        );
        formik.setFieldValue(
          "carte_rose",
          directiveValue(response.data, "carte_rose")?.value
        );

        formik.setFieldValue(
          "droit_de_timbre_automobile",
          directiveValue(response.data, "droit_de_timbre_automobile")?.value
        );
      },
      () => {}
    );
  };

  const fetchInsuranceDurations = async () => {
    poolInsuranceDurationService
      .getByActiveDirectiveTarifaires()
      .then(
        (response) => {
          setInsuranceDurations(response.data);
        },
        () => {}
      )
      .finally(() => {});
  };

  const fetchCompanyGaranties = async () => {
    setLoadCompanyGaranties(true);
    companyGarantiesService
      .getByActiveDirectivesTarifaires()
      .then((res) => {
        const garanties = [];
        res?.data?.forEach((item) => {
          garanties.push({
            libelle:
              item.pool_directives_tarifaires_has_listes_garanties
                .liste_garanties.name,
            uuid: item.uuid,
            code: item.code,
            is_required:
              item.pool_directives_tarifaires_has_listes_garanties
                .is_required === 1,
            risques:
              item.pool_directives_tarifaires_has_listes_garanties.liste_garanties.liste_garanties_risques?.map(
                (risque) => {
                  return {
                    libelle: risque.name,
                    uuid: risque.uuid,
                    checked:
                      item.pool_directives_tarifaires_has_listes_garanties
                        .is_required === 1,
                    value: 0,
                  };
                }
              ),
          });
        });
        setCompanyGaranties(res.data);

        formik.setFieldValue("garanties", garanties);
      })
      .catch(() => {})
      .finally(() => {
        setLoadCompanyGaranties(false);
      });
  };

  const panesList = [
    {
      menuItem: "Assuré",
      element: <AssurePane formik={formik} />,
    },
    {
      menuItem: "Référence contrat",
      element: (
        <ReferencesConditions
          formik={formik}
          insuranceDurations={insuranceDurations}
        />
      ),
    },
    {
      menuItem: "Identité du véhicule",
      element: (
        <IndentiteVehicule
          formik={formik}
          companyDirectiveValues={companyDirectiveValues}
          avariesFacultatives={avariesFacultatives}
          tauxBrisDeGlaces={
            directivesTarifairesValues.find(
              (item) => item.name === "tarif_bris_glaces"
            )?.value
          }
        />
      ),
    },
    {
      menuItem: "Calcul de la commission",
      element: <CalculCommission formik={formik} />,
    },
  ];

  const fetchCategoriesContrats = async () => {
    const response = await categoriesContratsService.fetchAll();

    if (response?.status === 200 && Array.isArray(response?.data)) {
      setCategoriesContrats(response?.data);
    }
  };

  const fetchGarantiesComplementaires = async () => {
    try {
      const response = await garantiesComplementairesService.getAll({
        active: true,
      });

      if (response?.status === 200 && Array.isArray(response?.data)) {
        setGarantiesComplementaires(response?.data);
      }
    } catch (error) {}
  };

  const fetchAvariesFacultatives = async () => {
    try {
      const response =
        await avariesFacultativesService.getByActiveDirectiveTarifaires();
      setAvariesFacultatives(response?.data);
    } catch (error) {}
  };

  const fetchCompanyDirectiveValues = async () => {
    try {
      const response =
        await companyDirectiveValuesService.getByActiveDirectivesTarifaires();
      setCompanyDirectiveValues(response?.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchCategoriesContrats();
    getDirectivesTarifairesValues();
    fetchCompanyGaranties();
    fetchInsuranceDurations();
    fetchGarantiesComplementaires();
    fetchAvariesFacultatives();
    fetchCompanyDirectiveValues();
    return () => {};
  }, []);

  useEffect(() => {
    setFieldValue(
      "idsouscripteurs",
      selectedSouscripteur?.idsouscripteurs,
      false
    );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSouscripteur]);
  useEffect(() => {
    if (values.lien_assure !== "LUI_MEME") {
      setFieldValue("assure.nom_assure", "", false);
      setFieldValue("assure.phone_assure", "", false);
      setFieldValue("assure.email_assure", "", false);
      setFieldValue("assure.address_assure", "", false);
      setFieldValue("assure.job_assure", "", false);
      setFieldValue("assure.idjobs", "", false);
    } else {
      setFieldValue(
        "assure.nom_assure",
        selectedSouscripteur?.groupe_souscripteur === "PARTICULIER"
          ? `${selectedSouscripteur?.first_name || ""} ${
              selectedSouscripteur?.last_name || ""
            }`
          : selectedSouscripteur?.denomination,
        false
      );
      setFieldValue(
        "assure.phone_assure",
        selectedSouscripteur?.phone || "",
        false
      );
      setFieldValue(
        "assure.email_assure",
        selectedSouscripteur?.email || "",
        false
      );
      setFieldValue(
        "assure.address_assure",
        selectedSouscripteur?.address || "",
        false
      );
      setFieldValue("assure.idjobs", selectedSouscripteur?.idjobs || "", false);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.lien_assure, values.idsouscripteurs]);

  return (
    <React.Fragment>
      <GridRow as={Form} className="souscription-mono-form">
        <Header dividing>Production Mono</Header>
        <SouscripteurSection
          formik={formik}
          selectedSouscripteur={selectedSouscripteur}
          setOpenSelectSouscripteurModal={setOpenSelectSouscripteurModal}
          categoriesContrats={categoriesContrats}
        />
        <Tab
          style={{ marginTop: "40px" }}
          menu={{ fluid: true, vertical: true, tabular: true }}
          panes={panesList.map((pane, index) => ({
            menuItem: pane.menuItem,
            render: () => <Tab.Pane key={index}>{pane.element}</Tab.Pane>,
            key: index,
          }))}
        />
        <GarantiesSection
          formik={formik}
          directivesTarifairesValues={directivesTarifairesValues}
          avariesFacultatives={avariesFacultatives}
          companyDirectiveValues={companyDirectiveValues}
        />
        {garantiesComplementaires.length > 0 && (
          <GarantiesFacultatives
            formik={formik}
            garantiesComplementaires={garantiesComplementaires}
          />
        )}
        <SommaireSection formik={formik} />
      </GridRow>
      <SelectSouscriptorModal
        open={openSelectSouscripteurModal}
        selectedSouscripteur={selectedSouscripteur}
        setSelectedSouscripteur={setSelectedSouscripteur}
        setOpen={setOpenSelectSouscripteurModal}
      />
    </React.Fragment>
  );
}

export default SouscriptionMonoForm;
