import { api } from "../../axios/api";

export const createBatch = async (data) => {
  return api.post("/gestion-production/tableau-garanties-rc", data);
};

export const getTableauGarantiesByDirectivesTarifaires = async (uuid) => {
  return api.get(
    "/gestion-production/tableau-garanties-rc/" +
    uuid +
    "/directives-tarifaires"
  );
};

export const updateBatch = async (uuid, data) => {
  return api.put("/gestion-production/tableau-garanties-rc/" + uuid, data);
};

export const getByActiveDirectivesTarifaires = async (params) => {
  return api.get(
    "/gestion-production/tableau-garanties-rc/active/directives-tarifaires",
    { params }
  );
};
