import React, { useEffect, useState } from "react";
import Page from "../../../../components/Page";
import {
  Button,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Label,
  List,
  Message,
  Segment,
  Table,
  Breadcrumb,
  BreadcrumbSection,
  BreadcrumbDivider,
} from "semantic-ui-react";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import * as ordersService from "../../../../services/gestion_attestations/company/ordersService";
import * as deliveriesService from "../../../../services/gestion_attestations/company/deliveries";
import { Link } from "react-router-dom";
import StockDoughnutChart from "../../../../components/charts/StockDoughnutChart";

function CommandesDetails() {
  const [order, setOrder] = useState(null);
  const [deliveries, setDeliveries] = useState([]);
  const uuid = window.location.pathname.split("/").pop();

  const fetchOrder = async () => {
    try {
      const { data } = await ordersService.fetchOne(uuid);
      setOrder(data);
    } catch (error) {
      toast.error(
        error?.response?.data?.error ||
          "Une erreur est survenue lors de la recuperation du order"
      );
    }
  };

  const fetchDeliveries = async () => {
    try {
      const { data } = await deliveriesService.fetchDeliveriesByOrder(
        order.uuid
      );
      setDeliveries(data);
    } catch (error) {
      toast.error(
        error?.response?.data?.error ||
          "Une erreur est survenue lors de la recuperation des livraisons"
      );
    }
  };

  useEffect(() => {
    fetchOrder();
  }, []);

  useEffect(() => {
    if (order) {
      fetchDeliveries();
    }
  }, [order]);

  return (
    <Page title="Détails de la commande">
      <GridRow className="Souscriptions">
        <Grid.Row className="!mb-5">
          <Grid.Column width={16}>
            <Breadcrumb>
              <BreadcrumbSection link>Compagnie</BreadcrumbSection>
              <BreadcrumbDivider icon="right chevron" />
              <BreadcrumbSection link>
                Gestion des attestations
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection link as={Link} to="/company/stocks/commandes">
                Commandes
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection active>
                Détails de la commande
              </BreadcrumbSection>
            </Breadcrumb>
            <Divider />
          </Grid.Column>
        </Grid.Row>
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid>
              <Grid.Column>
                <Header>
                  Détails de la commande [
                  {order?.idattestations_orders?.toString().padStart(6, "0")}]
                </Header>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input placeholder="Rechercher..." className="search !hidden">
              <input name="search" />
            </Input>
            <Button className="!ml-4 !hidden">
              <Icon name="add" /> Ajouter
            </Button>
          </Grid.Column>
        </Grid>
        <>
          <GridRow as={Segment}>
            <Label ribbon>Détails de la commande</Label>
            <Grid stackable columns={3} style={{ marginTop: "5px" }}>
              <Grid.Column>
                <List>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Numéro de commande</List.Header>
                      <List.Description>
                        {order?.idattestations_orders
                          ?.toString()
                          .padStart(6, "0")}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Quantité commandée</List.Header>
                      <List.Description>
                        {new Intl.NumberFormat().format(order?.quantity)}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column>
                <List>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Numéro du stock</List.Header>
                      <List.Description>
                        {order?.attestations_stocks?.idattestations_stocks
                          ?.toString()
                          .padStart(6, "0") || "-"}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Série du stock</List.Header>
                      <List.Description>
                        {order?.attestations_stocks
                          ? `
                          ${order?.attestations_stocks?.start_serial}
                          - ${order?.attestations_stocks?.end_serial}
                          ` || "-"
                          : "-"}{" "}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column>
                <List>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Date de commande</List.Header>
                      <List.Description>
                        {dayjs(order?.created_at).format("DD/MM/YYYY HH:mm")}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Quantité stockée</List.Header>
                      <List.Description>
                        {order?.attestations_stocks
                          ? new Intl.NumberFormat().format(
                              order?.attestations_stocks?.usage?.totalQty
                            )
                          : "-"}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid>
            {order?.comment && (
              <Grid.Column>
                <div className="bg-gray-100 p-2 rounded-md mt-2">
                  {order?.comment}
                </div>
              </Grid.Column>
            )}
          </GridRow>

          {order?.attestations_stocks ? (
            <>
              <StockDoughnutChart
                total={order?.attestations_stocks?.usage?.totalQty}
                consumed={order?.attestations_stocks?.usage?.consumedQty}
                remaining={order?.attestations_stocks?.usage?.remainingQty}
                labels={[
                  {
                    label: "Reçue",
                    value: order?.attestations_stocks?.usage?.consumedQty,
                    color: "#FF6384",
                  },
                  {
                    label: "Restant",
                    value: order?.attestations_stocks?.usage?.remainingQty,
                    color: "#36A2EB",
                  },
                ]}
              />
              <GridRow as={Segment}>
                <div className="ui label top attached !flex !justify-between">
                  <h3>Liste des livraisons</h3>
                  <div className="basic" style={{ cursor: "pointer" }}></div>
                </div>
                <Table basic="very">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Numéro de livraison</Table.HeaderCell>
                      <Table.HeaderCell>Magasin</Table.HeaderCell>
                      <Table.HeaderCell>Quantité livrée</Table.HeaderCell>
                      <Table.HeaderCell>Plage</Table.HeaderCell>
                      <Table.HeaderCell>Qté disponible</Table.HeaderCell>
                      <Table.HeaderCell>Date de livraison</Table.HeaderCell>
                      <Table.HeaderCell collapsing>Action</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {deliveries?.map((delivery) => (
                      <Table.Row>
                        <Table.Cell>
                          {delivery?.idattestations_deliveries
                            ?.toString()
                            .padStart(6, "0")}
                        </Table.Cell>
                        <Table.Cell>
                          {
                            delivery?.attestations_magasins_stocks[0]
                              ?.attestations_magasins?.name
                          }
                        </Table.Cell>
                        <Table.Cell>
                          {new Intl.NumberFormat().format(
                            delivery?.usage?.totalQty
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {`${delivery?.start_serial} - ${delivery?.end_serial}`}
                        </Table.Cell>
                        <Table.Cell>
                          {new Intl.NumberFormat().format(
                            delivery?.usage?.remainingQty
                          )}
                        </Table.Cell>

                        <Table.Cell>
                          {dayjs(delivery?.created_at).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                        </Table.Cell>
                        <Table.Cell className="text-right">
                          <Dropdown
                            pointing="right"
                            icon={<Icon name="ellipsis vertical" />}
                          >
                            <Dropdown.Menu>
                              <Dropdown.Item
                                icon={<Icon name="eye" />}
                                content="Détails"
                                as={Link}
                                to={`/company/stocks/commandes/delivery/${delivery?.uuid}`}
                              />
                            </Dropdown.Menu>
                          </Dropdown>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </GridRow>
            </>
          ) : (
            <>
              <Message info>
                <Message.Header>Note !</Message.Header>
                <p>
                  Il n'y a pas encore de stock pour cette commande. Veuillez
                  patienter jusqu'a ce que le order soit disponible.
                </p>
              </Message>
            </>
          )}
        </>
      </GridRow>
    </Page>
  );
}

export default CommandesDetails;
