import { USERS_ROLE_ENUMS, USER_STORAGE } from "../constants";

export const getUserBaseRoute = (authValue) => {
  if (
    [USERS_ROLE_ENUMS.ROOT_ADMIN, USERS_ROLE_ENUMS.POOL_USER].includes(
      authValue?.user?.user_role
    )
  ) {
    return "pool";
  }
  if ([USERS_ROLE_ENUMS.COMPANY_USER].includes(authValue?.user?.user_role)) {
    return "company";
  }
  if (
    [
      USERS_ROLE_ENUMS.AGENT_GENERAL_USER,
      USERS_ROLE_ENUMS.COURTIER_USER,
    ].includes(authValue?.user?.user_role)
  ) {
    return "intermediaire";
  }
  if (
    [USERS_ROLE_ENUMS.COMPANY_AGENCY_USER].includes(authValue?.user?.user_role)
  ) {
    return "agency";
  }

  if (
    [USERS_ROLE_ENUMS.INTERMEDIAIRE_AGENCY_USER].includes(
      authValue?.user?.user_role
    )
  ) {
    return "intermediaire-agency";
  }

  return false;
};

export const getUserFromStorage = () => {
  try {
    let data = sessionStorage.getItem(USER_STORAGE);
    return JSON.parse(data);
  } catch (error) {
    return null;
  }
};

export const saveUserFromStorage = (data) => {
  try {
    sessionStorage.setItem(USER_STORAGE, JSON.stringify(data));
  } catch (error) {
    sessionStorage.removeItem(USER_STORAGE);
  }
};

export const removeUserFromStorage = () => {
  sessionStorage.removeItem(USER_STORAGE);
};
