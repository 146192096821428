import React from "react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Loader,
  Message,
  Modal,
  TextArea,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as companyMagasinsService from "../../services/gestion_attestations/company/magasinsService";

const validationSchema = yup.object().shape({
  name: yup.string().required("Le nom du magasin est requis"),
  location: yup.string().required("La localisation est requise"),
  phone: yup
    .string()
    .required("Le téléphone est requis")
    .min(8, "Le téléphone doit contenir au moins 8 caractères")
    .max(16, "Le téléphone doit contenir au plus 16 caractères"),
  comment: yup
    .string()
    .max(500, "Le commentaire doit contenir au plus 500 caractères"),
  is_active: yup.boolean(),
  type_magasin: yup.string().required("Le type de magasin est requis"),
});
function CompanyMagasinFormModal({
  callback = () => {},
  open = false,
  setOpen,
  selectedMagasinId,
  handleCloseModal = () => {},
}) {
  const [loading, setLoading] = React.useState(false);
  const [magasin, setMagasin] = React.useState(null);
  const formik = useFormik({
    initialValues: {
      name: "",
      location: "",
      phone: "",
      comment: "",
      is_active: 0,
      type_magasin: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const request = selectedMagasinId
        ? companyMagasinsService.update(selectedMagasinId, values)
        : companyMagasinsService.create(values);
      request
        .then((response) => {
          toast.success(
            selectedMagasinId ? "Magasin mis à jour" : "Magasin ajouté"
          );
          setOpen(false);
          callback();
          formik.resetForm();
          handleCloseModal();
        })
        .catch((error) => {
          toast.error(
            "Une erreur est survenue lors de " +
              (selectedMagasinId ? "la mise à jour" : "l'ajout") +
              " du magasin"
          );
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const {
    values,
    errors,
    handleSubmit,
    isSubmitting,
    handleBlur,
    handleChange,
  } = formik;

  const fetchMagasin = () => {
    if (selectedMagasinId) {
      setLoading(true);
      companyMagasinsService
        .fetchOne(selectedMagasinId)
        .then((response) => {
          if (response.status === 200) {
            formik.setFieldValue("comment", response.data.comment || "");
            formik.setFieldValue("location", response.data.location || "");
            formik.setFieldValue("name", response.data.name || "");
            formik.setFieldValue("phone", response.data.phone || "");
            formik.setFieldValue("is_active", response.data.is_active || 0);
            formik.setFieldValue(
              "type_magasin",
              response.data.type_magasin || ""
            );
            setMagasin(response.data);
          }
        })
        .catch((error) => {
          toast.error(
            "Une erreur est survenue lors de la recuperation du magasin"
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  React.useEffect(() => {
    fetchMagasin();
  }, [selectedMagasinId]);
  return (
    <Modal
      onClose={() => {
        setOpen(false);
        formik.resetForm();
        callback();
        handleCloseModal();
      }}
      onOpen={() => setOpen(true)}
      open={open}
      size="tiny"
      closeIcon
      onUnmount={() => {
        setMagasin(null);
      }}
    >
      <Modal.Header>
        {selectedMagasinId ? "Modifier le magasin" : "Ajouter un magasin"}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description className="ui form">
          <Message>
            <Message.Header>Note</Message.Header>
            <Message.Content>
              Les magasins vous permettent de répartir votre stock
              d'attestations
            </Message.Content>
          </Message>
          {loading === false ? (
            <>
              <Form.Field required error={"name" in errors}>
                <label>Nom du magasin</label>
                <Input
                  name="name"
                  value={values.name}
                  onChange={handleChange("name")}
                  onBlur={handleBlur("name")}
                />
                <small className="field-error">{errors.name}</small>
              </Form.Field>
              <Form.Group widths="equal">
                <Form.Field required error={"location" in errors}>
                  <label>Localisation</label>
                  <Input
                    name="location"
                    value={values.location}
                    onChange={handleChange("location")}
                    onBlur={handleBlur("location")}
                  />
                  <small className="field-error">{errors.location}</small>
                </Form.Field>
                <Form.Field required error={"phone" in errors}>
                  <label>Téléphone</label>
                  <Input
                    name="phone"
                    value={values.phone}
                    onChange={handleChange("phone")}
                    onBlur={handleBlur("phone")}
                  />
                  <small className="field-error">{errors.phone}</small>
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Dropdown
                  error={"type_magasin" in errors}
                  selection
                  fluid
                  required
                  label="Type de magasin"
                  name="type_magasin"
                  value={values.type_magasin}
                  onChange={(e, data) => {
                    formik.setFieldValue("type_magasin", data.value);
                  }}
                  options={[
                    { key: "VENTE", text: "Vente", value: "VENTE" },
                    { key: "STOCKAGE", text: "Stockage", value: "STOCKAGE" },
                  ]}
                  disabled={magasin?.is_default === 1}
                />
                <Form.Field>
                  <label>Statut</label>
                  <Checkbox
                    toggle
                    name="is_active"
                    checked={values.is_active === 1}
                    onChange={() => {
                      formik.setFieldValue(
                        "is_active",
                        values.is_active === 1 ? 0 : 1
                      );
                    }}
                    disabled={magasin?.is_default === 1}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Field error={"comment" in errors}>
                <label>Commentaire</label>
                <TextArea
                  value={values.comment}
                  onChange={handleChange("comment")}
                />
                <small className="field-error">{errors.comment}</small>
              </Form.Field>
            </>
          ) : (
            <Loader active inline="centered" />
          )}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          onClick={() => {
            formik.resetForm();
            handleCloseModal();
          }}
        >
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          type="submit"
          loading={isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default CompanyMagasinFormModal;
