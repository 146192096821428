import { api } from "../axios/api";

export const fetchAll = async () => {
  return api.get(`car-brands`);
};
export const fetchOne = async (id) => {
  return api.get(`car-brands/${id}`);
};
export const create = async (data) => {
  return api.post(`car-brands`, data);
};
