import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Divider,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Segment,
  Table,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as conversionOfFiscalForcesService from "../../../../services/gestion_production/conversionOfFiscaleForcesServices";
import { useRecoilValue } from "recoil";
import { authState } from "../../../../atoms/authState";

const schema = Yup.object().shape({
  directive_uuid: Yup.string().required("Valeur obligatoire"),
  items: Yup.array(
    Yup.object().shape({
      idconversion_of_fiscal_forces: Yup.number()
        .nullable()
        .transform((value, originalValue) =>
          originalValue === "" ? null : value
        ),
      diesel_cv: Yup.number().min(1).required("Valeur obligatoire"),
      essence_cv: Yup.number().required("Valeur obligatoire"),
      is_max_value: Yup.number().min(0).max(1).default(0),
    })
  )
    .required()
    .min(1),
});

function TableauConversionForcesFiscales({ directiveUuid }) {
  const userData = useRecoilValue(authState);

  const [isEditMode, setIsEditMode] = useState(false);

  const formik = useFormik({
    initialValues: {
      directive_uuid: directiveUuid,
      items: [
        {
          idconversion_of_fiscal_forces: "",
          diesel_cv: "",
          essence_cv: "",
          is_max_value: 0,
        },
      ],
    },
    validationSchema: schema,
    onSubmit: () => {
      formik.setSubmitting(true);
      conversionOfFiscalForcesService
        .createConversionOfFiscaleForces(formik.values)
        .then((res) => {
          toast.success("Opération réussie");
          getConversionOfFiscaleForces();
        })
        .catch((err) => {
          toast.error("Opération échouée");
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
  });

  const addItem = () => {
    const items = [...formik.values.items];
    items.push({
      idconversion_of_fiscal_forces: "",
      diesel_cv: "",
      essence_cv: "",
      is_max_value: 0,
    });
    formik.setFieldValue("items", items);
  };

  const handleRemoveItem = (index) => {
    Swal.fire({
      title: "Etes-vous sûr?",
      text: "Vous ne pourrez pas revenir en arrière!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer!",
    }).then((result) => {
      if (result.isConfirmed) {
        removeItem(index);
      }
    });
  };

  const removeItem = (index) => {
    const items = [...formik.values.items];
    items.splice(index, 1);
    formik.setFieldValue("items", items);
  };

  const getConversionOfFiscaleForces = async () => {
    conversionOfFiscalForcesService
      .getConversionOfFiscaleForces(directiveUuid)
      .then((res) => {
        formik.setFieldValue(
          "items",
          res.data?.map((item) => {
            return {
              idconversion_of_fiscal_forces: item.idconversion_of_fiscal_forces,
              diesel_cv: parseInt(item.diesel_cv),
              essence_cv: parseInt(item.essence_cv),
              is_max_value: parseInt(item.is_max_value),
            };
          }) || []
        );
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error || "Une erreur est survenue");
      });
  };

  useEffect(() => {
    formik.setFieldValue("directive_uuid", directiveUuid);
    getConversionOfFiscaleForces();
  }, []);

  return (
    <GridRow>
      <Grid.Column className="!mt-6">
        <Segment>
          <div className="flex justify-between">
            <Header className="!mb-0">
              Tableau de conversion des forces fiscales
            </Header>
            {["ROOT_ADMIN", "POOL_USER"].includes(userData?.user?.user_role) && (
              <Checkbox
                label="Mode édition"
                toggle
                onClick={(data, { checked }) => {
                  setIsEditMode(!isEditMode);
                  if (!checked) {
                    getConversionOfFiscaleForces();
                  }
                }}
                checked={isEditMode}
              />
            )}
          </div>
          <Divider />
          <Table celled striped collapsing className="!text-center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell collapsing>#</Table.HeaderCell>
                {isEditMode && (
                  <Table.HeaderCell collapsing>
                    Valeur maximale
                  </Table.HeaderCell>
                )}
                <Table.HeaderCell>
                  Puissance fiscale diesel en CV
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Puissance fiscale essence en CV
                </Table.HeaderCell>
                {isEditMode && <Table.HeaderCell>Actions</Table.HeaderCell>}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {formik.values.items?.map((item, index) => (
                <Table.Row key={index}>
                  <Table.Cell collapsing>{index + 1}</Table.Cell>
                  {isEditMode && (
                    <Table.Cell collapsing>
                      <Checkbox
                        slider
                        checked={item.is_max_value === 1 ? true : false}
                        onChange={(e, { checked }) => {
                          formik.setFieldValue(
                            `items`,
                            formik.values.items.map((itm, idx) =>
                              idx === index
                                ? { ...itm, is_max_value: checked ? 1 : 0 }
                                : { ...itm, is_max_value: 0 }
                            )
                          );
                        }}
                      />
                    </Table.Cell>
                  )}
                  <Table.Cell>
                    {isEditMode ? (
                      <>
                        <Input
                          type="number"
                          placeholder="Diesel en CV"
                          value={item.diesel_cv}
                          onChange={(e, { checked }) =>
                            formik.setFieldValue(
                              `items.${index}.diesel_cv`,
                              e.target.value
                            )
                          }
                        />
                        {formik.errors.items &&
                          formik.errors.items[index] &&
                          formik.errors.items[index].diesel_cv && (
                            <div style={{ color: "red" }}>
                              <small>
                                {formik.errors.items[index].diesel_cv}
                              </small>
                            </div>
                          )}
                      </>
                    ) : (
                      <strong>{item.diesel_cv}</strong>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {isEditMode ? (
                      <>
                        <Input
                          type="number"
                          placeholder="Essence en CV"
                          value={item.essence_cv}
                          onChange={(e) =>
                            formik.setFieldValue(
                              `items.${index}.essence_cv`,
                              e.target.value
                            )
                          }
                        />
                        {formik.errors.items &&
                          formik.errors.items[index] &&
                          formik.errors.items[index].essence_cv && (
                            <div style={{ color: "red" }}>
                              <small>
                                {formik.errors.items[index].essence_cv}
                              </small>
                            </div>
                          )}
                      </>
                    ) : (
                      <strong>{item.essence_cv}</strong>
                    )}
                  </Table.Cell>
                  {isEditMode && (
                    <Table.Cell>
                      <Icon
                        name="trash"
                        link
                        color="red"
                        onClick={() => handleRemoveItem(index)}
                      />
                    </Table.Cell>
                  )}
                </Table.Row>
              ))}
            </Table.Body>
            {isEditMode && (
              <Table.Footer fullWidth>
                <Table.Row>
                  <Table.HeaderCell colSpan="5" align="right">
                    <Button
                      onClick={() => addItem()}
                      floated="left"
                      circular
                      icon="add"
                    />
                    <Button
                      content="Enregistrer"
                      icon="save"
                      labelPosition="left"
                      color="green"
                      type="submit"
                      loading={formik.isSubmitting}
                      disabled={formik.isSubmitting}
                      onClick={() => formik.handleSubmit()}
                      floated="right"
                    />
                    <Button
                      content="Annuler"
                      icon="cancel"
                      labelPosition="left"
                      color="red"
                      onClick={() => {
                        getConversionOfFiscaleForces();
                        setIsEditMode(false);
                      }}
                      floated="right"
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            )}
          </Table>
        </Segment>
      </Grid.Column>
    </GridRow>
  );
}

export default TableauConversionForcesFiscales;
