import { api } from "../../axios/api";

export const getDirectivesTarifaires = async () => {
  return api.get("/gestion-production/directives-tarifaires");
};

export const createDirectivesTarifaires = async (data) => {
  return api.post("/gestion-production/directives-tarifaires", data);
};

export const updateDirectivesTarifaires = async (uuid, data) => {
  return api.put("/gestion-production/directives-tarifaires/" + uuid, data);
};

export const deleteDirectivesTarifaires = async (uuid) => {
  return api.delete("/gestion-production/directives-tarifaires/" + uuid);
};

export const fetchOne = async (uuid) => {
  return api.get("/gestion-production/directives-tarifaires/" + uuid);
};
