import React, { useEffect } from "react";
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Segment,
  Table,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import * as tableauGarantiesVolIncendieService from "../../../../services/gestion_production/pool/tableauGarantiesVolIncendieService";
import Swal from "sweetalert2";
import { useRecoilValue } from "recoil";
import { authState } from "../../../../atoms/authState";

const schema = Yup.object().shape({
  directive_uuid: Yup.string().nullable(),
  items: Yup.array(
    Yup.object().shape({
      uuid: Yup.string()
        .nullable()
        .transform((value, originalValue) =>
          originalValue === "" ? null : value
        ),
      vehicle_value_min: Yup.number().required("Valeur obligatoire"),
      vehicle_value_max: Yup.number().required("Valeur obligatoire"),
      vehicle_age_group: Yup.string().required("Valeur obligatoire"),
      armed_robbery_premium: Yup.number().required("Valeur obligatoire"),
      simple_theft_premium: Yup.number().required("Valeur obligatoire"),
      fire_premium: Yup.number().required("Valeur obligatoire"),
    })
  ),
});

function TableauGarantiesVolIncedie({ directiveUuid }) {
  const userData = useRecoilValue(authState);
  const [isModeEdit, setIsModeEdit] = React.useState(false);
  const [garanties, setGaranties] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const addNewItem = () => {
    const items = [...garanties];
    items.push({
      uuid: null,
      vehicle_age_group: "",
      vehicle_value_max: "",
      vehicle_value_min: "",
      armed_robbery_premium: "",
      simple_theft_premium: "",
      fire_premium: "",
      directive_uuid: directiveUuid,
    });

    setGaranties(items);
  };

  const deleteItem = (index) => {
    const items = [...garanties];
    items.splice(index, 1);

    setGaranties(items);
  };

  const fetchItems = () => {
    setIsLoading(true);
    tableauGarantiesVolIncendieService
      .getAvariesByDirectiveTatifaire(directiveUuid)
      .then(
        (res) => {
          const items = [];
          res.data.forEach((item) => {
            items.push({
              uuid: item.uuid,
              vehicle_age_group: item.vehicle_age_group,
              vehicle_value_max: item.vehicle_value_max,
              vehicle_value_min: item.vehicle_value_min,
              armed_robbery_premium: item.armed_robbery_premium,
              simple_theft_premium: item.simple_theft_premium,
              fire_premium: item.fire_premium,
              directive_uuid: directiveUuid,
            });
          });
          setGaranties(items);
        },
        (error) => {
          toast.error("Opération échouée");
        }
      )
      .catch((error) => toast.error("Opération échouée"))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleRemoveItem = (index) => {
    Swal.fire({
      title: "Etes-vous sûr?",
      text: "Vous ne pourrez pas revenir en arrière!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer!",
    }).then((result) => {
      if (result.isConfirmed) {
        const item = garanties[index];
        if (!item.uuid) {
          deleteItem(index);
          return true;
        }
        tableauGarantiesVolIncendieService.removeItem(item.uuid).then(
          (res) => {
            toast.success("Opération réussie");
            fetchItems(directiveUuid);
            deleteItem(index);
          },
          (err) => {
            toast.error("Opération échouée");
          }
        );
      }
    });
  };

  useEffect(() => {
    if (directiveUuid) {
      fetchItems();
    }
  }, [directiveUuid]);

  return (
    <GridRow>
      <Grid.Column className="!mt-6">
        <Segment>
          <div className="flex justify-between items-center">
            <Header className="!mb-0">
              Tableau de garanties vol & incendie
            </Header>
            {["ROOT_ADMIN", "POOL_USER"].includes(
              userData?.user?.user_role
            ) && (
                <Checkbox
                  toggle
                  label="Mode édition"
                  onChange={(e, { checked }) => {
                    setIsModeEdit(checked);
                    fetchItems();
                  }}
                  checked={isModeEdit}
                />
              )}
          </div>
          <Divider />
          <Table striped celled compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Valeur Minimale</Table.HeaderCell>
                <Table.HeaderCell>Valeur Maximale</Table.HeaderCell>
                <Table.HeaderCell>Âge du Véhicule</Table.HeaderCell>
                <Table.HeaderCell>Vol à Main Armée</Table.HeaderCell>
                <Table.HeaderCell>Vol Simple</Table.HeaderCell>
                <Table.HeaderCell>Incendie</Table.HeaderCell>
                {isModeEdit ? (
                  <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
                ) : null}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {!isLoading && garanties?.map((item, index) => (
                <Formik
                  initialValues={item}
                  validationSchema={schema}
                  onSubmit={(values, actions) => {
                    let data = { ...values };
                    const uuid = data.uuid;
                    values.directive_uuid = directiveUuid;
                    if (uuid) {
                      delete values.directive_uuid;
                    }
                    const reqOp = data.uuid
                      ? tableauGarantiesVolIncendieService.update(uuid, data)
                      : tableauGarantiesVolIncendieService.create(data);

                    reqOp.then(
                      (res) => {
                        toast.success("Opération réussie");
                        actions.setFieldValue("uuid", res.data.uuid);
                        let items = [...garanties];
                        items[index].uuid = res.data.uuid;
                        setGaranties(items);
                      },
                      (err) => {
                        toast.error("Opération échouée");
                      }
                    );
                  }}
                >
                  {(formik) => (
                    <Table.Row key={index}>
                      <Table.Cell textAlign="left">
                        {isModeEdit ? (
                          <Input
                            fluid
                            type="number"
                            min={1}
                            value={formik.values.vehicle_value_min}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "vehicle_value_min",
                                e.target.value
                              )
                            }
                            max={100}
                            step={1}
                          />
                        ) : (
                          item.vehicle_value_min
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {isModeEdit ? (
                          <Input
                            fluid
                            value={formik.values.vehicle_value_max}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "vehicle_value_max",
                                e.target.value
                              );
                            }}
                            type="number"
                            min={1}
                            step={1}
                          />
                        ) : (
                          new Intl.NumberFormat().format(
                            Number(item.vehicle_value_max)
                          )
                        )}
                      </Table.Cell>
                      <Table.Cell width={2}>
                        {isModeEdit ? (
                          <Dropdown
                            fluid
                            value={formik.values.vehicle_age_group}
                            selection
                            options={[
                              { key: "", text: "Choisir...", value: "" },
                              {
                                key: "Moins de 2 ans",
                                text: "- de 2 ans",
                                value: "Moins de 2 ans",
                              },
                              {
                                key: "Plus de 2 ans",
                                text: "+ de 2 ans",
                                value: "Plus de 2 ans",
                              },
                            ]}
                            onChange={(e, { value }) => {
                              formik.setFieldValue("vehicle_age_group", value);
                            }}
                            type="number"
                            step={1}
                          />
                        ) : (
                          item.vehicle_age_group
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {isModeEdit ? (
                          <Input
                            fluid
                            value={formik.values.armed_robbery_premium}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "armed_robbery_premium",
                                e.target.value
                              );
                            }}
                            type="number"
                            step={1}
                          />
                        ) : (
                          new Intl.NumberFormat().format(
                            Number(item.armed_robbery_premium)
                          )
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {isModeEdit ? (
                          <Input
                            fluid
                            value={formik.values.simple_theft_premium}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "simple_theft_premium",
                                e.target.value
                              );
                            }}
                            type="number"
                            step={1}
                          />
                        ) : (
                          new Intl.NumberFormat().format(
                            Number(item.simple_theft_premium)
                          )
                        )}
                      </Table.Cell>
                      <Table.Cell width={2}>
                        {isModeEdit ? (
                          <Input
                            fluid
                            value={formik.values.fire_premium}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "fire_premium",
                                e.target.value
                              );
                            }}
                            type="number"
                            step={1}
                          />
                        ) : (
                          new Intl.NumberFormat().format(
                            Number(item.fire_premium)
                          )
                        )}
                      </Table.Cell>

                      {isModeEdit ? (
                        <Table.Cell className="!text-right">
                          <Icon
                            name="save"
                            color="blue"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              formik.submitForm();
                            }}
                          />
                          <Icon
                            name="trash"
                            color="red"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleRemoveItem(index);
                            }}
                          />
                        </Table.Cell>
                      ) : null}
                    </Table.Row>
                  )}
                </Formik>
              ))}
            </Table.Body>
            {isModeEdit ? (
              <Table.Footer fullWidth>
                <Table.Row>
                  <Table.HeaderCell colspan={isModeEdit ? 8 : 6}>
                    <Button
                      className="!ml-4"
                      icon="add"
                      size="tiny"
                      circular
                      floated="left"
                      onClick={() => {
                        addNewItem();
                      }}
                    />

                    <Button
                      content="Annuler"
                      icon="cancel"
                      labelPosition="left"
                      color="red"
                      floated="right"
                      size="tiny"
                      onClick={() => {
                        setIsModeEdit(false);
                        fetchItems();
                      }}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            ) : null}
          </Table>
        </Segment>
      </Grid.Column>
    </GridRow>
  );
}

export default TableauGarantiesVolIncedie;
