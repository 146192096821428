import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Table,
} from "semantic-ui-react";
import { useParams } from "react-router-dom";
import * as policesService from "../../services/gestion_production/policesService";

function SinistresPane() {
  const [police, setPolice] = useState();

  const params = useParams();

  const fetchCompany = async () => {
    try {
      const response = await policesService.findOne(params?.id);

      if (response?.status === 200) {
        setPolice(response?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    /*  if (params?.uuid) {
     
    } */ fetchCompany();
    return () => {};
  }, [params?.id]);

  return (
    <GridRow>
      <>
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Header>Liste des actes</Header>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input placeholder="Rechercher..." className="search">
              <input name="search" />
            </Input>
            <Button
              style={{ marginLeft: "10px" }}
              circular
              icon={<Icon name="add" />}
            />
          </Grid.Column>
        </Grid>
        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>#</Table.HeaderCell>
              <Table.HeaderCell>Numéro</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Période</Table.HeaderCell>
              <Table.HeaderCell>Création</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {/* {avenant?.map((police, index) => {
                return (
                  <Table.Row key={`${police.idpolices}`}>
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>{police.num_police || "N/A"}</Table.Cell>
                    <Table.Cell>
                      {police.avenants[0]?.vehicules?.[0].immatriculation}
                    </Table.Cell>
                    <Table.Cell>
                      {
                        police.souscriptions
                          ?.souscripteurs_souscriptions_idsouscripteursTosouscripteurs
                          ?.num
                      }
                    </Table.Cell>
                    <Table.Cell>
                      {police.souscriptions
                        ?.souscripteurs_souscriptions_idsouscripteursTosouscripteurs
                        ?.groupe_souscripteur === "PARTICULIER"
                        ? `${police.souscriptions?.souscripteurs_souscriptions_idsouscripteursTosouscripteurs?.last_name} ${police.souscriptions?.souscripteurs_souscriptions_idsouscripteursTosouscripteurs?.first_name}`
                        : police.souscriptions
                            ?.souscripteurs_souscriptions_idsouscripteursTosouscripteurs
                            ?.denomination}
                    </Table.Cell>
                    <Table.Cell>
                      {
                        police.souscriptions
                          ?.souscripteurs_souscriptions_idsouscripteursTosouscripteurs
                          ?.phone
                      }
                    </Table.Cell>
                    <Table.Cell>
                      {
                        police.souscriptions
                          ?.souscripteurs_souscriptions_idsouscripteursTosouscripteurs
                          ?.email
                      }
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <Dropdown
                        pointing="right"
                        icon={<Icon name="ellipsis vertical" />}
                      >
                        <Dropdown.Menu>
                          <Dropdown.Item
                            icon={<Icon name="eye" />}
                            content="Détails"
                            onClick={() =>
                              navigate(`details/${police?.idpolices}`)
                            }
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                );
              })} */}
          </Table.Body>
        </Table>
      </>
    </GridRow>
  );
}

export default SinistresPane;
