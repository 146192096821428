import { api } from "../axios/api";

export const fetchAll = async () => {
  return api.get(`sources-energies`);
};

export const fetchOne = async (id) => {
  return api.get(`sources-energies/${id}`);
};
export const create = async (data) => {
  return api.post(`sources-energies`, data);
};

