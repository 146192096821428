import { api } from "../../../axios/api";

export const fetchAll = async (filter) => {
  return api.get(`gestion-attestations/agency/requests`, {
    params: {
      page: filter?.page || 1,
      ...filter,
    },
  });
};

export const fetchOne = async (uuid) => {
  return api.get(`gestion-attestations/agency/requests/${uuid}`);
};

export const deleteOne = async (uuid) => {
  return api.delete(`gestion-attestations/agency/requests/${uuid}`);
};

export const update = async (uuid, data) => {
  return api.put(`gestion-attestations/agency/requests/${uuid}`, data);
};

export const create = async (data) => {
  return api.post(`gestion-attestations/agency/requests`, data);
};
