import React, { useEffect, useState } from "react";
import Page from "../../../../../../components/Page";
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Button,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Label,
  Segment,
  Table,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import * as insurancesDurationsService from "../../../../../../services/gestion_production/insurancesDurationsService";
import dayjs from "dayjs";
import InsuranceDurationModalForm from "../../../../../../components/modalForms/gestion_production/pool/InsuranceDurationModalForm";
import Swal from "sweetalert2";

function InsurancesDurationListPage() {
  const [insurancesDurations, setInsurancesDurations] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemUuid, setSelectedItemUuid] = useState(null);

  const getInsurancesDurations = () => {
    insurancesDurationsService
      .getInsurancesDurations()
      .then((res) => {
        setInsurancesDurations(res.data);
      })
      .catch((err) => {
        toast.error("Opération échouée");
      });
  };

  const handleRemoveClick = (uuid) => {
    Swal.fire({
      title: "Etes-vous sûr?",
      text: "Vous ne pourrez pas revenir en arrière!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer!",
    }).then((result) => {
      if (result.isConfirmed) {
        insurancesDurationsService.deleteInsurancesDurations(uuid).then(
          (res) => {
            getInsurancesDurations();
            toast.success("Opération réussie");
          },
          (err) => {
            toast.error("Opération échouée");
          }
        );
      }
    });
  };

  const handleEditClick = (uuid) => {
    setSelectedItemUuid(uuid);
    setIsModalOpen(true);
  };

  useEffect(() => {
    getInsurancesDurations();
  }, []);

  return (
    <Page title="Durées de l'assurance">
      <GridRow className="Souscriptions">
        <Grid.Row className="!mb-5">
          <Grid.Column width={16}>
            <Breadcrumb>
              <BreadcrumbSection link>Pool</BreadcrumbSection>
              <BreadcrumbDivider icon="right chevron" />
              <BreadcrumbSection link>
                Gestion de la production
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection link>
                Paramètres de tarifications
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection active>
                Durées de l'assurance
              </BreadcrumbSection>
            </Breadcrumb>
            <Divider />
          </Grid.Column>
        </Grid.Row>
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid>
              <Grid.Column>
                <Header>
                  <Header.Content>Durées de garantie</Header.Content>
                </Header>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              gap: "10px",
            }}
          >
            <Button
              onClick={() => {
                setSelectedItemUuid(null);
                setIsModalOpen(true);
              }}
            >
              <Icon name="add" />
              Ajouter
            </Button>
          </Grid.Column>
        </Grid>
        <GridRow>
          <Grid.Column className="!mt-6">
            <Segment>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell collapsing>#</Table.HeaderCell>
                    <Table.HeaderCell>Nombre de mois</Table.HeaderCell>
                    <Table.HeaderCell>Actif</Table.HeaderCell>
                    <Table.HeaderCell>Date de création</Table.HeaderCell>
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {insurancesDurations?.map((duration, index) => (
                    <Table.Row key={index}>
                      <Table.Cell collapsing>{index + 1}</Table.Cell>
                      <Table.Cell>{duration.months_number} mois</Table.Cell>
                      <Table.Cell>
                        <Label color={duration.is_active ? "green" : "red"}>
                          {duration.is_active ? "Actif" : "Inactif"}
                        </Label>
                      </Table.Cell>
                      <Table.Cell>
                        {dayjs(duration.created_at).format("DD/MM/YYYY HH:mm")}
                      </Table.Cell>
                      <Table.Cell collapsing>
                        <Dropdown
                          pointing="right"
                          icon={<Icon name="ellipsis vertical" />}
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item
                              icon={<Icon name="edit" />}
                              content="Editer"
                              onClick={() => {
                                handleEditClick(duration.uuid);
                              }}
                            />
                            <>
                              <Dropdown.Item
                                icon={<Icon name="trash" />}
                                content="Supprimer"
                                onClick={() => {
                                  handleRemoveClick(duration.uuid);
                                }}
                              />
                            </>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Segment>
          </Grid.Column>
        </GridRow>
      </GridRow>
      <InsuranceDurationModalForm
        selectedItemId={selectedItemUuid}
        setOpen={setIsModalOpen}
        open={isModalOpen}
        callback={() => {
          setIsModalOpen(false);
          setSelectedItemUuid(null);
          getInsurancesDurations();
        }}
        handleCloseModal={() => {
          setIsModalOpen(false);
          setSelectedItemUuid(null);
        }}
      />
    </Page>
  );
}

export default InsurancesDurationListPage;
