import React from "react";
import { Link } from "react-router-dom";
import { Accordion, Icon, Menu } from "semantic-ui-react";
function PoolMenus() {
  const [activeIndex, setActiveIndex] = React.useState("dashboard");

  const handleItemClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? "" : index;
    setActiveIndex(newIndex);
  };
  return (
    <Accordion
      as={Menu}
      fluid
      vertical={true}
      style={{ borderRadius: "0px", border: "none" }}
    >
      <Menu.Item
        as={Link}
        to="pool/dashboard"
        active={activeIndex === "dashboard"}
        index={"dashboard"}
        onClick={handleItemClick}
      >
        <span>
          <Icon name="dashboard" /> Dashboard
        </span>
      </Menu.Item>
      <Menu.Item>
        <Accordion.Title
          active={activeIndex === "administration"}
          onClick={handleItemClick}
          index="administration"
        >
          <Icon name="briefcase" /> Administration
          <i className="right dropdown icon"></i>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === "administration"}>
          <Menu.Menu>
            <Menu.Item as={Link} to="pool/administration/compagnies">
              Compagnies
            </Menu.Item>
            <Menu.Item as={Link} to="pool/administration/intermediaires">
              Intermédiaires
            </Menu.Item>
            <Menu.Item as={Link} to="pool/administration/users">
              Utilisateurs
            </Menu.Item>
            <Menu.Item as="a">Autorisations</Menu.Item>
          </Menu.Menu>
        </Accordion.Content>
      </Menu.Item>
      <Menu.Item>
        <Accordion.Title
          active={activeIndex === "production"}
          index={"production"}
          onClick={handleItemClick}
        >
          <Icon name="bus" /> Production
          <i className="right dropdown icon"></i>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === "production"}>
          <Menu.Menu>
            <Menu.Item as={Link} to="pool/polices">
              Liste des polices
            </Menu.Item>
            <Menu.Item as="a">Déclaration de sinistre</Menu.Item>
          </Menu.Menu>
        </Accordion.Content>
      </Menu.Item>

      <Menu.Item>
        <Accordion.Title
          index="etats"
          active={activeIndex === "etats"}
          onClick={handleItemClick}
        >
          <Icon name="file alternate" /> Etats
          <i className="right dropdown icon"></i>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === "etats"}>
          <Menu.Menu>
            <Menu.Item as="a">Bordereaux de production du mois</Menu.Item>
            <Menu.Item as="a">Bordereaux des sinistres réglés</Menu.Item>
            <Menu.Item as="a">Bordereaux des avis de sinistre</Menu.Item>
            <Menu.Item as="a">Bordereaux Avis d'échéance</Menu.Item>
          </Menu.Menu>
        </Accordion.Content>
      </Menu.Item>

      <Menu.Item>
        <Accordion.Title
          index="stocks"
          active={activeIndex === "stocks"}
          onClick={handleItemClick}
        >
          <Icon name="box" /> Stocks
          <i className="right dropdown icon"></i>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === "stocks"}>
          <Menu.Menu>
            <Menu.Item
              as={Link}
              to="pool/stocks"
              active={activeIndex === "pool/stocks"}
              index={"pool/stocks"}
            >
              Stocks d'attestations
            </Menu.Item>
            <Menu.Item as={Link} to="pool/stocks/demandes">
              Demandes d'attestations
            </Menu.Item>
            {/*  <Menu.Item
              as={Link}
              to="pool/stocks/commandes"
              active={activeIndex === "pool/stocks/commandes"}
              index={"pool/stocks/commandes"}
            >
              Commandes d'attestations
            </Menu.Item> */}
          </Menu.Menu>
        </Accordion.Content>
      </Menu.Item>

      <Menu.Item>
        <Accordion.Title
          index="gestion"
          onClick={handleItemClick}
          active={activeIndex === "gestion"}
        >
          <Icon name="car crash" /> Gestion des sinistres
          <i className="right dropdown icon"></i>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === "gestion"}>
          <Menu.Menu>
            <Menu.Item as="a">Déclaration d'un sinistre</Menu.Item>
            <Menu.Item as="a">Estimation</Menu.Item>
            <Menu.Item as="a">Règlement</Menu.Item>
          </Menu.Menu>
        </Accordion.Content>
      </Menu.Item>

      <Menu.Item>
        <Accordion.Title
          index="cession"
          active={activeIndex === "cession"}
          onClick={handleItemClick}
        >
          <Icon name="expand alternate" /> Cessions & Rétrocessions
          <i className="right dropdown icon"></i>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === "cession"}>
          <Menu.Menu>
            <Menu.Item as="a">Cessions</Menu.Item>
            <Menu.Item as="a">Rétrocessions</Menu.Item>
          </Menu.Menu>
        </Accordion.Content>
      </Menu.Item>

      <Menu.Item>
        <Accordion.Title index="dictionnaire" onClick={handleItemClick}>
          <Icon name="book" /> Dictionnaire
          <i className="right dropdown icon"></i>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === "dictionnaire"}>
          <Menu.Menu>
            <Menu.Item as={Link} to="pool/dictionnaire/categories-contrats">
              Catégories de contrats
            </Menu.Item>
            <Menu.Item as={Link} to="pool/dictionnaire/sources-energies">
              Sources d'énergie
            </Menu.Item>
            <Menu.Item as={Link} to="pool/dictionnaire/zones-circulation">
              Zones de circulation
            </Menu.Item>
            <Menu.Item as={Link} to="pool/dictionnaire/civilites">
              Civilités
            </Menu.Item>
            <Menu.Item as={Link} to="pool/dictionnaire/professions">
              Professions
            </Menu.Item>
            <Menu.Item as={Link} to="pool/dictionnaire/pays">
              pays
            </Menu.Item>
            <Menu.Item as={Link} to="pool/dictionnaire/car-brands">
              Marques de voiture
            </Menu.Item>
            <Menu.Item as={Link} to="pool/dictionnaire/pieces-officielles">
              Pièces officielles
            </Menu.Item>
          </Menu.Menu>
        </Accordion.Content>
      </Menu.Item>
      <Menu.Item>
        <Accordion.Title
          index="parametres-tarification"
          onClick={handleItemClick}
          active={activeIndex === "parametres-tarification"}
        >
          <Icon name="cogs" /> Paramètres de tarification
          <i className="right dropdown icon"></i>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === "parametres-tarification"}>
          <Menu.Menu>
            <Menu.Item
              as={Link}
              to="pool/production/parametres-tarification/directives"
            >
              Directives de tarification
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="pool/production/parametres-tarification/durees-assurances"
            >
              Durées d'assurance
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="pool/production/parametres-tarification/liste-garanties"
            >
              Liste des garanties
            </Menu.Item>
          </Menu.Menu>
        </Accordion.Content>
      </Menu.Item>
    </Accordion>
  );
}

export default PoolMenus;
