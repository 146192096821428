import React from "react";
import Page from "../../components/Page";
import { Dropdown, Grid, Header, Segment, Tab } from "semantic-ui-react";
import PaneInfos from "../../components/sinistres/add/PaneInfos";

const panes = [
  { menuItem: "Informations générales", render: () => <PaneInfos /> },
  { menuItem: "Notes", render: () => <Tab.Pane>Tab 2 Content</Tab.Pane> },
];

function SinistreAdd() {
  return (
    <Page title="Ajouter un sinistre">
      <Grid.Row>
        <Grid.Column width={16}>
          <Header dividing>Définition du sinistre</Header>
        </Grid.Column>
        <Grid.Column width={16} as={Segment}>
          <div className="ui grid ">
            <div
              className="column four wide"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="text-gray-700 text-xl font-bold">Sinistre</div>
            </div>
            <div className="column four wide">
              <label>Police</label>
              <Dropdown
                placeholder="Selectionner un sinistre"
                fluid
                search
                selection
                options={[]}
              />
            </div>
            <div className="column four wide">
              <label>Avenant</label>
              <Dropdown
                placeholder="Selectionner un sinistre"
                fluid
                search
                selection
                options={[]}
              />
            </div>
          </div>
        </Grid.Column>

        <Grid.Column width={16}>
          <Tab panes={panes} />
        </Grid.Column>
      </Grid.Row>
    </Page>
  );
}

export default SinistreAdd;
