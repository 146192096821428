import { api } from "../../../axios/api";

export const getAvariesByDirectiveTatifaire = async (directive_uuid) => {
  return api.get(
    `/gestion-production/avaries-facultatives/${directive_uuid}/directives-tarifaires`
  );
};

export const create = async (data) => {
  return api.post("/gestion-production/avaries-facultatives", data);
};
export const update = async (uuid, data) => {
  return api.put("/gestion-production/avaries-facultatives/" + uuid, data);
};

export const removeItem = async (uuid) => {
  return api.delete(`/gestion-production/avaries-facultatives/${uuid}`);
};

export const getByActiveDirectiveTarifaires = async () => {
  return api.get(
    `/gestion-production/avaries-facultatives/active/directives-tarifaires`
  );
};
