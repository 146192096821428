import React, { useEffect } from "react";
import { Button, Checkbox, Form, Input, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as directivesTarifairesService from "../../../../services/gestion_production/directivesTarifairesService";
import dayjs from "dayjs";

const validationSchema = yup.object().shape({
  signing_date: yup
    .date("DD/MM/YYYY")
    .required("La date de signature est obligatoire"),
  enforcement_date: yup
    .date("DD/MM/YYYY")
    .required("La date de mise en vigueur est obligatoire"),
  is_active: yup.number().default(0),
});
function DirectiveTarifaireModalForm({
  callback = () => {},
  open = false,
  setOpen,
  selectedItemId,
  handleCloseModal = () => {},
}) {
  const [directive, setDirective] = React.useState(null);

  const formik = useFormik({
    initialValues: {
      signing_date: "",
      enforcement_date: "",
      is_active: 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const request = selectedItemId
        ? directivesTarifairesService.updateDirectivesTarifaires(
            selectedItemId,
            values
          )
        : directivesTarifairesService.createDirectivesTarifaires(values);

      request
        .then((response) => {
          toast.success(
            `Directive tarifaire ${selectedItemId ? "mise à jour" : "ajoutée"}`
          );
          handleCloseModal();
          callback();
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.error ||
              `Une erreur est survenue lors de ${
                selectedItemId ? "la mise à jour" : "l'ajout"
              } de la directive tarifaire`
          );
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
    validateOnBlur: false,
    validateOnChange: false,
  });

  const {
    values,
    errors,
    handleSubmit,
    isSubmitting,
    handleBlur,
    handleChange,
  } = formik;

  useEffect(() => {
    formik.resetForm();
    if (selectedItemId) {
      directivesTarifairesService.fetchOne(selectedItemId).then((response) => {
        if (typeof response.data === "object") {
          const data = response.data;
          setDirective(data);
          formik.setFieldValue(
            "signing_date",
            dayjs(data.signing_date).format("YYYY-MM-DD")
          );
          formik.setFieldValue(
            "enforcement_date",
            dayjs(data.enforcement_date).format("YYYY-MM-DD")
          );

          formik.setFieldValue("is_active", data.is_active);
        }
      });
    }

    return () => {};
  }, [selectedItemId]);

  return (
    <Modal
      onClose={() => {
        handleCloseModal();
      }}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      size="tiny"
      closeIcon
      onUnmount={() => {
        formik.resetForm();
      }}
    >
      <Modal.Header>
        {selectedItemId ? "Modifier" : "Ajouter"} une directive tarifaire
      </Modal.Header>
      <Modal.Content>
        <Modal.Description className="ui form">
          <div className="mt-4">
            <Form.Field required error={"signing_date" in errors}>
              <label>Date de signature</label>
              <Input
                type="date"
                name="signing_date"
                value={values.signing_date}
                onChange={handleChange("signing_date")}
                onBlur={handleBlur("signing_date")}
              />
              <small className="field-error">{errors.signing_date}</small>
            </Form.Field>
            <Form.Field required error={"enforcement_date" in errors}>
              <label>Date de mise en vigueur</label>
              <input
                type="date"
                name="enforcement_date"
                value={values.enforcement_date}
                onChange={handleChange("enforcement_date")}
                onBlur={handleBlur("enforcement_date")}
              />
              <small className="field-error">{errors.enforcement_date}</small>
            </Form.Field>
            <Form.Field>
              <Checkbox
                toggle
                label="Actif"
                name="is_active"
                checked={values.is_active === 1}
                onChange={(e, { checked }) =>
                  formik.setFieldValue("is_active", checked ? 1 : 0)
                }
              />
            </Form.Field>
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          onClick={() => {
            handleCloseModal();
          }}
        >
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          type="submit"
          loading={isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default DirectiveTarifaireModalForm;
