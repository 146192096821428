import React from "react";
import {
  Button,
  Grid,
  GridRow,
  Header,
  Icon,
  Segment,
  Table,
  Input
} from "semantic-ui-react";

function PiecesJointesPane() {
  return (
    <GridRow  style={{ marginTop: "30px" }}>
      <Grid stackable>
        <Grid.Column
          width={8}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Header>Liste des pièces jointes</Header>
        </Grid.Column>
        <Grid.Column
          width={8}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Input placeholder="Rechercher..." className="search">
            <input name="search" />
          </Input>
          <Button
            style={{ marginLeft: "10px" }}
            circular
            icon={<Icon name="add" />}
          />
        </Grid.Column>
      </Grid>

      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>#</Table.HeaderCell>
            <Table.HeaderCell>Dénomination</Table.HeaderCell>
            <Table.HeaderCell>Téléphones</Table.HeaderCell>
            <Table.HeaderCell>Adresse</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body></Table.Body>
      </Table>
    </GridRow>
  );
}

export default PiecesJointesPane;
