import React, { useEffect } from "react";
import {
  Button,
  Divider,
  Form,
  Input,
  List,
  ListDescription,
  ListHeader,
  ListItem,
  Modal,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as deliveriesService from "../../../services/gestion_attestations/company/deliveries";
import * as magasinsService from "../../../services/gestion_attestations/company/magasinsService";
import * as magasinsStocksService from "../../../services/gestion_attestations/company/magasinsStockService";

const validationSchema = yup.object().shape({
  start_serial: yup
    .number("Veuillez renseigner un numéro de série de début valide")
    .required("Veuillez renseigner le numéro de série de début"),
  end_serial: yup
    .number("Veuillez renseigner un numéro de série de fin valide")
    .min(
      yup.ref("start_serial"),
      "Le numéro de série de fin doit être supérieur ou égal au numéro de série de début"
    )
    .required("Veuillez renseigner le numéro de série de fin"),
  magasin_uuid: yup
    .string()
    .uuid("Veuillez sélectionner un magasin")
    .required("Veuillez sélectionner un magasin"),
});
function MagasinStockAttributionFormModal({
  callback = () => {},
  open = false,
  setOpen,
  selectedItemId,
  deliveryUuid = null,
  handleCloseModal = () => {},
}) {
  const [delivery, setDelivery] = React.useState(null);
  const [stock, setStock] = React.useState(null);
  const [magasins, setMagasins] = React.useState([]);
  const inputQtyRef = React.useRef(null);
  const formik = useFormik({
    initialValues: {
      start_serial: "",
      end_serial: "",
      magasin_uuid: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (!selectedItemId) {
        values.delivery_uuid = delivery?.uuid;
      }

      const request = selectedItemId
        ? magasinsStocksService.update(selectedItemId, values)
        : magasinsStocksService.create(values);

      request
        .then(() => {
          toast.success(
            `Attribution d'attestation ${
              selectedItemId ? "mise à jour" : "ajoutée"
            }`
          );
          handleCloseModal();
          callback();
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.error ||
              `Une erreur est survenue lors de ${
                selectedItemId ? "la mise à jour" : "l'ajout"
              } de l'attribution d'attestation`
          );
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
  });

  const { values, errors, handleSubmit, isSubmitting } = formik;

  const fetchDelivery = (selectedItemId) => {
    deliveriesService
      .fetchOne(selectedItemId)
      .then((response) => {
        if (typeof response.data === "object") {
          const data = response.data;
          setDelivery(data);
        }
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.error ||
            "Une erreur est survenue lors de la récupération des informations"
        );
      })
      .finally(() => {});
  };

  const fetchMagasinStock = (selectedItemId) => {
    magasinsStocksService
      .fetchOne(selectedItemId)
      .then((response) => {
        if (typeof response.data === "object") {
          const data = response.data;
          formik.setValues({
            magasin_uuid: data.attestations_magasins?.uuid,
            start_serial: data.start_serial,
            end_serial: data.end_serial,
          });
          setStock(data);
        }
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.error ||
            "Une erreur est survenue lors de la récupération des informations"
        );
      })
      .finally(() => {});
  };

  const fetchMagasins = () => {
    magasinsService
      .fetchAll()
      .then((response) => {
        if (Array.isArray(response.data)) {
          const data = response.data;
          setMagasins(data?.filter((magasin) => magasin.is_default === 0));
        }
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.error ||
            "Une erreur est survenue lors de la récupération des informations"
        );
      })
      .finally(() => {});
  };

  useEffect(() => {
    formik.resetForm();
    if (open && deliveryUuid) fetchDelivery(deliveryUuid);
  }, [open, deliveryUuid]);

  useEffect(() => {
    if (selectedItemId) fetchMagasinStock(selectedItemId);
  }, [selectedItemId]);

  useEffect(() => {
    fetchMagasins();
  }, []);

  return (
    <Modal
      onClose={() => {
        handleCloseModal();
      }}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      size="tiny"
      closeIcon
    >
      <Modal.Header>
        {selectedItemId ? "Modifier" : "Ajouter"} une attribution de stock
      </Modal.Header>
      <Modal.Content>
        <Modal.Description className="ui form">
          <List horizontal className="w-full">
            <ListItem className="w-[45%] text-left">
              <ListHeader as="a">Quantité disponible</ListHeader>
              <ListDescription className="mt-2">
                {new Intl.NumberFormat().format(
                  delivery?.usage?.remainingQty || 0
                )}
              </ListDescription>
            </ListItem>
            <ListItem className="w-[45%] text-right">
              <ListHeader as="a">Plage disponible</ListHeader>
              <ListDescription className="mt-2">
                {delivery?.usage?.remainingQty > 0 ? (
                  <>
                    {`${delivery?.usage?.available_start_serial} - ${delivery?.usage?.available_end_serial}`}
                  </>
                ) : (
                  "Toutes les attestations sont déjà attribuées"
                )}
              </ListDescription>
            </ListItem>
          </List>
          <Divider />
          <div className="mt-4">
            <div>
              <Form.Dropdown
                fluid
                selection
                required={true}
                label="Magasin"
                placeholder="Selectionner un magasin"
                name="magasin_uuid"
                value={values.magasin_uuid}
                onChange={(e, { value }) => {
                  formik.setFieldValue("magasin_uuid", value);
                }}
                onBlur={(e, { value }) => {
                  formik.setFieldValue("magasin_uuid", value);
                }}
                options={[
                  {
                    key: "",
                    text: "Choisir un magasin",
                    value: "",
                  },
                  ...magasins.map((magasin) => ({
                    key: magasin.uuid,
                    text: `${
                      magasin.name
                    } (${magasin.type_magasin?.toLowerCase()})`,
                    value: magasin.uuid,
                  })),
                ]}
              />
              <small className="field-error">{errors.magasin_uuid}</small>
            </div>

            <div className="mt-4 mb-4">
              <Form.Field width={8}>
                <label>Quantité</label>
                <input
                  ref={inputQtyRef}
                  type="number"
                  onChange={(e) => {
                    const quantity = parseInt(e.target.value);
                    if (!isNaN(quantity) && quantity > 0) {
                      const startSerial =
                        delivery?.usage?.available_start_serial || 0;
                      formik.setFieldValue("start_serial", startSerial);
                      formik.setFieldValue(
                        "end_serial",
                        (Number(startSerial) + Number(quantity) - 1)
                          .toString()
                          .padStart(formik.values.start_serial.length, "0")
                      );
                    }
                  }}
                />
              </Form.Field>
            </div>

            <Form.Group widths="equal">
              <Form.Field required error={"start_serial" in errors}>
                <label>Série de début</label>
                <Input
                  type="text"
                  name="start_serial"
                  value={values.start_serial}
                  onChange={(e) => {
                    const startSerial = e.target.value;
                    formik.setFieldValue("start_serial", startSerial);
                    formik.setFieldValue(
                      "end_serial",
                      (
                        Number(startSerial) +
                        Number(inputQtyRef.current.value) -
                        1
                      )
                        .toString()
                        .padStart(formik.values.start_serial.length, "0")
                    );

                    inputQtyRef.current.value = "";
                  }}
                  min={delivery?.usage?.available_start_serial}
                  max={delivery?.usage?.available_end_serial}
                  error={errors.start_serial}
                />
                <small className="field-error">{errors.start_serial}</small>
              </Form.Field>
              <Form.Field required error={"end_serial" in errors}>
                <label>Série de fin</label>
                <Input
                  type="text"
                  name="end_serial"
                  value={values.end_serial}
                  onChange={(e) => {
                    const endSerial = e.target.value;
                    formik.setFieldValue("end_serial", endSerial);

                    inputQtyRef.current.value = "";
                  }}
                  min={values.start_serial}
                  max={delivery?.usage?.available_end_serial}
                  error={errors.end_serial}
                />
              </Form.Field>
            </Form.Group>
            {!isNaN(Number(values.start_serial)) &&
              !isNaN(Number(values.end_serial)) &&
              Number(values.start_serial) < Number(values.end_serial) && (
                <div className="text-right font-bold text-gray-500">
                  Quantité d'attestations:{" "}
                  {new Intl.NumberFormat().format(
                    Number(values.end_serial) - Number(values.start_serial) + 1
                  )}
                </div>
              )}
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          onClick={() => {
            handleCloseModal();
          }}
        >
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          type="submit"
          loading={isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default MagasinStockAttributionFormModal;
