import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Grid, Header, Segment } from "semantic-ui-react";

const StockDoughnutChart = ({
  total,
  consumed,
  remaining,
  labels = [
    {
      label: "Livrée",
      value: consumed,
      color: "#FF6384",
    },
    {
      label: "Restant",
      value: remaining,
      color: "#36A2EB",
    },
  ],
}) => {
  const data = {
    labels: labels.map((label) => label.label),
    datasets: [
      {
        data: labels.map((label) => label.value),
        backgroundColor: labels.map((label) => label.color),
        hoverBackgroundColor: labels.map((label) => label.color),
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    height: 200,
  };

  return (
    <Grid columns={2}>
      <Grid.Column>
        <Segment style={{ height: "200px" }}>
          <Doughnut data={data} options={options} />
        </Segment>
      </Grid.Column>
      <Grid.Column>
        <Segment style={{ height: "200px" }}>
          <Header as="h4" dividing className="mb-4 !text-gray-500">
            Récapitulatif
          </Header>
          <div className="flex flex-row justify-between">
            <div className="text-lg font-bold">Total:</div>
            <div className="text-lg font-bold">
              {new Intl.NumberFormat().format(total)}
            </div>
          </div>
          <hr className="my-2" />
          <div className="flex flex-row justify-between">
            <div className="text-lg font-bold">{labels[0].label}:</div>
            <div className="text-lg font-bold">
              {new Intl.NumberFormat().format(labels[0].value)}
            </div>
          </div>
          <hr className="my-2" />
          <div className="flex flex-row justify-between">
            <div className="text-lg font-bold">{labels[1].label}:</div>
            <div className="text-lg font-bold">
              {new Intl.NumberFormat().format(labels[1].value)}
            </div>
          </div>
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default StockDoughnutChart;
