import { api } from "../axios/api";

export const fetchAll = async (query = null) => {
  return api.get(`users?${query !== null ? query : ""}`);
};

export const fetchOne = async (id) => {
  return api.get(`users/${id}`);
};
export const create = async (data) => {
  return api.post(`users`, data);
};
