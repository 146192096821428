// utils.js
import dayjs from "dayjs";
import * as garantiesVolIncendieService from "../services/gestion_production/pool/tableauGarantiesVolIncendieService";
import {
  BRIS_DE_GLACES_ENUMS,
  GARANTIES_INCENDIE_ENUMS,
  GARANTIES_VOL_ENUMS,
} from "./constants";

export const handleVolRisqueChange = async (
  garanties,
  garantiesIndex,
  risque,
  values,
  setFieldValue
) => {
  try {
    if (
      risque.libelle === GARANTIES_VOL_ENUMS.VOL_A_MAIN_ARMEE ||
      risque.libelle === GARANTIES_VOL_ENUMS.VOL_SIMPLE
    ) {
      const prime = await getPrimeIncendieOrVol(
        values.identite_vehicule.valeur_a_neuf,
        values.identite_vehicule.date_premiere_mise_en_circulation
      );
      if (!prime) {
        return;
      }
      let updatedRisques = garanties[garantiesIndex].risques.map((r) => {
        if (r.libelle !== risque.libelle) {
          return {
            ...r,
            value: 0,
            checked: false,
          };
        }
        return r;
      });

      if (!risque.checked) {
        let risqueToUpdate = updatedRisques.find(
          (r) => r.libelle === risque.libelle
        );
        risqueToUpdate.value = 0;
        risqueToUpdate.checked = false;
        updatedRisques = updatedRisques.map((r) => {
          if (r.libelle === risque.libelle) {
            return risqueToUpdate;
          }
          return r;
        });

        garanties[garantiesIndex].risques = updatedRisques;
        return setFieldValue("garanties", garanties, true);
      }

      let risqueToUpdate = updatedRisques.find(
        (r) => r.libelle === risque.libelle
      );
      risqueToUpdate.value =
        risque.libelle === GARANTIES_VOL_ENUMS.VOL_A_MAIN_ARMEE
          ? prime.armed_robbery_premium
          : prime.simple_theft_premium;
      risqueToUpdate.checked = true;

      garanties[garantiesIndex].risques = updatedRisques;
      setFieldValue("garanties", garanties, true);
    }
  } catch (error) {
    console.log(error?.response?.data?.error || "Une erreur est survenue");
  }
};

export const handleIncendieRisqueChange = async (
  vehiculePrice,
  firstPutIntoCirculationDate,
  garanties,
  garantiesIndex,
  risque,
  values,
  setFieldValue
) => {
  try {
    if (risque.libelle === GARANTIES_INCENDIE_ENUMS.INCENDIE) {
      const isValidDate = dayjs(firstPutIntoCirculationDate).isValid();
      if (!isValidDate) {
        return;
      }

      if (vehiculePrice <= 0) {
        return;
      }
      const prime = await getPrimeIncendieOrVol(
        vehiculePrice,
        firstPutIntoCirculationDate
      );
      if (!prime) {
        return;
      }

      let updatedRisques = garanties[garantiesIndex].risques.map((r) => {
        if (r.libelle !== risque.libelle) {
          return {
            ...r,
            value: 0,
            checked: false,
          };
        }
        return r;
      });

      if (!risque.checked) {
        let risqueToUpdate = updatedRisques.find(
          (r) => r.libelle === risque.libelle
        );
        risqueToUpdate.value = 0;
        risqueToUpdate.checked = false;
        garanties[garantiesIndex].risques = updatedRisques;
        return setFieldValue("garanties", garanties, true);
      }

      let risqueToUpdate = updatedRisques.find(
        (r) => r.libelle === risque.libelle
      );
      risqueToUpdate.value = prime.fire_premium || 0;
      risqueToUpdate.checked = true;

      garanties[garantiesIndex].risques = updatedRisques;
      setFieldValue("garanties", garanties, true);
    }
  } catch (error) {
    console.log(error?.response?.data?.error || "Une erreur est survenue");
  }
};

export const getPrimeIncendieOrVol = async (
  vehiculePrice,
  firstPutIntoCirculationDate
) => {
  const isValidDate = dayjs(firstPutIntoCirculationDate).isValid();
  if (!isValidDate) {
    return;
  }

  if (vehiculePrice <= 0) {
    return;
  }

  const requestParams = {
    vehicule_price: vehiculePrice,
    first_put_into_circulation_date: firstPutIntoCirculationDate,
  };

  try {
    const res =
      await garantiesVolIncendieService.getByActiveDirectiveTarifaires(
        requestParams
      );
    return res.data;
  } catch (error) {
    console.log(error?.response?.data?.error || "Une erreur est survenue");
  }
};

//handle vehiculeprince change or first put into circulation date change
export const handleVehiculePriceOrFirstPutIntoCirculationDateChange = async (
  vehiculePrice,
  firstPutIntoCirculationDate,
  values,
  setFieldValue
) => {
  try {
    const isValidDate = dayjs(firstPutIntoCirculationDate).isValid();
    if (!isValidDate) {
      return;
    }

    if (vehiculePrice <= 0) {
      return;
    }

    const CONCERNED_RISQUES = [
      GARANTIES_VOL_ENUMS.VOL_A_MAIN_ARMEE,
      GARANTIES_VOL_ENUMS.VOL_SIMPLE,
      GARANTIES_INCENDIE_ENUMS.INCENDIE,
    ];
    const response = await getPrimeIncendieOrVol(
      vehiculePrice,
      firstPutIntoCirculationDate
    );

    if (!response) {
      return;
    }

    const prime = response;

    if (!prime) {
      return;
    }

    CONCERNED_RISQUES.forEach((risqueName) => {
      const garantie = values.garanties.find(
        (g) => g.libelle === GARANTIES_VOL_ENUMS.VOL
      );

      const risque = garantie.risques.find((r) => r.libelle === risqueName);

      if (!risque) {
        return;
      }

      if (!risque.checked) {
        return;
      }

      switch (risqueName) {
        case GARANTIES_VOL_ENUMS.VOL_A_MAIN_ARMEE:
          risque.value = prime.armed_robbery_premium || 0;
          break;
        case GARANTIES_VOL_ENUMS.VOL_SIMPLE:
          risque.value = prime.simple_theft_premium || 0;
          break;
        case GARANTIES_INCENDIE_ENUMS.INCENDIE:
          risque.value = prime.fire_premium || 0;
          break;
        default:
          break;
      }

      const garanties = values.garanties.map((g) => {
        if (g.libelle === GARANTIES_VOL_ENUMS.VOL) {
          return {
            ...g,
            risques: g.risques.map((r) =>
              r.libelle === risqueName ? risque : r
            ),
          };
        }
        return g;
      });
      setFieldValue("garanties", garanties, true);
    });
  } catch (error) {
    console.log(error?.response?.data?.error || "Une erreur est survenue");
  }
};

export const getBriseDeGlacePrime = (tauxBrisDeGlaces, vehiculePrice) => {
  try {
    if (!tauxBrisDeGlaces || !vehiculePrice) {
      return;
    }
    return Math.floor((tauxBrisDeGlaces * vehiculePrice) / 100);
  } catch (error) {
    console.log("error", error);
  }
};

export const changeBrisDeGlaceValue = (
  vehiculePrice,
  tauxBrisDeGlaces,
  values,
  setFieldValue
) => {
  try {
    const prime = getBriseDeGlacePrime(tauxBrisDeGlaces, vehiculePrice);

    const garantie = values.garanties.find(
      (g) => g.libelle === BRIS_DE_GLACES_ENUMS.BRIS_DE_GLACES
    );

    const risque = garantie.risques.find(
      (r) => r.libelle === BRIS_DE_GLACES_ENUMS.BRIS_DE_GLACES
    );

    if (!risque) {
      return;
    }

    risque.value = prime || 0;
    risque.checked = !risque.checked;

    if (risque.checked === false) {
      risque.value = 0;
    }

    const updatedGarantie = {
      ...garantie,
      risques: garantie.risques.map((r) =>
        r.libelle === BRIS_DE_GLACES_ENUMS.BRIS_DE_GLACES ? risque : r
      ),
    };

    const garanties = values.garanties.map((g) =>
      g.libelle === BRIS_DE_GLACES_ENUMS.BRIS_DE_GLACES ? updatedGarantie : g
    );

    setFieldValue("garanties", garanties, true);
  } catch (error) {
    console.log("error", error);
  }
};

export const handleAvariesFacultativesRisqueChange = (
  updatedGaranties,
  index,
  updatedRisque,
  values,
  setFieldValue
) => {
  try {
    const garantie = updatedGaranties[index];
    const risque = garantie.risques.find(
      (r) => r.libelle === updatedRisque.libelle
    );

    if (!risque) {
      return;
    }

    if (risque.checked) {
      setFieldValue("pool_avaries_facultatives_uuid", 0, true);
    }

    risque.value = updatedRisque.value;
    risque.checked = updatedRisque.checked;

    const updatedGarantie = {
      ...garantie,
      risques: garantie.risques.map((r) =>
        r.libelle === updatedRisque.libelle ? risque : r
      ),
    };

    const garanties = updatedGaranties.map((g) =>
      g.libelle === garantie.libelle ? updatedGarantie : g
    );

    if (risque.checked === false) {
      risque.value = 0;
    }

    setFieldValue("garanties", garanties, true);
  } catch (error) {
    console.log("error", error);
  }
};

export const isActiveRique = (garanties, garantieName, risqueName) => {
  try {
    const isBrisDeGlaceChecked = garanties
      .find((g) => g.libelle === garantieName)
      .risques.find((r) => r.libelle === risqueName)?.checked;

    return isBrisDeGlaceChecked;
  } catch (error) {
    return false;
  }
};

export const updateGaranties = (garanties, garantieName, risqueName, value) => {
  try {
    const garantie = garanties.find((g) => g.libelle === garantieName);
    const risque = garantie.risques.find((r) => r.libelle === risqueName);
    risque.value = value;
    const updatedGarantie = {
      ...garantie,
      risques: garantie.risques.map((r) =>
        r.libelle === risqueName ? risque : r
      ),
    };
    return garanties.map((g) =>
      g.libelle === garantieName ? updatedGarantie : g
    );
  } catch (error) {
    console.log("error", error);
  }
};

export const calculateFranchiseDommageVehicule = (
  valeurNeuve,
  selectedAvariesFacultativesUuid,
  companyDirectiveValues,
  avariesFacultatives
) => {
  try {
    const tauxPrimeDommage = companyDirectiveValues?.find(
      (item) => item.name === "taux_prime_dommage"
    )?.value;

    const avariesFacultativesSelected = avariesFacultatives.find(
      (avary) => avary.uuid === selectedAvariesFacultativesUuid
    );

    if (!avariesFacultativesSelected) {
      return 0;
    }
    const primeDommage =
      valeurNeuve *
      (tauxPrimeDommage / 100) *
      (1 - avariesFacultativesSelected.reduction / 100);

    return primeDommage;
  } catch (error) {
    console.log("error", error);
    return 0;
  }
};

export const calculateTaxes = (
  primeNetteTotale,
  montantGestionPool,
  coutPolice,
  directivesTarifairesValues
) => {
  try {
    const tauxTaxeContratAssurance = directivesTarifairesValues?.find(
      (item) => item.name === "taxe_contrat_assurance"
    )?.value;

    return (
      ((primeNetteTotale + montantGestionPool + coutPolice) *
        tauxTaxeContratAssurance) /
      100
    );
  } catch (error) {
    console.log("error", error);
    return 0;
  }
};
