import { api } from "../../../axios/api";

export const getAll = async (params) => {
  return api.get(`/gestion-production/companies/garanties-complementaires`, {
    params,
  });
};

export const create = async (data) => {
  return api.post(
    `/gestion-production/companies/garanties-complementaires`,
    data
  );
};

export const update = async (uuid, data) => {
  return api.put(
    `/gestion-production/companies/garanties-complementaires/${uuid}`,
    data
  );
};

export const deleteOne = async (uuid) => {
  return api.delete(
    `/gestion-production/companies/garanties-complementaires/${uuid}`
  );
};

export const fetchOne = async (uuid) => {
  return api.get(
    `/gestion-production/companies/garanties-complementaires/${uuid}`
  );
};
