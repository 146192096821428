import React, { useEffect, useState } from "react";
import Page from "../../../../../../components/Page";
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Segment,
  Table,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import * as directivesTarificationsService from "../../../../../../services/gestion_production/directivesTarifairesService";
import dayjs from "dayjs";

function DirectivesTarificationsList() {
  const [directivesTarifications, setDirectivesTarifications] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemUuid, setSelectedItemUuid] = useState(null);

  const getDirectivesTarifications = () => {
    directivesTarificationsService
      .getDirectivesTarifaires()
      .then((res) => {
        setDirectivesTarifications(res.data);
      })
      .catch((err) => {
        toast.error("Opération échouée");
      });
  };

  const handleEditClick = (uuid) => {
    setSelectedItemUuid(uuid);
    setIsModalOpen(true);
  };

  useEffect(() => {
    getDirectivesTarifications();
  }, []);

  return (
    <Page title="Directives de tarification">
      <GridRow className="Souscriptions">
        <Grid.Row className="!mb-5">
          <Grid.Column width={16}>
            <Breadcrumb>
              <BreadcrumbSection link>Compagnie</BreadcrumbSection>
              <BreadcrumbDivider icon="right chevron" />
              <BreadcrumbSection link>
                Gestion de la production
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection link>
                Paramètres de tarifications
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection active>
                Directives de tarification
              </BreadcrumbSection>
            </Breadcrumb>
            <Divider />
          </Grid.Column>
        </Grid.Row>
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid>
              <Grid.Column>
                <Header>
                  Tarif automobile catégorie 4 & directives tarifaires
                </Header>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              gap: "10px",
            }}
          ></Grid.Column>
        </Grid>
        <GridRow>
          <Grid.Column className="!mt-6">
            <Segment>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell collapsing>#</Table.HeaderCell>
                    <Table.HeaderCell>Date de signature</Table.HeaderCell>
                    <Table.HeaderCell>Date de mise en vigueur</Table.HeaderCell>
                    <Table.HeaderCell>Date de création</Table.HeaderCell>
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {directivesTarifications?.map((directive, index) => (
                    <Table.Row key={index} className={`${directive.is_active  === 1 ? "green" : ""}`}>
                      <Table.Cell collapsing>{index + 1}</Table.Cell>
                      <Table.Cell>
                        {dayjs(directive.signing_date).format("DD/MM/YYYY")}
                      </Table.Cell>
                      <Table.Cell>
                        {dayjs(directive.enforcement_date).format("DD/MM/YYYY")}
                      </Table.Cell>
                      <Table.Cell>
                        {dayjs(directive.created_at).format("DD/MM/YYYY HH:mm")}
                      </Table.Cell>
                      <Table.Cell collapsing>
                        <Dropdown
                          pointing="right"
                          icon={<Icon name="ellipsis vertical" />}
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item
                              as={Link}
                              icon={<Icon name="eye" />}
                              content="Détails"
                              to={`/company/production/parametres-tarification/directives/details/${directive.uuid}`}
                            />
                          </Dropdown.Menu>
                        </Dropdown>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Segment>
          </Grid.Column>
        </GridRow>
      </GridRow>
    </Page>
  );
}

export default DirectivesTarificationsList;
