import React, { useEffect, useState } from "react";
import Page from "../../../../../../components/Page";
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Button,
  Divider,
  Grid,
  GridRow,
  Header,
  Icon,
  Label,
  List,
  Segment,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import * as directivesTarificationsService from "../../../../../../services/gestion_production/directivesTarifairesService";
import dayjs from "dayjs";
import TableauGarantiesRcAn from "../../../../../../components/production/tarifification/pool/TableauGarantiesRcAn";
import TableauConversionForcesFiscales from "../../../../../../components/production/tarifification/pool/TableauConversionForcesFiscales";
import InsurancesDurations from "../../../../../../components/production/tarifification/pool/InsurancesDurations";
import CompanyListeDesGaranties from "../../../../../../components/production/tarifification/company/CompanyListeDesGaranties";
import DirectivesTarifairesValues from "../../../../../../components/production/tarifification/pool/DirectivesTarifairesValues";
import AvariesFacultatives from "../../../../../../components/production/tarifification/pool/AvariesFacultatives";
import TableauGarantiesVolIncedie from "../../../../../../components/production/tarifification/pool/TableauGarantiesVolIncedie";
import CompanyDirectivesTarifairesValues from "../../../../../../components/production/tarifification/company/CompanyDirectivesTarifairesValues";

function DirectivesTarificationsDetails() {
  const [directive, setDirective] = useState([]);

  const navigate = useNavigate();
  const { uuid } = useParams();
  const getDirectivesTarification = () => {
    directivesTarificationsService
      .fetchOne(uuid)
      .then((res) => {
        setDirective(res.data);
      })
      .catch((err) => {
        toast.error("Opération échouée");
      });
  };

  useEffect(() => {
    getDirectivesTarification();
  }, [uuid]);

  return (
    <Page title="Directives de tarification">
      <GridRow className="Souscriptions">
        <Grid.Row className="!mb-5">
          <Grid.Column width={16}>
            <Breadcrumb>
              <BreadcrumbSection link>Compangie</BreadcrumbSection>
              <BreadcrumbDivider icon="right chevron" />
              <BreadcrumbSection link>
                Gestion de la production
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection link>
                Paramètres de tarifications
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection active>
                Directives de tarification
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection active>Détails</BreadcrumbSection>
            </Breadcrumb>
            <Divider />
          </Grid.Column>
        </Grid.Row>
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid>
              <Grid.Column>
                <Header>
                  Tarif automobile catégorie 4 & directives tarifaires
                </Header>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              gap: "10px",
            }}
          >
            <Button
              onClick={() => {
                navigate(-1);
              }}
            >
              <Icon name="arrow left" />
              Retour
            </Button>
          </Grid.Column>
        </Grid>
        <GridRow className="!mt-6">
          <GridRow as={Segment}>
            <Label ribbon>Détails</Label>
            <Grid stackable columns={3} style={{ marginTop: "5px" }}>
              <Grid.Column>
                <List>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Date de création</List.Header>
                      <List.Description>
                        {dayjs(directive.created_at).format("DD/MM/YYYY")}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column>
                <List>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Date de signature</List.Header>
                      <List.Description>
                        {dayjs(directive.signing_date).format("DD/MM/YYYY")}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column>
                <List>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Date de mise en vigueur</List.Header>
                      <List.Description>
                        {dayjs(directive.enforcement_date).format("DD/MM/YYYY")}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid>
          </GridRow>

          <TableauGarantiesRcAn directiveUuid={uuid} />
          <CompanyListeDesGaranties directiveUuid={uuid} />
          <InsurancesDurations directiveUuid={uuid} />
          <DirectivesTarifairesValues directiveUuid={uuid} />
          <CompanyDirectivesTarifairesValues directiveUuid={uuid} />
          <AvariesFacultatives directiveUuid={uuid} />
          <TableauGarantiesVolIncedie directiveUuid={uuid} />
          <TableauConversionForcesFiscales directiveUuid={uuid} />
        </GridRow>
      </GridRow>
    </Page>
  );
}

export default DirectivesTarificationsDetails;
