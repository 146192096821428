import React, { useEffect } from "react";
import { Button, Form, Input, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as garantiesServices from "../../../../services/gestion_production/garantiesServices";

const validationSchema = yup.object().shape({
  name: yup
    .string("Veuillez renseigner un nom valide")
    .required("Veuillez renseigner un nom valide")
    .min(2, "Le nom doit contenir au moins 1 caractère")
    .max(255, "Le nom ne doit pas contenir plus de 255 caractères"),
});
function GarantiesModalForm({
  callback = () => {},
  open = false,
  setOpen,
  selectedItemId,
  handleCloseModal = () => {},
}) {
  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const request = selectedItemId
        ? garantiesServices.update(selectedItemId, values)
        : garantiesServices.create(values);

      request
        .then((response) => {
          toast.success(
            `Durée de garantie ${selectedItemId ? "mise à jour" : "ajoutée"}`
          );
          handleCloseModal();
          callback();
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.error ||
              `Une erreur est survenue lors de ${
                selectedItemId ? "la mise à jour" : "l'ajout"
              } de la garantie`
          );
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
  });

  const {
    values,
    errors,
    handleSubmit,
    isSubmitting,
    handleBlur,
    handleChange,
  } = formik;

  useEffect(() => {
    formik.resetForm();
    if (selectedItemId) {
      garantiesServices.fetchOne(selectedItemId).then((response) => {
        if (typeof response.data === "object") {
          const data = response.data;
          formik.setFieldValue("name", data.name);
          formik.setFieldValue("is_active", data.is_active);
        }
      });
    }

    return () => {};
  }, [selectedItemId]);

  return (
    <Modal
      onClose={() => {
        handleCloseModal();
      }}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      size="tiny"
      closeIcon
      onUnmount={() => {
        formik.resetForm();
      }}
    >
      <Modal.Header>
        {selectedItemId ? "Modifier" : "Ajouter"} une garantie
      </Modal.Header>
      <Modal.Content>
        <Modal.Description className="ui form">
          <div className="mt-4">
            <Form.Field required error={"name" in errors}>
              <label>Nom de la garantie</label>
              <Input
                type="text"
                name="name"
                value={values.name}
                onChange={handleChange("name")}
                onBlur={handleBlur("name")}
              />
              <small className="field-error">{errors.name}</small>
            </Form.Field>
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          onClick={() => {
            handleCloseModal();
          }}
        >
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          type="submit"
          loading={isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default GarantiesModalForm;
