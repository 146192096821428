import { api } from "../../axios/api";

export const getAll = async (uuid) => {
  return api.get(`/gestion-production/risques-des-garanties/${uuid}/garanties`);
};

export const create = async (data) => {
  return api.post("/gestion-production/risques-des-garanties", data);
};

export const update = async (uuid, data) => {
  return api.put("/gestion-production/risques-des-garanties/" + uuid, data);
};

export const deleteOne = async (uuid) => {
  return api.delete("/gestion-production/risques-des-garanties/" + uuid);
};

export const fetchOne = async (uuid) => {
  return api.get("/gestion-production/risques-des-garanties/" + uuid);
};
