import { api } from "../../../axios/api";

export const fetchAll = async (params = {}) => {
  return api.get(`gestion-attestations/pool/stocks`, {
    params: {
      ...params,
    },
  });
};

export const fetchOne = async (id) => {
  return api.get(`gestion-attestations/pool/stocks/${id}`);
};
export const fetchOnebyOrder = async (id) => {
  return api.get(`gestion-attestations/pool/stocks/by-order/${id}`);
};
export const create = async (data) => {
  return api.post(`gestion-attestations/pool/stocks`, data);
};

export const update = async (id, data) => {
  return api.put(`gestion-attestations/pool/stocks/${id}`, data);
};

export const remove = async (id) => {
  return api.delete(`gestion-attestations/pool/stocks/${id}`);
};
