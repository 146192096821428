import React from "react";
import {
  Button,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Segment,
  Table,
} from "semantic-ui-react";
import Page from "../../components/Page";
import { useNavigate } from "react-router-dom";
import * as avenantsService from "../../services/avenantsService";
import { generateAvenantserieNumber } from "../../utils/commons";
import dayjs from "dayjs";
import SearchPoliceModal from "../../components/avenants/SearchPoliceModal";
import SuspensionModal from "../../components/avenants/operations/SuspensionModal";
import ChangementImmatriculationModal from "../../components/modalForms/ActesDeGestion.js/ChangementImmatriculationModal";
import ChangementDeNomModal from "../../components/modalForms/ActesDeGestion.js/ChangementDeNomModal";
import EncaissementModal from "../../components/detailsPolice/EncaissementModal";

function AvenantsList() {
  const [avenants, setAvenants] = React.useState([]);
  const [openSearchPoliceModal, setOpenSearchPoliceModal] =
    React.useState(false);
  const [selectedAvenantId, setSelectedAvenantId] = React.useState();
  const [selectedPoliceId, setSelectedPoliceId] = React.useState();
  const [openEncaissementModal, setOpenEncaissementModal] =
    React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [operationType, setOperationType] = React.useState();
  const navigate = useNavigate();

  const fetchAvenants = async () => {
    try {
      setLoading(true);
      const response = await avenantsService.getAvenants();
      if (response.status === 200 && Array.isArray(response.data)) {
        setAvenants(response.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onSuccessfulOperation = () => {
    fetchAvenants();
    setOperationType(null);
  };

  React.useEffect(() => {
    fetchAvenants();
    return () => {};
  }, []);

  return (
    <Page title="Avenants">
      <GridRow className="Avenants">
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Header>Liste des des avenants</Header>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input placeholder="Rechercher..." className="search">
              <input name="search" />
            </Input>
            <Button
              style={{ marginLeft: "10px" }}
              circular
              icon={<Icon name="add" />}
              onClick={() => setOpenSearchPoliceModal(true)}
            />
          </Grid.Column>
        </Grid>
        <Segment>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Numéro</Table.HeaderCell>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>Création</Table.HeaderCell>
                <Table.HeaderCell>Police</Table.HeaderCell>
                <Table.HeaderCell>Souscripteur</Table.HeaderCell>
                <Table.HeaderCell>Paiement</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {avenants?.map((avenant, index) => {
                return (
                  <Table.Row key={`${avenant.idavenants}`}>
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>
                      {generateAvenantserieNumber(avenant.numero_avenant)}
                    </Table.Cell>
                    <Table.Cell>{avenant.types_avenants?.libelle}</Table.Cell>
                    <Table.Cell>
                      {dayjs(avenant.created_at).format("DD/MM/YYYY HH:mm")}
                    </Table.Cell>
                    <Table.Cell>
                      {avenant.polices?.num_police || "Aucune"}
                    </Table.Cell>
                    <Table.Cell>{`${avenant.polices?.souscriptions?.souscripteurs_souscriptions_idsouscripteursTosouscripteurs?.first_name}
                    ${avenant.polices?.souscriptions?.souscripteurs_souscriptions_idsouscripteursTosouscripteurs?.last_name}
                    `}</Table.Cell>
                    <Table.Cell>
                      {avenant?.facturation?.payments?.idpayments ? (
                        <>
                          <Icon name="check" color="green" />
                          <span style={{ marginLeft: "5px" }}>
                            {dayjs(
                              avenant?.facturation?.payments?.date_paiement
                            ).format("DD-MM-YYYY HH:mm")}
                          </span>
                        </>
                      ) : (
                        <Icon name="close" color="red" />
                      )}
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <Dropdown
                        pointing="right"
                        icon={<Icon name="ellipsis vertical" />}
                      >
                        <Dropdown.Menu>
                          <Dropdown.Item
                            icon={<Icon name="eye" />}
                            content="Police"
                            onClick={() =>
                              navigate(
                                `/company/polices/details/${avenant.idpolices}`
                              )
                            }
                          />
                          <Dropdown.Item
                            icon={<Icon name="payment" />}
                            content="Encaissement"
                            onClick={() => {
                              setSelectedAvenantId(avenant.idavenants);
                              setOpenEncaissementModal(true);
                            }}
                          />
                          <Dropdown.Item
                            icon={<Icon name="eye" />}
                            content="Supprimer"
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Segment>
      </GridRow>
      {/* <avenantsFormModal
        callback={fetchavenants}
        open={open}
        setOpen={setOpen}
      /> */}

      <SearchPoliceModal
        open={openSearchPoliceModal}
        setOpen={setOpenSearchPoliceModal}
        setSelectedPoliceId={setSelectedPoliceId}
        setOperationType={setOperationType}
        onCloseCallBack={() => {
        }}
      />

      <SuspensionModal
        setOpen={setOperationType}
        idpolices={selectedPoliceId}
        selectedOperation={operationType}
        setSelectedOperation={setOperationType}
        onSuccessCallBack={onSuccessfulOperation}
      />
      <ChangementImmatriculationModal
        open={operationType === "changement-immatriculation"}
        setOpen={setOperationType}
        idpolices={selectedPoliceId}
        selectedOperation={operationType}
        setSelectedOperation={setOperationType}
        onSuccessCallBack={onSuccessfulOperation}
      />
      <ChangementDeNomModal
        open={operationType === "changement-de-nom"}
        setOpen={setOperationType}
        idpolices={selectedPoliceId}
        selectedOperation={operationType}
        setSelectedOperation={setOperationType}
        onSuccessCallBack={onSuccessfulOperation}
      />

      <EncaissementModal
        open={openEncaissementModal}
        setOpen={setOpenEncaissementModal}
        avenant={avenants.find(
          (avenant) => avenant?.idavenants === selectedAvenantId
        )}
        onSuccessPaymentCallBack={onSuccessfulOperation}
      />
    </Page>
  );
}

export default AvenantsList;
