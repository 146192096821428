import React, { useEffect, useState } from "react";
import Page from "../../../../components/Page";
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Menu,
  Segment,
  Table,
} from "semantic-ui-react";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import * as distributionRequestService from "../../../../services/gestion_attestations/company/distributionsRequestsService";
import * as distributionsService from "../../../../services/gestion_attestations/company/distributionsService";
function DistributionsRequestsList() {
  const [requests, setRequests] = useState([]);
  const [pagination, setPagination] = useState({});

  const [selectedPage, setSelectedPage] = useState(1);
  const [search, setSearch] = useState("");

  const fetchRequests = async () => {
    try {
      const { data } = await distributionRequestService.fetchAll({
        page: selectedPage,
        search,
      });
      setRequests(data.requests || []);
      setPagination({
        totalPages: data.totalPages,
        nextPage: data.nextPage,
        prevPage: data.prevPage,
        currentPage: data.currentPage,
        total: data.total,
      });
    } catch (error) {
      toast.error(
        "Une erreur est survenue lors de la recuperation des requests"
      );
    }
  };

  const handleDeleteClick = (request) => {
    Swal.fire({
      title: "Etes-vous sur de vouloir supprimer cette Distribution ?",
      text: "Vous ne pourrez pas reprendre cette action !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        distributionsService
          .deleteDistribution(request.distribution_uuid)
          .then(() => {
            toast.success("Distribution supprimée avec succes");
            fetchRequests();
          })
          .catch((error) => {
            toast.error(
              "Une erreur est survenue lors de la suppression de la distribution d'attestation"
            );
          });
      }
    });
  };

  useEffect(() => {
    fetchRequests();
  }, [selectedPage, search]);

  return (
    <Page title="Liste des requests d'attestation">
      <GridRow className="Souscriptions">
        <Grid.Row className="!mb-5">
          <Grid.Column width={16}>
            <Breadcrumb>
              <BreadcrumbSection link>Compagnie</BreadcrumbSection>
              <BreadcrumbDivider icon="right chevron" />
              <BreadcrumbSection link>
                Gestion des attestations
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right chevron" />
              <BreadcrumbSection link>Stocks réseaux</BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection
                link
                as={Link}
                to="/company/stocks/distributions-requests"
              >
                Demandes d'attestions
              </BreadcrumbSection>
            </Breadcrumb>
            <Divider />
          </Grid.Column>
        </Grid.Row>
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid>
              <Grid.Column>
                <Header>
                  Liste des demandes d'attestation [{pagination.total || 0}]
                </Header>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input
              placeholder="Rechercher..."
              className="search"
              onChange={(e) => setSearch(e.target.value)}
            >
              <input name="search" />
            </Input>
          </Grid.Column>
        </Grid>
        <>
          <Segment className="!min-h-auto">
            <Table striped celled sortable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell>Entité</Table.HeaderCell>
                  <Table.HeaderCell>Type d'entité</Table.HeaderCell>
                  <Table.HeaderCell>Quantité demandée</Table.HeaderCell>
                  <Table.HeaderCell>Date de commande</Table.HeaderCell>
                  <Table.HeaderCell>Plage attribuée</Table.HeaderCell>
                  <Table.HeaderCell>Etat</Table.HeaderCell>
                  <Table.HeaderCell>Qté livrée</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {requests?.length > 0 ? (
                  requests.map((request, index) => (
                    <Table.Row
                      key={index}
                      className={`${
                        request?.distribution_uuid ? "" : "warning"
                      }`}
                    >
                      <Table.Cell>{index + 1}</Table.Cell>
                      <Table.Cell>{request?.denomination}</Table.Cell>
                      <Table.Cell>
                        {request?.creative_entity?.toUpperCase()}
                      </Table.Cell>
                      <Table.Cell>
                        {request?.quantity &&
                          new Intl.NumberFormat("fr-FR").format(
                            request.quantity
                          )}
                      </Table.Cell>

                      <Table.Cell>
                        {dayjs(request.created_at).format("DD/MM/YYYY HH:mm")}
                      </Table.Cell>
                      <Table.Cell>
                        {request.start_serial
                          ? `${request.start_serial} - ${request.end_serial}`
                          : "-- --"}
                      </Table.Cell>
                      <Table.Cell>
                        {request?.distribution_uuid ? "Livrée" : "En attente"}
                      </Table.Cell>
                      <Table.Cell>
                        {request?.distribution_uuid
                          ? `${new Intl.NumberFormat("fr-FR").format(
                              parseInt(request.end_serial) -
                                parseInt(request.start_serial) +
                                1
                            )}`
                          : "-- --"}
                      </Table.Cell>

                      <Table.Cell width={1} textAlign="right">
                        <Dropdown
                          pointing="right"
                          icon={<Icon name="ellipsis vertical" />}
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item
                              as={Link}
                              icon={<Icon name="eye" />}
                              content="Détails"
                              to={`/company/stocks/distributions-requests/details/${request.uuid}`}
                            />

                            {request?.distribution_uuid && (
                              <Dropdown.Item
                                icon={<Icon name="trash" />}
                                content="Rétirer la distribution"
                                onClick={() => handleDeleteClick(request)}
                              />
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Table.Cell>
                    </Table.Row>
                  ))
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan="8" textAlign="center">
                      <Header as="h4">Aucune donnée</Header>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
              {requests.length > 0 && (
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell colSpan="9">
                      <Menu floated="right" pagination>
                        <Menu.Item
                          as="a"
                          icon
                          onClick={() => setSelectedPage(pagination?.prevPage)}
                        >
                          <Icon name="chevron left" />
                        </Menu.Item>
                        <Menu.Item as="a">
                          {pagination?.currentPage} sur {pagination?.totalPages}
                        </Menu.Item>
                        <Menu.Item
                          as="a"
                          icon
                          onClick={() => setSelectedPage(pagination?.nextPage)}
                        >
                          <Icon name="chevron right" />
                        </Menu.Item>
                      </Menu>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              )}
            </Table>
          </Segment>
        </>
      </GridRow>
    </Page>
  );
}

export default DistributionsRequestsList;
