import React, { useEffect, useRef, useState } from "react";
import Page from "../../../../components/Page";
import {
  Button,
  Divider,
  FormGroup,
  Grid,
  Form,
  GridRow,
  Label,
  List,
  Message,
  Segment,
  Breadcrumb,
  BreadcrumbSection,
  BreadcrumbDivider,
  Header,
  Table,
  Icon,
} from "semantic-ui-react";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { Formik } from "formik";
import { Link, useParams } from "react-router-dom";
import * as deliveriesService from "../../../../services/gestion_attestations/pool/deliveriesService";
import * as Yup from "yup";
import * as requestsService from "../../../../services/gestion_attestations/pool/requestsService";
import StockDoughnutChart from "../../../../components/charts/StockDoughnutChart";

function RequestDetails() {
  const [request, setRequest] = useState(null);
  const [loading, setLoading] = useState(true);
  const { uuid } = useParams();
  const inputQtyRef = useRef(null);

  const fetchRequest = async () => {
    try {
      setLoading(true);
      const { data } = await requestsService.fetchOne(uuid);

      setRequest(data);
    } catch (error) {
      toast.error(
        error?.response?.data?.error ||
          "Une erreur est survenue lors de la recuperation de la demande"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleCreateDelivery = async (values) => {
    try {
      const { data } = await deliveriesService.createRequestDelivery(values);
      toast.success("La livraison a bien été crée pour la request");
      fetchRequest();
    } catch (error) {
      toast.error(
        error?.response?.data?.error ||
          "Une erreur est survenue lors de la creation de la livraison"
      );
    }
  };

  const handleUpdateDelivery = async (uuid, values) => {
    try {
      const { data } = await deliveriesService.updateRequestDelivery(
        uuid,
        values
      );
      toast.success("La livraison a bien été mis a jour");
      fetchRequest();
    } catch (error) {
      toast.error(
        error?.response?.data?.error ||
          "Une erreur est survenue lors de la mise a jour de la livraison"
      );
    }
  };

  useEffect(() => {
    fetchRequest();
  }, []);

  return (
    <Page title="Détails de la demande">
      <GridRow className="Souscriptions">
        <Grid.Row className="!mb-5">
          <Grid.Column width={16}>
            <Breadcrumb>
              <BreadcrumbSection link>Pool</BreadcrumbSection>
              <BreadcrumbDivider icon="right chevron" />
              <BreadcrumbSection link>
                Gestion des attestations
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection link as={Link} to={`/pool/stocks/demandes`}>
                Demandes
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right chevron" />
              <BreadcrumbSection active>détail de la demande</BreadcrumbSection>
            </Breadcrumb>
            <Divider />
          </Grid.Column>
        </Grid.Row>
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          ></Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          ></Grid.Column>
        </Grid>
        <>
          <GridRow as={Segment}>
            <Label ribbon>
              Détails
              {request?.attestations_stocks ? (
                <Label.Detail>
                  {request?.attestations_stocks?.start_serial} -{" "}
                  {request?.attestations_stocks?.end_serial}
                </Label.Detail>
              ) : null}
            </Label>
            <Grid stackable columns={3} style={{ marginTop: "5px" }}>
              <Grid.Column>
                <List>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Numéro de demande</List.Header>
                      <List.Description>
                        {request?.idattestations_requests
                          ?.toString()
                          .padStart(6, "0")}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Quantité demandée</List.Header>
                      <List.Description>
                        {new Intl.NumberFormat().format(request?.quantity)}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column>
                <List>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Compagnie</List.Header>
                      <List.Description>
                        {
                          request?.attestations_stocks?.attestations_orders
                            ?.compagnies?.denomination
                        }
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Statut</List.Header>
                      <List.Description>
                        {request?.attestations_deliveries
                          ? "Livrée"
                          : "En cours"}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column>
                <List>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Date de demande</List.Header>
                      <List.Description>
                        {dayjs(request?.created_at).format("DD/MM/YYYY HH:mm")}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Numéro de livraison</List.Header>
                      <List.Description>
                        {request?.attestations_deliveries
                          ? request?.attestations_deliveries?.idattestations_deliveries
                              ?.toString()
                              .padStart(6, "0")
                          : "----"}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid>
          </GridRow>
          {request?.attestations_stocks === null ? (
            <Message
              warning
              header="Aucun Stock Associé a cette request"
              content="Vous devez d'abord ajouter un stock pour pouvoir ajouter une livraison."
            />
          ) : (
            <>
              <StockDoughnutChart
                total={
                  request?.attestations_stocks?.usage?.remainingQty +
                  request?.attestations_stocks?.usage?.consumedQty
                }
                consumed={request?.attestations_stocks?.usage?.consumedQty}
                remaining={request?.attestations_stocks?.usage?.remainingQty}
              />
              <Grid>
                <Grid.Column width={16}>
                  <Segment>
                    <Header as={"h6"} dividing>
                      Dernière livraison
                    </Header>
                    <Table basic="very" celled size="small">
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Quantité</Table.HeaderCell>
                          <Table.HeaderCell>Plage</Table.HeaderCell>
                          <Table.HeaderCell>Date</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {request?.attestations_stocks?.last_delivery ? (
                          <Table.Row>
                            <Table.Cell>
                              {new Intl.NumberFormat().format(
                                Number(
                                  request?.attestations_stocks?.last_delivery
                                    ?.end_serial
                                ) -
                                  Number(
                                    request?.attestations_stocks?.last_delivery
                                      ?.start_serial
                                  ) +
                                  1
                              )}
                            </Table.Cell>
                            <Table.Cell>
                              {
                                request?.attestations_stocks?.last_delivery
                                  ?.start_serial
                              }{" "}
                              -{" "}
                              {
                                request?.attestations_stocks?.last_delivery
                                  ?.end_serial
                              }
                            </Table.Cell>
                            <Table.Cell>
                              {dayjs(
                                request?.attestations_stocks?.last_delivery
                                  ?.created_at
                              ).format("DD/MM/YYYY HH:mm")}
                            </Table.Cell>
                          </Table.Row>
                        ) : (
                          <Table.Row>
                            <Table.Cell colSpan="3">
                              <div className="text-center">
                                <Icon name="warning sign" />
                                Aucune livraison trouvée
                              </div>
                            </Table.Cell>
                          </Table.Row>
                        )}
                      </Table.Body>
                    </Table>
                  </Segment>
                </Grid.Column>
              </Grid>
            </>
          )}

          <Grid as={Segment} loading={loading}>
            <Grid.Column width={10}>
              <Message
                header="Note"
                content={`
                  ${
                    request?.attestations_deliveries
                      ? "La livraison pour cette demande a été enregistrée le " +
                        dayjs(
                          request?.attestations_deliveries?.created_at
                        ).format("DD/MM/YYYY HH:mm") +
                        ". La plage de la livraison est " +
                        request?.attestations_deliveries?.start_serial +
                        " - " +
                        request?.attestations_deliveries?.end_serial
                      : "Vous pouvez ajouter une livraison de en réponse de cette demande."
                  }
                  `}
              />
              <Message
                header="Avertissement"
                warning
                content="Vous ne pouvez pas modifier une livraison s'il a été envoyé à la compagnie et distribuée."
              />
            </Grid.Column>
            <Grid.Column width={6}>
              {!loading && (
                <Formik
                  initialValues={{
                    start_serial:
                      request?.attestations_deliveries?.start_serial || "",
                    end_serial:
                      request?.attestations_deliveries?.end_serial || "",
                  }}
                  validationSchema={Yup.object().shape({
                    start_serial: Yup.number(
                      "Veuillez renseigner un numéro de série de début valide"
                    ).required(
                      "Veuillez renseigner le numéro de série de début"
                    ),
                    end_serial: Yup.number(
                      "Veuillez renseigner un numéro de série de fin valide"
                    )
                      .min(
                        Yup.ref("start_serial"),
                        "Le numéro de série de fin doit être supérieur au numéro de série de début"
                      )
                      .required(
                        "Veuillez renseigner le numéro de série de fin"
                      ),
                  })}
                  onSubmit={(values) => {
                    values.attestations_requests_uuid = request?.uuid;

                    if (request?.attestations_deliveries) {
                      delete values.attestations_requests_uuid;
                      handleUpdateDelivery(
                        request?.attestations_deliveries?.uuid,
                        values
                      );
                    } else {
                      handleCreateDelivery(values);
                    }
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <div>
                      <Form className="attached fluid segment">
                        <Label className="top attached">
                          Plage des attestations
                        </Label>
                        <Form.Field>
                          <label>Quantité</label>
                          <input
                            type="number"
                            ref={inputQtyRef}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (!isNaN(value)) {
                                setFieldValue(
                                  "end_serial",
                                  (
                                    Number(value) +
                                    Number(values.start_serial) -
                                    1
                                  )
                                    .toString()
                                    .padStart(values.start_serial.length, "0")
                                );
                              }
                            }}
                          />
                        </Form.Field>
                        <FormGroup widths="equal">
                          <Form.Field required>
                            <label>Numéro de série de début</label>
                            <Form.Input
                              name="start_serial"
                              value={values.start_serial}
                              onChange={(e, { value }) => {
                                setFieldValue("start_serial", value);

                                inputQtyRef.current.value = "";
                              }}
                              error={errors.start_serial}
                              min={
                                request?.attestations_stocks
                                  ?.attestations_orders?.start_serial || 0
                              }
                              max={
                                request?.attestations_stocks
                                  ?.attestations_orders?.end_serial || 0
                              }
                            />
                          </Form.Field>
                          <Form.Field required>
                            <label>Numéro de série de fin</label>
                            <Form.Input
                              name="end_serial"
                              value={values.end_serial}
                              onChange={(e, { value }) => {
                                setFieldValue("end_serial", value);
                                inputQtyRef.current.value = "";
                              }}
                              error={errors.end_serial}
                              min={values.start_serial}
                              max={
                                request?.attestations_stocks
                                  ?.attestations_orders?.end_serial || 0
                              }
                            />
                          </Form.Field>
                        </FormGroup>
                        {!isNaN(Number(values.start_serial)) &&
                        !isNaN(Number(values.end_serial)) &&
                        Number(values.start_serial) <=
                          Number(values.end_serial) ? (
                          <div className="text-right text-gray-500 font-bold">
                            {`Quantité d'attestations: ${
                              Number(values.end_serial) -
                              Number(values.start_serial) +
                              1
                            }`}
                          </div>
                        ) : null}

                        <div>
                          <Button
                            type="submit"
                            color="blue"
                            onClick={handleSubmit}
                          >
                            {request?.attestations_deliveries
                              ? "Modifier"
                              : "Enregistrer"}
                          </Button>
                        </div>
                      </Form>
                    </div>
                  )}
                </Formik>
              )}
            </Grid.Column>
          </Grid>
        </>
      </GridRow>
    </Page>
  );
}

export default RequestDetails;
