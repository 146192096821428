import React from "react";
import { Button, Divider, Form, Input } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as souscripteursService from "../../services/souscripteursService";

const validationSchema = yup.object().shape({
  denomination: yup
    .string()
    .max(255)
    .required("La dénomination est obligatoire"),
  carte_contribuable: yup.string().max(50),
  registre_commerce: yup.string().max(50),
  nom_dirigeant: yup.string().max(255),
  address: yup.string().max(255).required("L'adresse est obligatoire"),
  phone: yup
    .string()
    .min(8)
    .max(16)
    .required("Le numéro de téléphone est obligatoire"),
  email: yup.string().email("L'email est invalide"),
  groupe_souscripteur: yup
    .string()
    .oneOf(["ENTREPRISE"])
    .required("Le groupe du souscripteur est obligatoire"),
});
function EntrepriseForm({
  callback = () => {},
  open = false,
  setOpen,
  countries,
  souscripteur = null,
  hideModalCallback = () => {},
}) {
  const formik = useFormik({
    initialValues: {
      denomination: "",
      carte_contribuable: "",
      registre_commerce: "",
      nom_dirigeant: "",
      email: "",
      address: "",
      phone: "",
      groupe_souscripteur: "ENTREPRISE",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const operation = souscripteur
        ? souscripteursService.update(souscripteur.uuid, values)
        : souscripteursService.create(values);

      operation
        .then(
          (response) => {
            toast(
              `Souscripteur ${
                souscripteur ? "modifié" : "ajouté"
              } avec succès.`,
              {
                type: "success",
                theme: "colored",
              }
            );
            handleReset();
            callback();
            setOpen(false);
            hideModalCallback();
          },
          (reason) => {
            toast(`${reason?.response?.data?.message || reason?.message}`, {
              type: "error",
              theme: "colored",
            });
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const {
    values,
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    handleBlur,
    handleChange,
    handleReset,
    setFieldValue,
  } = formik;

  React.useEffect(() => {
    formik.resetForm();
    if (souscripteur) {
      setFieldValue("denomination", souscripteur.denomination || "");
      setFieldValue(
        "carte_contribuable",
        souscripteur.carte_contribuable || ""
      );
      setFieldValue("registre_commerce", souscripteur.registre_commerce || "");
      setFieldValue("nom_dirigeant", souscripteur.nom_dirigeant || "");
      setFieldValue("email", souscripteur.email || "");
      setFieldValue("address", souscripteur.address || "");
      setFieldValue("phone", souscripteur.phone || "");
      setFieldValue("idcountries", souscripteur.idcountries || "");
      setFieldValue(
        "groupe_souscripteur",
        souscripteur.groupe_souscripteur || "ENTREPRISE"
      );
    }
  }, [souscripteur]);
  return (
    <Form size="tiny">
      <Form.Field required error={"denomination" in errors}>
        <label>Dénomination</label>
        <Input
          value={values.denomination}
          onChange={handleChange("denomination")}
          onBlur={handleBlur("denomination")}
        />
        <small className="field-error">{errors.denomination}</small>
      </Form.Field>
      <Form.Group widths={"equal"}>
        <Form.Field error={"carte_contribuable" in errors}>
          <label>Carte de contribuable</label>
          <Input
            value={values.carte_contribuable}
            onChange={handleChange("carte_contribuable")}
            onBlur={handleBlur("carte_contribuable")}
          />
          <small className="field-error">{errors.carte_contribuable}</small>
        </Form.Field>
        <Form.Field error={"registre_commerce" in errors}>
          <label>Registre de commerce</label>
          <Input
            value={values.registre_commerce}
            onChange={handleChange("registre_commerce")}
            onBlur={handleBlur("registre_commerce")}
          />
          <small className="field-error">{errors.registre_commerce}</small>
        </Form.Field>
        <Form.Field error={"nom_dirigeant" in errors}>
          <label>Nom du dirigeant</label>
          <Input
            value={values.nom_dirigeant}
            onChange={handleChange("nom_dirigeant")}
            onBlur={handleBlur("nom_dirigeant")}
          />
          <small className="field-error">{errors.nom_dirigeant}</small>
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Field error={"idcountries" in errors} width={6}>
          <label>Pays</label>
          <Form.Select
            search
            fluid
            onChange={(e, data) => setFieldValue("idcountries", data.value)}
            placeholder="Choisr..."
            options={[
              {
                key: "",
                value: "",
                text: "Choisir...",
              },
              ...countries.map((country) => ({
                key: country.idcountries,
                value: country.idcountries,
                text: country.libelle,
              })),
            ]}
            value={values.idcountries}
          />
          <small className="field-error">{errors.idcountries}</small>
        </Form.Field>
        <Form.Field error={"phone" in errors} required width={6}>
          <label>Téléphone</label>
          <Input
            value={values.phone}
            onChange={handleChange("phone")}
            onBlur={handleBlur("phone")}
          />
          <small className="field-error">{errors.phone}</small>
        </Form.Field>
        <Form.Field error={"email" in errors} width={6}>
          <label>Email</label>
          <Input
            value={values.email}
            onChange={handleChange("email")}
            onBlur={handleBlur("email")}
          />
          <small className="field-error">{errors.email}</small>
        </Form.Field>
      </Form.Group>
      <Form.Field error={"address" in errors}>
        <label>Adresse</label>
        <Form.Input
          value={values.address}
          onChange={handleChange("address")}
          onBlur={handleBlur("address")}
        />
        <small className="field-error">{errors.address}</small>
      </Form.Field>
      <Divider />
      <Form.Field style={{ textAlign: "right" }}>
        <Button
          color="black"
          onClick={() => {
            handleReset();
            setOpen(false);
            hideModalCallback();
          }}
        >
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          type="submit"
          loading={isSubmitting}
        />
      </Form.Field>
    </Form>
  );
}

export default EntrepriseForm;
