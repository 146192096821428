import React, { Fragment } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  LinearScale,
  CategoryScale,
  BarElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import dayjs from "dayjs";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.register(ArcElement, Tooltip, Legend);
function DaylyProductionChart({ height = 100 }) {
  const labels = [];
  const values = [];
  const daysInMonth = dayjs().daysInMonth();

  for (let day = 0; day < daysInMonth; day++) {
    labels.push(dayjs().startOf("month").add(day, "day").format("DD MMM"));
    if (dayjs().startOf("month").add(day, "day") < dayjs()) {
      values.push(Math.floor(Math.random() * 100) + 1);
    }
  }

  const data = {
    labels,
    datasets: [
      {
        label: "Production Journalière",
        data: values,
        backgroundColor: "#7cccba",
      },
    ],
  };
  return (
    <Fragment>
      <Bar data={data} height={height} />
    </Fragment>
  );
}

export default DaylyProductionChart;
