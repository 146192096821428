import { api } from "../../../axios/api";

export const fetchAll = async (filter) => {
  return api.get(`gestion-attestations/companies/deliveries`);
};

export const fetchDeliveriesByOrder = async (orderuuid) => {
  return api.get(
    `gestion-attestations/companies/deliveries/by-order/${orderuuid}`
  );
};

export const fetchOne = async (uuid) => {
  return api.get(`gestion-attestations/companies/deliveries/${uuid}`);
};


