import { api } from "../../axios/api";

export const getConversionOfFiscaleForces = async (directive_uuid) => {
  return api.get(
    `/gestion-production/conversion-of-fiscale-forces/${directive_uuid}/directives-tarifaires`
  );
};

export const createConversionOfFiscaleForces = async (data) => {
  return api.post("/gestion-production/conversion-of-fiscale-forces", data);
};

export const getConversionOfFiscaleForcesByActiveDitectiveTarifaire = async (
  filter
) => {
  return api.get(
    `/gestion-production/conversion-of-fiscale-forces/active/directives-tarifaires`,
    {
      params: {
        ...filter,
      },
    }
  );
};
