import React from "react";
import { Helmet } from "react-helmet";

function Page(props) {
  return (
    <>
      <Helmet>
        <title>{props.title || "Go-Tour"}</title>
      </Helmet>
      {props.children}
    </>
  );
}

export default Page;
