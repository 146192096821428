import { api } from "../../../axios/api";

export const fetchAll = async (filter) => {
  return api.get(`gestion-attestations/pool/requests`, {
    params: {
      page: filter?.page || 1,
      ...filter,
    },
  });
};

export const fetchOne = async (uuid) => {
  return api.get(`gestion-attestations/pool/requests/${uuid}`);
};
