import React from "react";
import { Button, Form, Input, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as civilitiesService from "../../services/civilitiesService";

const validationSchema = yup.object().shape({
  libelle: yup.string().required(),
});
function CivilityFormModal({ callback = () => {}, open = false, setOpen }) {
  const formik = useFormik({
    initialValues: {
      libelle: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      civilitiesService
        .create(values)
        .then(
          (response) => {
            toast(`Civilité ajoutée avec succès.`, {
              type: "success",
              theme: "colored",
            });
            handleReset();
            callback();
            setOpen(false);
          },
          (reason) => {
            toast(`${reason?.response?.data || reason?.message}`, {
              type: "error",
              theme: "colored",
            });
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const {
    values,
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    handleBlur,
    handleChange,
    handleReset,
  } = formik;
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      as={Form}
      size="tiny"
    >
      <Modal.Header>Ajouter une civilité</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form.Field required error={"libelle" in errors}>
            <label>libelle</label>
            <Input
              value={values.libelle}
              onChange={handleChange("libelle")}
              onBlur={handleBlur("libelle")}
            />
            <small className="field-error">{errors.libelle}</small>
          </Form.Field>         
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          type="submit"
          loading={isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default CivilityFormModal;
