import React from "react";
import {
  Button,
  Form,
  Input,
  Modal,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import * as policesService from "../../../services/gestion_production/policesService";
import { LIENS_SOUSCRIPEUR_ASSURE } from "../../../constants";

const validationSchema = yup.object().shape({
  denomination: yup.string().min(4).required(),
  lien_assure: yup.string().min(4).required(),
});
function ChangementDeNomModal({
  callback = () => {},
  open = false,
  setOpen,
  avenants,
  idpolices,
  onSuccessPaymentCallBack = () => {},
  onSuccessCallBack = () => {},
}) {
  const formik = useFormik({
    initialValues: {
      denomination: "",
      lien_assure: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const data = {
        denomination: values.denomination,
        idpolices: idpolices,
        lien_assure: values.lien_assure,
      };
      policesService
        .changementDeNom(data)
        .then((response) => {
          setSubmitting(false);
          onSuccessCallBack();
          toast("Nom modifié avec succès!", {
            type: "success",
            title: "Succès",
          });
          setOpen(false);
        })
        .catch((error) => {
          toast(
            `${
              error.message || "Une erreur s'est produite pendant l'opération"
            }`,
            {
              type: "error",
              title: "Erreur",
            }
          );
          setSubmitting(false);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const {
    values,
    setFieldValue,
    handleBlur,
    handleChange,
    handleReset,
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
  } = formik;

  return (
    <Modal
      onClose={() => {
        setOpen(false);
      }}
      onOpen={() => setOpen(true)}
      open={open}
      closeIcon
      onUnmount={() => {
        handleReset();
      }}
      size="tiny"
    >
      <FormikProvider value={formik}>
        <Modal.Header>Changement de nom</Modal.Header>
        <Modal.Content>
          <Modal.Description className="ui form">
            <Form.Field required error={"lien_assure" in errors}>
              <label>Lien avec l'assuré </label>
              <Form.Select
                readOnly
                value={values.lien_assure}
                onChange={(e, data) => {
                  setFieldValue("lien_assure", data.value, true);
                }}
                options={LIENS_SOUSCRIPEUR_ASSURE}
              />
              <small className="field-error">{errors.lien_assure}</small>
            </Form.Field>
            <Form.Field>
              <label>Nom de l'assuré/Dénomination</label>
              <Input
                value={values.denomination}
                onChange={handleChange("denomination")}
                onBlur={handleBlur("denomination")}
              />
              <small className="field-error">{errors.denomination}</small>
            </Form.Field>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setOpen(false)}>
            Fermer
          </Button>
          <Button
            content="Enregistrer"
            labelPosition="right"
            icon="save"
            onClick={handleSubmit}
            positive
            loading={isSubmitting}
            type="submit"
          />
        </Modal.Actions>
      </FormikProvider>
    </Modal>
  );
}

export default ChangementDeNomModal;
