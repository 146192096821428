import { api } from "../../../axios/api";

export const getGaranties = async (directive_uuid) => {
  return api.get(
    `/gestion-production/pool/insurances-durations/${directive_uuid}/directives-tarifaires`
  );
};

export const storeBatch = async (data) => {
  return api.post("/gestion-production/pool/insurances-durations", data);
};

export const removeItem = async (uuid) => {
  return api.delete(`/gestion-production/pool/insurances-durations/${uuid}`);
};

export const getByActiveDirectiveTarifaires = async () => {
  return api.get(
    `/gestion-production/pool/insurances-durations/active/directives-tarifaires`
  );
};
