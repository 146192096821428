import React from "react";
import { Button, Form, Input, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as intermediairesService from "../../services/intermediairesService";
import { TYPES_INTERMEDIAIRE } from "../../constants";

const validationSchema = yup.object().shape({
  denomination: yup.string().required(),
  siege_social: yup.string().required(),
  type_intermediaire: yup.string().required(),
});
function IntermediairesFormModal({
  callback = () => {},
  open = false,
  setOpen,
}) {
  const formik = useFormik({
    initialValues: {
      denomination: "",
      siege_social: "",
      type_intermediaire: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      intermediairesService
        .create(values)
        .then(
          (response) => {
            toast(`Intermédiare ajouté avec succès.`, {
              type: "success",
              theme: "colored",
            });
            handleReset();
            callback();
            setOpen(false);
          },
          (reason) => {
            toast(`${reason?.response?.data || reason?.message}`, {
              type: "error",
              theme: "colored",
            });
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const {
    values,
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    handleBlur,
    handleChange,
    handleReset,
    setFieldValue,
  } = formik;
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      as={Form}
      size="tiny"
    >
      <Modal.Header>Ajouter un intermédiaire</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form.Field required error={"denomination" in errors}>
            <label>Dénomination</label>
            <Input
              value={values.denomination}
              onChange={handleChange("denomination")}
              onBlur={handleBlur("denomination")}
            />
            <small className="field-error">{errors.denomination}</small>
          </Form.Field>
          <Form.Field error={"siege_social" in errors} required>
            <label>Siège social</label>
            <Input
              value={values.siege_social}
              onChange={handleChange("siege_social")}
              onBlur={handleBlur("siege_social")}
            />
            <small className="field-error">{errors.siege_social}</small>
          </Form.Field>
          <Form.Field error={"type_intermediaire" in errors} required>
            <label>Type d'intermédiaire</label>
            <Form.Select
              search
              value={values.type_intermediaire}
              options={[
                {
                  key: TYPES_INTERMEDIAIRE.AGENT_GENERAL,
                  text: "Agent Génénal",
                  value: TYPES_INTERMEDIAIRE.AGENT_GENERAL,
                },
                {
                  key: TYPES_INTERMEDIAIRE.COURTIER,
                  text: "Courtier",
                  value: TYPES_INTERMEDIAIRE.COURTIER,
                },
              ]}
              onChange={(e, data) =>
                setFieldValue("type_intermediaire", data.value)
              }
            />
            <small className="field-error">{errors.type_intermediaire}</small>
          </Form.Field>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          type="submit"
          loading={isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default IntermediairesFormModal;
