import { api } from "../../../axios/api";

export const fetchAll = async (params = {}) => {
  return api.get(`gestion-attestations/pool/deliveries`, {
    params: {
      ...params,
    },
  });
};

export const fetchDeliveriesByStock = async (stockuuid) => {
  return api.get(`gestion-attestations/pool/deliveries/by-stock/${stockuuid}`);
};

export const fetchOne = async (id) => {
  return api.get(`gestion-attestations/pool/deliveries/${id}`);
};
export const fetchOnebyOrder = async (id) => {
  return api.get(`gestion-attestations/pool/deliveries/by-order/${id}`);
};
export const create = async (data) => {
  return api.post(`gestion-attestations/pool/deliveries`, data);
};

export const update = async (id, data) => {
  return api.put(`gestion-attestations/pool/deliveries/${id}`, data);
};

export const deleteOne = async (id) => {
  return api.delete(`gestion-attestations/pool/deliveries/${id}`);
};

export const createRequestDelivery = async (data) => {
  return api.post(`gestion-attestations/pool/deliveries/request`, data);
};

export const updateRequestDelivery = async (uuid, data) => {
  return api.put(`gestion-attestations/pool/deliveries/request/${uuid}`, data);
};
