import React, { useEffect, useRef } from "react";
import {
  Button,
  Dropdown,
  Form,
  Input,
  Message,
  Modal,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as companyRequestsService from "../../../services/gestion_attestations/company/requestsService";
import * as companyStocksService from "../../../services/gestion_attestations/company/stockService";

const validationSchema = yup.object().shape({
  quantity: yup
    .number("Veuillez renseigner une quantité valide")
    .min(1, "Veuillez renseigner une quantité valide")
    .required("Veuillez renseigner la quantité"),
  attestations_stocks_uuid: yup
    .string()
    .uuid("Veuillez sélectionner un stock")
    .required("Veuillez sélectionner un stock"),
});

function CompanyRequestAttestationFormModal({
  callback = () => {},
  open = false,
  setOpen,
  selectedRequestId,
  handleCloseModal = () => {},
}) {
  const [attestationsStocks, setAttestationsStocks] = React.useState([]);
  const formik = useFormik({
    initialValues: {
      quantity: 0,
      attestations_stocks_uuid: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const request = selectedRequestId
        ? companyRequestsService.update(selectedRequestId, values)
        : companyRequestsService.create(values);
      request
        .then((response) => {
          toast.success(
            selectedRequestId
              ? "Demande d'attestation mise à jour"
              : "Demande d'attestation ajoutée"
          );
          setOpen(false);
          callback();
          formik.resetForm();
          handleCloseModal();
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.error ||
              "Une erreur est survenue lors de " +
                (selectedRequestId ? "la mise à jour" : "l'ajout") +
                " de la demande d'attestation"
          );
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
    validateOnBlur: false,
    validateOnChange: false,
  });

  const {
    values,
    errors,
    handleSubmit,
    isSubmitting,
    handleBlur,
    handleChange,
  } = formik;

  const fetchData = (selectedRequestId) => {
    companyRequestsService
      .fetchOne(selectedRequestId)
      .then((response) => {
        if (typeof response.data === "object") {
          const data = response.data;
          formik.setValues(
            {
              quantity: data.quantity,
              attestations_stocks_uuid: data.attestations_stocks?.uuid,
            },
            true
          );
        }
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.error ||
            "Une erreur est survenue lors de la récupération"
        );
      })
      .finally(() => {});
  };

  const fetchStocks = () => {
    companyStocksService
      .fetchAll()
      .then((response) => {
        if (Array.isArray(response.data)) {
          const data = response.data;
          setAttestationsStocks(data);
        }
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.error ||
            "Une erreur est survenue lors de la récupération"
        );
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (open) {
      fetchStocks();
    }

    return () => {};
  }, [open]);

  useEffect(() => {
    formik.resetForm();
    if (selectedRequestId !== null && open) {
      fetchData(selectedRequestId);
    }
  }, [selectedRequestId, open]);

  return (
    <Modal
      onClose={() => {
        handleCloseModal();
      }}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      size="tiny"
      closeIcon
    >
      <Modal.Header>
        {selectedRequestId
          ? "Mettre à jour la demande d'attestation"
          : "Ajouter une nouvelle demande d'attestation"}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description className="ui form">
          <Message>
            <Message.Header>Note</Message.Header>
            <Message.Content>
              Une demande d'attestation n'implique pas une production
              d'attestion.
            </Message.Content>
          </Message>
          <Form.Field required error={"attestations_stocks_uuid" in errors}>
            <label>Choisir un stock</label>
            <Dropdown
              fluid
              search
              selection
              placeholder="Selectionner un stock"
              name="attestations_stocks_uuid"
              options={[
                { key: "", value: "", text: "Choisir un stock" },
                ...attestationsStocks.map((stock) => ({
                  key: stock.uuid,
                  value: stock.uuid,
                  text: `
                    [${stock.start_serial} - ${stock.end_serial}]
                   ${new Intl.NumberFormat().format(stock.usage?.remainingQty)}
                    attestation(s) restantes]
                  
                  `,
                })),
              ]}
              onChange={(e, { value }) => {
                formik.setFieldValue("attestations_stocks_uuid", value);
              }}
              onBlur={handleBlur("attestations_stocks_uuid")}
              value={values.attestations_stocks_uuid}
            />
            <small className="field-error">
              {errors.attestations_stocks_uuid}
            </small>
          </Form.Field>

          <Form.Field required error={"quantity" in errors}>
            <label>Quantité d'attestation(s) à demander</label>
            <Input
              type="number"
              name="quantity"
              value={values.quantity}
              onChange={handleChange("quantity")}
              onBlur={handleBlur("quantity")}
            />
            <small className="field-error">{errors.quantity}</small>
          </Form.Field>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          onClick={() => {
            handleCloseModal();
          }}
        >
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          type="submit"
          loading={isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default CompanyRequestAttestationFormModal;
