import React, { useEffect, useRef, useState } from "react";
import Page from "../../../../components/Page";
import {
  Button,
  Divider,
  Dropdown,
  FormGroup,
  Grid,
  Form,
  GridRow,
  Header,
  Icon,
  Input,
  Label,
  List,
  Message,
  Segment,
  Table,
  FormField,
  Breadcrumb,
  BreadcrumbSection,
  BreadcrumbDivider,
} from "semantic-ui-react";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import * as stockService from "../../../../services/gestion_attestations/pool/stockService";
import { Formik } from "formik";
import * as Yup from "yup";
import DeliveryAttestationFormModal from "../../../../components/modalForms/gestion_attestations/DeliveryAttestationFormModal";
import * as deliveriesService from "../../../../services/gestion_attestations/pool/deliveriesService";
import { Link, useParams } from "react-router-dom";
import StockDoughnutChart from "../../../../components/charts/StockDoughnutChart";

function StockDetails() {
  const [stock, setStock] = useState(null);
  const [openDeliveryModal, setOpenDeliveryModal] = useState(false);
  const [deliveries, setDeliveries] = useState([]);
  const [selectedDeliveryUuid, setSelectedDeliveryUuid] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const inputQtyRef = useRef(null);

  const { uuid } = useParams();

  const fetchStock = async () => {
    setIsLoading(true);
    try {
      const { data } = await stockService.fetchOnebyOrder(uuid);
      setStock(data);
    } catch (error) {
      toast.error(
        error?.response?.data?.error ||
          "Une erreur est survenue lors de la recuperation du stock"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDeliveries = async () => {
    try {
      if (stock.attestations_stocks?.uuid) {
        const { data } = await deliveriesService.fetchDeliveriesByStock(
          stock.attestations_stocks?.uuid
        );
        setDeliveries(data);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.error ||
          "Une erreur est survenue lors de la recuperation des livraisons"
      );
    }
  };

  const handleCreateStock = async (values, setSubmitting) => {
    try {
      const { data } = await stockService.create(values);
      toast.success("Le stock a bien été crée pour la commande");
      fetchStock();
    } catch (error) {
      toast.error(
        error?.response?.data?.error ||
          "Une erreur est survenue lors de la creation du stock"
      );
    } finally {
      setSubmitting(false);
    }
  };

  const handleUpdateStock = async (
    idattestations_stocks,
    values,
    setSubmitting
  ) => {
    try {
      const { data } = await stockService.update(idattestations_stocks, values);
      toast.success("Le stock a bien été mis a jour");
      fetchStock();
    } catch (error) {
      toast.error(
        error?.response?.data?.error ||
          "Une erreur est survenue lors de la mise a jour du stock"
      );
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteDeliveryClick = (delivery) => {
    Swal.fire({
      title: "Etes-vous sur de vouloir supprimer cette livraison ?",
      text: "Vous ne pourrez pas reprendre cette action !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        deliveriesService
          .deleteOne(delivery.uuid)
          .then(() => {
            toast.success("Livraison supprimée avec succes");
            fetchDeliveries();
            fetchStock();
          })
          .catch((error) => {
            toast.error(
              error?.response?.data?.error ||
                "Une erreur est survenue lors de la suppression de la livraison"
            );
          });
      }
    });
  };

  const handleEditDeliveryClick = (delivery) => {
    setOpenDeliveryModal(true);
    setSelectedDeliveryUuid(delivery?.uuid);
  };

  useEffect(() => {
    fetchStock();
  }, []);

  useEffect(() => {
    if (stock) {
      fetchDeliveries();
    }
  }, [stock]);

  return (
    <Page title="Détails du stock">
      <GridRow className="Souscriptions">
        <Grid.Row className="!mb-5">
          <Grid.Column width={16}>
            <Breadcrumb>
              <BreadcrumbSection link>Pool</BreadcrumbSection>
              <BreadcrumbDivider icon="right chevron" />
              <BreadcrumbSection link>
                Gestion des attestations
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection link as={Link} to={`/pool/stocks`}>
                Stocks
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right chevron" />
              <BreadcrumbSection active>détail du stock</BreadcrumbSection>
            </Breadcrumb>
            <Divider />
          </Grid.Column>
        </Grid.Row>
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid>
              <Grid.Column>
                <Header>Détails du stock</Header>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input placeholder="Rechercher..." className="search !hidden">
              <input name="search" />
            </Input>
            <Button className="!ml-4 !hidden">
              <Icon name="add" /> Ajouter
            </Button>
          </Grid.Column>
        </Grid>
        <>
          <GridRow as={Segment}>
            <Label ribbon>Détails de la commande</Label>
            <Grid stackable columns={3} style={{ marginTop: "5px" }}>
              <Grid.Column>
                <List>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Numéro de commande</List.Header>
                      <List.Description>
                        {stock?.idattestations_orders
                          ?.toString()
                          .padStart(6, "0")}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Quantité commandée</List.Header>
                      <List.Description>
                        {new Intl.NumberFormat().format(stock?.quantity)}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column>
                <List>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Compagnie</List.Header>
                      <List.Description>
                        {stock?.compagnies?.denomination}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Statut</List.Header>
                      <List.Description>{stock?.status} </List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column>
                <List>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Date de commande</List.Header>
                      <List.Description>
                        {dayjs(stock?.created_at).format("DD/MM/YYYY HH:mm")}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Numéro de stocks</List.Header>
                      <List.Description>
                        {stock?.attestations_stocks
                          ? stock?.attestations_stocks?.idattestations_stocks
                              ?.toString()
                              .padStart(6, "0")
                          : ""}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid>
            {stock?.comment && (
              <Grid.Column>
                <div className="bg-gray-100 p-2 rounded-md mt-2">
                  {stock?.comment}
                </div>
              </Grid.Column>
            )}
          </GridRow>

          <Grid as={Segment} loading={isLoading}>
            <Grid.Column width={10}>
              <Message
                header="Note"
                content={`
                  ${
                    stock?.attestations_stocks
                      ? "Le stock pour cette commande a été enregistré le " +
                        dayjs(stock?.attestations_stocks?.created_at).format(
                          "DD/MM/YYYY HH:mm"
                        ) +
                        ". La série de la plage est " +
                        stock?.attestations_stocks?.start_serial +
                        " - " +
                        stock?.attestations_stocks?.end_serial
                      : "Vous pouvez ajouter le stock de en réponse de cette commande"
                  }
                  `}
              />
              {stock?.attestations_stocks ? (
                <Message
                  header="Avertissement"
                  warning
                  content="Vous ne pouvez pas modifier le stock s'il a été envoyé à la compagnie"
                />
              ) : (
                <></>
              )}
            </Grid.Column>
            <Grid.Column width={6}>
              {!isLoading && (
                <Formik
                  initialValues={{
                    end_serial: stock?.attestations_stocks?.end_serial || "",
                    start_serial:
                      stock?.attestations_stocks?.start_serial || "",
                  }}
                  validationSchema={Yup.object().shape({
                    end_serial: Yup.number("Ne doit être un entier").required(
                      "Ce champ est requis"
                    ),
                    start_serial: Yup.number("Ne doit être un entier")
                      .lessThan(
                        Yup.ref(
                          "end_serial",
                          "Le nombre de carnets doit être inferieur au nombre de carnets"
                        )
                      )
                      .required("Ce champ est requis"),
                  })}
                  onSubmit={(values, { setSubmitting }) => {
                    values.idattestations_orders = stock?.idattestations_orders;

                    if (stock?.attestations_stocks?.idattestations_stocks) {
                      delete values.idattestations_orders;
                      handleUpdateStock(
                        stock?.attestations_stocks?.idattestations_stocks,
                        values,
                        setSubmitting
                      );
                    } else {
                      handleCreateStock(values, setSubmitting);
                    }
                  }}
                  validateOnBlur={false}
                  validateOnChange={false}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                    setSubmitting,
                  }) => (
                    <div>
                      <Form className="attached fluid segment">
                        <Label className="top attached">Stocks</Label>
                        <Form.Field>
                          <label>Qté</label>
                          <input
                            type="number"
                            ref={inputQtyRef}
                            min={0}
                            defaultValue={0}
                            onChange={(e) => {
                              const value = parseInt(e.target.value);
                              if (
                                !isNaN(value) &&
                                !isNaN(Number(values.start_serial)) &&
                                Number(values.start_serial) > 0
                              ) {
                                setFieldValue(
                                  "end_serial",
                                  (
                                    Number(value) +
                                    Number(values.start_serial) -
                                    1
                                  )
                                    .toString()
                                    .padStart(values.start_serial.length, "0")
                                );
                              }
                            }}
                          />
                        </Form.Field>
                        <FormGroup>
                          <FormField
                            width={8}
                            error={touched.start_serial && errors.start_serial}
                            required={true}
                          >
                            <label>Début de série</label>
                            <input
                              placeholder="Début de série"
                              type="text"
                              min={0}
                              name="start_serial"
                              onChange={(e) => {
                                const value = parseInt(e.target.value);
                                setFieldValue("start_serial", e.target.value);
                                if (
                                  !isNaN(value) &&
                                  !isNaN(Number(inputQtyRef.current.value)) &&
                                  Number(inputQtyRef.current.value) > 0
                                ) {
                                  setFieldValue(
                                    "end_serial",
                                    (
                                      Number(value) +
                                      Number(inputQtyRef.current.value) -
                                      1
                                    )
                                      .toString()
                                      .padStart(e.target.value.length, "0")
                                  );
                                }
                              }}
                              onBlur={handleBlur}
                              value={values.start_serial}
                              defaultValue={
                                stock?.attestations_stocks?.start_serial
                              }
                            />
                            {touched.start_serial && errors.start_serial && (
                              <Label basic color="red" pointing>
                                {errors.start_serial}
                              </Label>
                            )}
                          </FormField>
                          <FormField
                            width={8}
                            error={touched.end_serial && errors.end_serial}
                          >
                            <label>Fin de série</label>
                            <input
                              placeholder="Fin de série"
                              required
                              type="text"
                              min={0}
                              name="end_serial"
                              onChange={(e) => {
                                setFieldValue("end_serial", e.target.value);
                                inputQtyRef.current.value = "";
                              }}
                              value={values.end_serial}
                              defaultValue={
                                stock?.attestations_stocks?.end_serial
                              }
                            />
                            {touched.end_serial && errors.end_serial && (
                              <Label basic color="red" pointing>
                                {errors.end_serial}
                              </Label>
                            )}
                          </FormField>
                        </FormGroup>
                        {parseInt(values.end_serial) -
                          parseInt(values.start_serial) >
                        0 ? (
                          <>
                            <div className="w-full mb-2">
                              <Label className="w-full">
                                Nombre d'attestations :{" "}
                                {new Intl.NumberFormat().format(
                                  parseInt(values.end_serial) -
                                    parseInt(values.start_serial) +
                                    1
                                )}
                              </Label>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        <div className="text-right">
                          <Button
                            type="submit"
                            color="blue"
                            onClick={handleSubmit}
                            size="mini"
                            disabled={isSubmitting}
                            loading={isSubmitting}
                          >
                            {stock?.attestations_stocks?.idattestations_stocks
                              ? "Modifier"
                              : "Enregistrer"}
                          </Button>
                        </div>
                      </Form>
                    </div>
                  )}
                </Formik>
              )}
            </Grid.Column>
          </Grid>
          {!stock?.attestations_stocks ? (
            <>
              <Message
                warning
                header="Aucun Stock Associé a cette commande"
                content="Vous devez d'abord ajouter un stock pour pouvoir ajouter une livraison."
              />
            </>
          ) : (
            <>
              <StockDoughnutChart
                total={stock?.usage?.totalQty}
                consumed={stock?.usage?.consumedQty}
                remaining={stock?.usage?.remainingQty}
              />
              <GridRow as={Segment}>
                <div className="ui label top attached !flex !justify-between">
                  <h3>Liste des livraisons</h3>
                  {stock?.usage?.remainingQty > 0 ? (
                    <div
                      className="basic"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOpenDeliveryModal(true);
                        setSelectedDeliveryUuid(null);
                      }}
                    >
                      <Icon name="plus" />
                      Ajouter une livraison
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <Table basic="very">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Numéro de livraison</Table.HeaderCell>
                      <Table.HeaderCell>Magasin</Table.HeaderCell>
                      <Table.HeaderCell>Quantité livrée</Table.HeaderCell>
                      <Table.HeaderCell>Plage</Table.HeaderCell>
                      <Table.HeaderCell>Date de livraison</Table.HeaderCell>
                      <Table.HeaderCell collapsing>Action</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {deliveries?.map((delivery) => (
                      <Table.Row>
                        <Table.Cell>
                          {delivery?.idattestations_deliveries
                            ?.toString()
                            .padStart(6, "0")}
                        </Table.Cell>
                        <Table.Cell>
                          {
                            delivery?.attestations_magasins_stocks[0]
                              ?.attestations_magasins?.name
                          }
                        </Table.Cell>
                        <Table.Cell>
                          {new Intl.NumberFormat().format(
                            parseInt(delivery?.end_serial) -
                              parseInt(delivery?.start_serial) +
                              1
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {`${delivery?.start_serial} - ${delivery?.end_serial}`}
                        </Table.Cell>
                        <Table.Cell>
                          {dayjs(delivery?.created_at).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                        </Table.Cell>
                        <Table.Cell className="text-right">
                          <Dropdown
                            pointing="right"
                            icon={<Icon name="ellipsis vertical" />}
                          >
                            <Dropdown.Menu>
                              <Dropdown.Item
                                icon={<Icon name="edit" />}
                                content="Editer"
                                onClick={() =>
                                  handleEditDeliveryClick(delivery)
                                }
                              />
                              <Dropdown.Item
                                icon={<Icon name="trash" />}
                                content="Supprimer"
                                onClick={() =>
                                  handleDeleteDeliveryClick(delivery)
                                }
                              />
                            </Dropdown.Menu>
                          </Dropdown>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </GridRow>
            </>
          )}
        </>
      </GridRow>

      <DeliveryAttestationFormModal
        open={openDeliveryModal}
        setOpen={setOpenDeliveryModal}
        orderId={stock?.idattestations_orders}
        handleCloseModal={() => {
          setSelectedDeliveryUuid(null);
          setOpenDeliveryModal(false);
        }}
        selectedItemId={selectedDeliveryUuid}
        callback={() => {
          setOpenDeliveryModal(false);
          setSelectedDeliveryUuid(null);
          fetchStock();
          fetchDeliveries();
        }}
      />
    </Page>
  );
}

export default StockDetails;
