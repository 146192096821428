import { api } from "../axios/api";

export const fetchAll = async () => {
  return api.get(`intermediaires-agences`);
};

export const fetchOne = async (id) => {
  return api.get(`intermediaires-agences/${id}`);
};
export const create = async (data) => {
  return api.post(`intermediaires-agences`, data);
};
export const addUser = async (data, agenceId) => {
  return api.post(
    `intermediaires-agences/user/add/${agenceId ? agenceId : ""}`,
    data
  );
};

export const fetchCompanies = async () => {
  return api.get(`intermediaires-agences/companies`);
};
