import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Grid,
  Header,
  Icon,
  Input,
  Modal,
  Table,
} from "semantic-ui-react";

import * as souscripteursService from "../../../../services/souscripteursService";

function SelectSouscriptorModal({
  callback = () => {},
  open = false,
  selectedSouscripteur = null,
  setSelectedSouscripteur,
  setOpen,
}) {
  const [souscripteurs, setSouscripteurs] = useState([]);

  const fetchSouscripteurs = async () => {
    try {
      const response = await souscripteursService.fetchAll();
      if (response.status === 200 && Array.isArray(response.data)) {
        setSouscripteurs(response.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    fetchSouscripteurs();
    return () => {};
  }, []);

  return (
    <Modal
      closeIcon
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <Header icon="check" content="Sélection du souscripteur" />
      <Modal.Content scrolling>
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          ></Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input placeholder="Rechercher..." className="search">
              <input name="search" />
            </Input>
            <Button
              style={{ marginLeft: "10px" }}
              circular
              icon={<Icon name="add" />}
              onClick={() => setOpen(true)}
            />
          </Grid.Column>
        </Grid>
        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>#</Table.HeaderCell>
              <Table.HeaderCell>Nom</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>email</Table.HeaderCell>
              <Table.HeaderCell>Téléphone</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {souscripteurs.map((souscripteur, index) => {
              return (
                <Table.Row
                  key={`${souscripteur.num}`}
                  className="cursor-pointer"
                >
                  <Table.Cell>
                    <Checkbox
                      label={`${souscripteur.num}`}
                      onChange={(e, data) => {
                        if (data.checked) {
                          setSelectedSouscripteur(souscripteur);
                        } else {
                          setSelectedSouscripteur(null);
                        }
                      }}
                      checked={
                        souscripteur.idsouscripteurs ===
                        selectedSouscripteur?.idsouscripteurs
                      }
                    />
                  </Table.Cell>
                  <Table.Cell>
                    {souscripteur.groupe_souscripteur === "ENTREPRISE"
                      ? souscripteur.denomination
                      : `${souscripteur.last_name} ${souscripteur.first_name}`}
                  </Table.Cell>
                  <Table.Cell>{souscripteur.groupe_souscripteur}</Table.Cell>
                  <Table.Cell>{souscripteur.email}</Table.Cell>
                  <Table.Cell>{souscripteur.phone}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button color="green" onClick={() => setOpen(false)}>
          <Icon name="checkmark" /> OK
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default SelectSouscriptorModal;
