import { USERS_ROLE_ENUMS_OPTIONS } from "../constants";

export const getTypeIntermediaireText = (key = "") => {
  let text = "Type inconnu";

  switch (key) {
    case "AGENT_GENERAL":
      text = "Agent Général";
      break;
    case "COURTIER":
      text = "Courtier";
      break;

    default:
      break;
  }

  return text;
};

export const getUserRoleReadableText = (key = "") => {
  let role = USERS_ROLE_ENUMS_OPTIONS.find((role) => role.key === key);

  return role?.text || "Type inconnu";
};

export const generateAvenantserieNumber = (numero_avenant = "") => {
  let initialCode = "0000000";
  return initialCode
    .slice(-(initialCode.length - numero_avenant?.trim()?.length))
    .concat(numero_avenant);
};

export const getReadebleOrderStatus = (key = "") => {
  let text = "Statut inconnu";
  switch (key) {
    case "ORDERED":
      text = "Commandé";
      break;
    case "CONFIRMED":
      text = "Confirmée";
      break;
    case "IN_PROGRESS":
      text = "En cours";
      break;
    case "CANCELED":
      text = "Annulée";
      break;
    default:
      break;
  }

  return text;
};

export const readableAttestationOrderPackagingType = (
  key = "",
  short = false
) => {
  let text = "Type inconnu";
  switch (key) {
    case "CARNET_CHEQUE":
      text = short ? "Carnet(s)" : "Carnet(s) de Cheque";
      break;
    case "EMBALLAGE_CONTINU":
      text = short ? "Attestion(s)" : "Emballage Continu";
      break;

    default:
      break;
  }

  return text;
};
