import React from "react";
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Button,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Label,
  Segment,
  Table,
} from "semantic-ui-react";
import Page from "../../../../components/Page";
import CompanyMagasinFormModal from "../../../../components/modalForms/CompanyMagasinFormModal";
import * as companyMagasinsService from "../../../../services/gestion_attestations/company/magasinsService";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import Swal from "sweetalert2";

function MagasinsList() {
  const [magasins, setMagasins] = React.useState([]);
  const [pagination, setPagination] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [selectedMagasinId, setSelectedMagasinId] = React.useState(null);

  const fetchMagasins = async () => {
    try {
      const { data } = await companyMagasinsService.fetchAll();

      setMagasins(data || []);
    } catch (error) {
      toast.error(
        "Une erreur est survenue lors de la recuperation des magasins"
      );
    }
  };

  const handleUpdateClick = (magasin) => {
    setSelectedMagasinId(magasin.idattestations_magasins);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedMagasinId(null);
    setOpen(false);
  };

  const handleDeleteClick = (magasin) => {
    Swal.fire({
      title: "Etes-vous sur de vouloir supprimer ce magasin ?",
      text: "Vous ne pourrez pas reprendre cette action !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        companyMagasinsService
          .deleteOne(magasin.idattestations_magasins)
          .then(() => {
            toast.success("Commande d'attestation supprimée avec succes");
            fetchMagasins();
          })
          .catch((error) => {
            toast.error(
              "Une erreur est survenue lors de la suppression de la commande d'attestation"
            );
          });
      }
    });
  };

  React.useEffect(() => {
    fetchMagasins();
  }, []);
  return (
    <Page title="Liste des magasins">
      <GridRow className="Souscriptions">
        <Grid.Row className="!mb-5">
          <Grid.Column width={16}>
            <Breadcrumb>
              <BreadcrumbSection link>Compagnie</BreadcrumbSection>
              <BreadcrumbDivider icon="right chevron" />
              <BreadcrumbSection link>
                Gestion des attestations
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection active>Magasins</BreadcrumbSection>
            </Breadcrumb>
            <Divider />
          </Grid.Column>
        </Grid.Row>
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid>
              <Grid.Column>
                <Header>Liste des magasins</Header>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input placeholder="Rechercher..." className="search !hidden">
              <input name="search" />
            </Input>
            <Button className="!ml-4" onClick={() => setOpen(true)}>
              <Icon name="add" /> Ajouter
            </Button>
          </Grid.Column>
        </Grid>
        <>
          <Segment className="!min-h-auto">
            <Table striped celled sortable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell>Nom du magasin</Table.HeaderCell>
                  <Table.HeaderCell>Type de magasin</Table.HeaderCell>
                  <Table.HeaderCell>Lieu </Table.HeaderCell>
                  <Table.HeaderCell>Téléphone</Table.HeaderCell>
                  <Table.HeaderCell>Statut</Table.HeaderCell>
                  <Table.HeaderCell>Création</Table.HeaderCell>
                  <Table.HeaderCell width={1} textAlign="right">
                    Actions
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {magasins.map((magasin, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>
                      {magasin.name}
                      {magasin.is_default === 1 && (
                        <sup>
                          <Icon name="check circle" color="green" />
                        </sup>
                      )}
                    </Table.Cell>
                    <Table.Cell>{magasin.type_magasin}</Table.Cell>
                    <Table.Cell>{magasin.location}</Table.Cell>
                    <Table.Cell>
                      {magasin.phone ? magasin.phone : "Aucun"}
                    </Table.Cell>
                    <Table.Cell>
                      {magasin.is_active ? (
                        <Label color="green">Actif</Label>
                      ) : (
                        <Label color="red">Inactif</Label>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {dayjs(magasin.created_at).format("DD/MM/YYYY")}
                    </Table.Cell>
                    <Table.Cell width={1} textAlign="right">
                      <Dropdown
                        pointing="right"
                        icon={<Icon name="ellipsis vertical" />}
                      >
                        <Dropdown.Menu>
                          <Dropdown.Item
                            icon={<Icon name="edit" />}
                            content="Editer"
                            onClick={() => handleUpdateClick(magasin)}
                          />
                          <Dropdown.Item
                            icon={<Icon name="trash" />}
                            content="Supprimer"
                            onClick={() => handleDeleteClick(magasin)}
                            disabled={magasin.is_default === 1}
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Segment>
        </>
      </GridRow>

      <CompanyMagasinFormModal
        open={open}
        setOpen={setOpen}
        callback={fetchMagasins}
        selectedMagasinId={selectedMagasinId}
        handleCloseModal={handleCloseModal}
      />
    </Page>
  );
}

export default MagasinsList;
