import React, { useState } from "react";
import {
  Button,
  Modal,
  Table,
  Label,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import currency from "currency.js";
import * as policeServices from "../../services/gestion_production/policesService";

function EncaissementModal({
  callback = () => {},
  open = false,
  setOpen,
  avenant,
  onSuccessPaymentCallBack = () => {},
}) {
  const [loading, setLoading] = useState(false);

  const makePayment = () => {
    setLoading(true);
    policeServices
      .makePayment({
        idavenants: avenant.idavenants,
      })
      .then((res) => {
        setLoading(false);
        onSuccessPaymentCallBack(res);
        toast("Votre encaissement a été effectué avec succès", {
          type: "success",
          title: "Succès",
        });
        setOpen(false);
      })
      .catch((err) => {
        toast("Erreur lors de l'encaissement", {
          type: "error",
          title: "Erreur",
        });
        setLoading(false);
      });
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      closeIcon
    >
      <Modal.Header>Encaissement</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Création</Table.HeaderCell>
                <Table.HeaderCell>Nom Assuré</Table.HeaderCell>
                <Table.HeaderCell>Montant Prime</Table.HeaderCell>
                <Table.HeaderCell>Montant à verser</Table.HeaderCell>
                <Table.HeaderCell>Statut</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  {dayjs(avenant?.created_at).format("DD-MM-YYYY")}
                </Table.Cell>
                <Table.Cell>{avenant?.assures?.denomination}</Table.Cell>
                <Table.Cell>
                  {currency(
                    parseFloat(avenant?.facturation?.total_a_payer)
                  ).format({
                    separator: " ",
                    symbol: "",
                  })}
                </Table.Cell>
                <Table.Cell>
                  {currency(
                    parseFloat(avenant?.facturation?.total_a_payer)
                  ).format({
                    separator: " ",
                    symbol: "",
                  })}
                </Table.Cell>
                <Table.Cell>
                  <Label
                    color={
                      avenant?.facturation?.payments?.idpayments
                        ? "green"
                        : "red"
                    }
                  >
                    {avenant?.facturation?.payments?.idpayments
                      ? "PAYE"
                      : "NON PAYE"}
                  </Label>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          Fermer
        </Button>
        <Button
          disabled={avenant?.facturation?.payments?.idpayments}
          content="Encaisser"
          labelPosition="right"
          icon="payment"
          onClick={makePayment}
          positive
          loading={loading}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default EncaissementModal;
