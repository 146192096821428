import React, { useEffect, useState } from "react";
import Page from "../../../../../../components/Page";
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Button,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Label,
  Segment,
  Table,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import GarantiesComplementairesModalForm from "../../../../../../components/modalForms/gestion_production/company/GarantiesComplementairesModalForm";
import * as garantiesComplementairesService from "../../../../../../services/gestion_production/company/garantiesComplementairesService";
import Swal from "sweetalert2";

function GarantiesComplementairesListPage() {
  const [garanties, setGaranties] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemUuid, setSelectedItemUuid] = useState(null);

  const getGaranties = () => {
    garantiesComplementairesService
      .getAll()
      .then((res) => {
        setGaranties(res.data);
      })
      .catch((err) => {
        toast.error("Opération échouée");
      });
  };

  const handleEditClick = (uuid) => {
    setSelectedItemUuid(uuid);
    setIsModalOpen(true);
  };

  const handleDeleteClick = (uuid) => {
    Swal.fire({
      title: "Etes-vous sûr?",
      text: "Vous ne pourrez pas récupérer ces informations!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer!",
    }).then((result) => {
      if (result.isConfirmed) {
        garantiesComplementairesService
          .deleteOne(uuid)
          .then((res) => {
            toast.success("Opération réussie");
            getGaranties();
          })
          .catch((err) => {
            toast.error("Opération échouée");
          });
      }
    });
  };

  useEffect(() => {
    getGaranties();
  }, []);

  return (
    <Page title="Liste des garanties comlémentaires">
      <GridRow className="Souscriptions">
        <Grid.Row className="!mb-5">
          <Grid.Column width={16}>
            <Breadcrumb>
              <BreadcrumbSection link>Compagnie</BreadcrumbSection>
              <BreadcrumbDivider icon="right chevron" />
              <BreadcrumbSection link>
                Gestion de la production
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection link>
                Paramètres de tarifications
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection active>
                Liste des garanties complémentaires
              </BreadcrumbSection>
            </Breadcrumb>
            <Divider />
          </Grid.Column>
        </Grid.Row>
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid>
              <Grid.Column>
                <Header>
                 Liste des garanties complémentaires
                </Header>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              gap: "10px",
            }}
          >
            <Button
              onClick={() => {
                setSelectedItemUuid(null);
                setIsModalOpen(true);
              }}
            >
              <Icon name="add" />
              Ajouter
            </Button>
          </Grid.Column>
        </Grid>
        <GridRow>
          <Grid.Column className="!mt-6">
            <Segment>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell collapsing>#</Table.HeaderCell>
                    <Table.HeaderCell>Nom de la garantie</Table.HeaderCell>
                    <Table.HeaderCell>Code</Table.HeaderCell>
                    <Table.HeaderCell>Active</Table.HeaderCell>
                    <Table.HeaderCell>Date de création</Table.HeaderCell>
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {garanties?.map((garantie, index) => (
                    <Table.Row key={index}>
                      <Table.Cell collapsing>{index + 1}</Table.Cell>
                      <Table.Cell>{garantie.name}</Table.Cell>
                      <Table.Cell>{garantie.code}</Table.Cell>
                      <Table.Cell>
                        <Label color={garantie.is_active ? "green" : "red"}>
                          {garantie.is_active ? "Oui" : "Non"}
                        </Label>
                      </Table.Cell>
                      <Table.Cell>
                        {dayjs(garantie.created_at).format("DD/MM/YYYY HH:mm")}
                      </Table.Cell>
                      <Table.Cell collapsing>
                        <Dropdown
                          pointing="right"
                          icon={<Icon name="ellipsis vertical" />}
                        >
                          <Dropdown.Menu>
                            <>
                              <Dropdown.Item
                                icon={<Icon name="edit" />}
                                content="Editer"
                                onClick={() => handleEditClick(garantie.uuid)}
                              />
                              <Dropdown.Item
                                icon={<Icon name="trash" />}
                                content="Supprimer"
                                onClick={() => {
                                  handleDeleteClick(garantie.uuid);

                                }}
                              />
                            </>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Segment>
          </Grid.Column>
        </GridRow>
      </GridRow>
      <GarantiesComplementairesModalForm
        selectedItemId={selectedItemUuid}
        setOpen={setIsModalOpen}
        open={isModalOpen}
        callback={() => {
          setIsModalOpen(false);
          setSelectedItemUuid(null);
          getGaranties();
        }}
        handleCloseModal={() => {
          setIsModalOpen(false);
          setSelectedItemUuid(null);
        }}
      />
    </Page>
  );
}

export default GarantiesComplementairesListPage;
