import { api } from "../../axios/api";

export const getInsurancesDurations = async () => {
  return api.get("/gestion-production/insurances-durations");
};

export const createInsurancesDurations = async (data) => {
  return api.post("/gestion-production/insurances-durations", data);
};

export const updateInsurancesDurations = async (uuid, data) => {
  return api.put("/gestion-production/insurances-durations/" + uuid, data);
};

export const deleteInsurancesDurations = async (uuid) => {
  return api.delete("/gestion-production/insurances-durations/" + uuid);
};

export const fetchOne = async (uuid) => {
  return api.get("/gestion-production/insurances-durations/" + uuid);
};
