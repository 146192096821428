import React, { useEffect, useState } from "react";
import Page from "../../../../../../components/Page";
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Form,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Label,
  List,
  Segment,
  Table,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import * as garantiesServices from "../../../../../../services/gestion_production/garantiesServices";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { Link, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as risqueGarantieServices from "../../../../../../services/gestion_production/risquesGarantiesServices";
import * as Yup from "yup";

function GarantiesListDetails() {
  const [garantie, setGarantie] = useState({});
  const [risques, setRisques] = useState([]);
  const [isModeEdit, setIsModeEdit] = useState(false);

  const { uuid } = useParams();

  const handleRemoveClick = (uuid) => {
    Swal.fire({
      title: "Etes-vous sûr?",
      text: "Vous ne pourrez pas revenir en arrière!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer!",
    }).then((result) => {
      if (result.isConfirmed) {
        let isSavedItem =
          risques.find((item) => item.uuid === uuid).created_at !== undefined;
        if (!isSavedItem) {
          setRisques(risques.filter((item) => item.uuid !== uuid));
        } else {
          risqueGarantieServices.deleteOne(uuid).then(
            (res) => {
              toast.success("Opération réussie");
              getRisques();
            },
            (err) => {
              toast.error("Opération échouée");
            }
          );
        }
      }
    });
  };

  const getGarantie = () => {
    garantiesServices
      .fetchOne(uuid)
      .then((res) => {
        setGarantie(res.data);
      })
      .catch((err) => {
        toast.error("Opération échouée");
      });
  };

  const handleAddItemClick = () => {
    setRisques([
      ...risques,
      {
        uuid:
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15),
        name: "",
      },
    ]);
  };

  const getRisques = () => {
    risqueGarantieServices.getAll(uuid).then(
      (res) => {
        setRisques(res.data);
      },
      (err) => {
        toast.error("Opération échouée");
      }
    );
  };

  useEffect(() => {
    getGarantie();
    getRisques();
  }, [uuid]);

  return (
    <Page title="Détails de la garantie">
      <GridRow className="Souscriptions">
        <Grid.Row className="!mb-5">
          <Grid.Column width={16}>
            <Breadcrumb>
              <BreadcrumbSection link>Pool</BreadcrumbSection>
              <BreadcrumbDivider icon="right chevron" />
              <BreadcrumbSection link>
                Gestion de la production
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection link>
                Paramètres de tarifications
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection
                link
                as={Link}
                to={"/pool/production/parametres-tarification/liste-garanties"}
              >
                Liste des garanties
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection active>Détails</BreadcrumbSection>
            </Breadcrumb>
            <Divider />
          </Grid.Column>
        </Grid.Row>
        <GridRow as={Segment}>
          <Label ribbon>Détails</Label>
          <Grid stackable columns={3} style={{ marginTop: "5px" }}>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Nom de la garantie</List.Header>
                    <List.Description>{garantie?.name}</List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Date de creation</List.Header>
                    <List.Description>
                      {dayjs(garantie?.created_at).format("DD/MM/YYYY")}
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Dernière modification</List.Header>
                    <List.Description>
                      {dayjs(garantie?.updated_at).format("DD/MM/YYYY")}
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid>
        </GridRow>

        <GridRow>
          <Grid.Column className="!mt-6">
            <Segment>
              <div className="flex justify-between">
                <Header className="!mb-0" as="h3">
                  Liste des risques couverts
                </Header>
                <div>
                  <Checkbox
                    toggle
                    label="Mode édition"
                    onClick={(e, data) => {
                      setIsModeEdit(data.checked);
                      getRisques();
                    }}
                    value={isModeEdit}
                  />
                </div>
              </div>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell collapsing>#</Table.HeaderCell>
                    <Table.HeaderCell>Nom du risque</Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      Date de création
                    </Table.HeaderCell>

                    {isModeEdit && (
                      <Table.HeaderCell width={3}>Actions</Table.HeaderCell>
                    )}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {risques?.map((item, index) => (
                    <Formik
                      key={item.uuid}
                      initialValues={{
                        name: item.name,
                        created_at: item.created_at,
                        updated_at: item.updated_at,
                        uuid: item.uuid,
                        garantie_uuid: garantie.uuid,
                      }}
                      onSubmit={(values, actions) => {
                        actions.setSubmitting(true);
                        const data = {
                          name: values.name,
                          garantie_uuid: garantie.uuid,
                        };

                        let ReqToSend = values.created_at
                          ? risqueGarantieServices.update(values.uuid, data)
                          : risqueGarantieServices.create(data);

                        ReqToSend.then((res) => {
                          toast.success("Opération réussie");
                          getGarantie();
                          getRisques();
                        })
                          .catch((err) => {
                            toast.error("Opération échouée");
                          })
                          .finally(() => {
                            actions.setSubmitting(false);
                          });
                      }}
                      validationSchema={Yup.object({
                        name: Yup.string().required("Ce champ est obligatoire"),
                      })}
                    >
                      {(formik) => (
                        <Table.Row key={item.uuid}>
                          <Table.Cell collapsing>{index + 1}</Table.Cell>
                          <Table.Cell>
                            {isModeEdit ? (
                              <Input
                                fluid
                                name="name"
                                placeholder="Nom du risque"
                                value={item.name}
                                onChange={(e) => {
                                  formik.setFieldValue("name", e.target.value);
                                  setRisques(
                                    risques.map((i) =>
                                      i.uuid === item.uuid
                                        ? { ...i, name: e.target.value }
                                        : i
                                    )
                                  );
                                }}
                                error={formik.errors.name}
                              />
                            ) : (
                              item.name
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {dayjs(item.created_at).format("DD/MM/YYYY")}
                          </Table.Cell>

                          {isModeEdit && (
                            <Table.Cell>
                              <Button
                                size="mini"
                                color="red"
                                icon
                                circular
                                onClick={() => {
                                  handleRemoveClick(item.uuid);
                                }}
                                disabled={formik.isSubmitting}
                              >
                                <Icon name="trash" />
                              </Button>
                              <Button
                                className="!ml-3"
                                color="green"
                                size="mini"
                                icon
                                circular
                                loading={formik.isSubmitting}
                                disabled={formik.isSubmitting}
                                type="submit"
                                onClick={() => {
                                  formik.submitForm();
                                }}
                              >
                                <Icon name="save" />
                              </Button>
                            </Table.Cell>
                          )}
                        </Table.Row>
                      )}
                    </Formik>
                  ))}
                </Table.Body>
                {isModeEdit && (
                  <Table.Footer fullWidth>
                    <Table.Row>
                      <Table.HeaderCell colspan={4}>
                        <Button
                          icon
                          circular
                          onClick={() => {
                            handleAddItemClick();
                          }}
                        >
                          <Icon name="plus" />
                        </Button>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                )}
              </Table>
            </Segment>
          </Grid.Column>
        </GridRow>
      </GridRow>
    </Page>
  );
}

export default GarantiesListDetails;
