import { api } from "../../../axios/api";

export const createDistribution = async (data) => {
  return api.post("gestion-attestations/companies/distributions", data);
};

export const updateDistribution = async (uuid, data) => {
  return api.put(`gestion-attestations/companies/distributions/${uuid}`, data);
};

export const deleteDistribution = async (uuid) => {
  return api.delete(`gestion-attestations/companies/distributions/${uuid}`);
};
