import React from "react";
import { Form, Input } from "semantic-ui-react";

import dayjs from "dayjs";

function ReferencesConditions({ formik, insuranceDurations = [] }) {
  const { values, errors, handleBlur, handleChange, setFieldValue } = formik;
  const { conditions_particulieres } = values;
  return (
    <React.Fragment key="conditions_particulieres">
      <Form.Group widths={"equal"}>
        <Form.Field
          error={"conditions_particulieres.date_proposition" in errors}
          required
        >
          <label>Date de proposition</label>
          <Input
            type="datetime-local"
            value={conditions_particulieres.date_proposition}
            onChange={handleChange("conditions_particulieres.date_proposition")}
            onBlur={handleBlur("conditions_particulieres.date_proposition")}
          />
        </Form.Field>
        <Form.Field
          error={"conditions_particulieres.date_effet" in errors}
          required
        >
          <label>Date d'éffet</label>
          <Input
            type="datetime-local"
            value={values.conditions_particulieres.date_effet}
            onChange={(e, data) => {
              setFieldValue(
                "conditions_particulieres.date_effet",
                dayjs(data.value).format("YYYY-MM-DD HH:mm"),
                true
              );
              const month =
                values?.conditions_particulieres.type_renouvellement?.split(
                  "_"
                )[0];
              setFieldValue(
                "conditions_particulieres.date_echeance",
                dayjs(dayjs(data.value).format("YYYY-MM-DD HH:mm"))
                  .add(Number(month), "months")
                  .add(-1, "day")
                  .format("YYYY-MM-DD HH:mm"),
                true
              );
            }}
            onBlur={handleBlur("conditions_particulieres.date_effet")}
          />
        </Form.Field>
        <Form.Field
          error={"conditions_particulieres.type_renouvellement" in errors}
          required
        >
          <label>Durée du contrat</label>
          <Form.Select
            options={[
              {
                key: "",
                text: "Choisir...",
                value: "",
              },
              ...insuranceDurations?.map((insuranceDuration) => ({
                key: insuranceDuration.uuid,
                value: insuranceDuration.uuid,
                text: `${insuranceDuration.insurances_durations.months_number} mois`,
              })),
            ]}
            value={conditions_particulieres?.type_renouvellement}
            onChange={(e, data) => {
              setFieldValue(
                "conditions_particulieres.type_renouvellement",
                data.value,
                true
              );
              const month = insuranceDurations?.find(
                (insuranceDuration) => insuranceDuration.uuid === data.value
              )?.insurances_durations?.months_number;
              setFieldValue(
                "conditions_particulieres.date_echeance",
                dayjs(conditions_particulieres.date_effet)
                  .add(Number(month), "months")
                  .add(-1, "day")
                  .format("YYYY-MM-DD HH:mm"),
                true
              );
            }}
          />
        </Form.Field>
        <Form.Field
          required
          error={"conditions_particulieres.date_echeance" in errors}
        >
          <label>Date d'expiration</label>
          <Input
            type="datetime-local"
            readOnly
            value={conditions_particulieres.date_echeance}
            onChange={handleChange("conditions_particulieres.date_echeance")}
            onBlur={handleBlur("conditions_particulieres.date_echeance")}
          />
        </Form.Field>
      </Form.Group>
      <Form.Field
        required
        error={"conditions_particulieres.type_avenant" in errors}
      >
        <label>Type d'avenant</label>
        <Form.Select
          readOnly
          disabled
          options={[
            {
              key: "AFFAIRE_NOUVELLE",
              text: "AFFAIRE NOUVELLE",
              value: "AFFAIRE_NOUVELLE",
            },
            {
              key: "RENOUVELLEMEMNT",
              text: "RENOUVELLEMENT",
              value: "RENOUVELLEMEMNT",
            },
            { key: "RESILIATION", text: "RESILIATION", value: "RESILIATION" },
            { key: "SUSPENSION", text: "SUSPENSION", value: "SUSPENSION" },
            {
              key: "MODIFICATION_PRISE_EFFET",
              text: "MODIFICATION DE PRISE D'EFFET",
              value: "MODIFICATION_PRISE_EFFET",
            },
            {
              key: "CHANGEMENT_IMMATRICULATION",
              text: "CHANGEMENT D'IMMATRICULATION",
              value: "CHANGEMENT_IMMATRICULATION",
            },
            {
              key: "REMISE_VIGUEUR",
              text: "REMISE EN VIGUEUR",
              value: "REMISE_VIGUEUR",
            },
            {
              key: "INCORPORATION",
              text: "INCORPORATION",
              value: "INCORPORATION",
            },
            { key: "RETRAIT", text: "RETRAIT", value: "RETRAIT" },
            {
              key: "EMISSION_ATTESTATION",
              text: "EXTENSION DE GARANTIES",
              value: "EXTENSION_ATTESTATION",
            },
            {
              key: "EXTENSION_GARANTIES",
              text: "EXTENSION DE GARANTIES",
              value: "EXTENSION_GARANTIES",
            },
            {
              key: "RETRAIT_GARANTIES",
              text: "RETRAIT DE GARANTIES",
              value: "RETRAIT_GARANTIES",
            },
            {
              key: "AVENANT_CHANGEMENT_NOM",
              text: "AVENANT DE CHANGEMENT DE NOM",
              value: "AVENANT_CHANGEMENT_NOM",
            },
          ]}
          value={values.conditions_particulieres.type_avenant}
          onChange={(e, data) => {
            setFieldValue("conditions_particulieres.type_avenant", data.value);
          }}
        />
      </Form.Field>
      <Form.Group widths={"equal"}></Form.Group>
    </React.Fragment>
  );
}

export default ReferencesConditions;
