import React from "react";
import { Button, Form, Header, Image, Input, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import * as compagniesService from "../../services/comagniesService";

const validationSchema = yup.object().shape({
  denomination: yup.string().required(),
  siege_social: yup.string().required(),
  phone_1: yup.string().min(8).max(16),
  phone_2: yup.string().min(8).max(16),
});
function CompanyFormModal({ callback = () => {}, open=false, setOpen }) {
  const formik = useFormik({
    initialValues: {
      denomination: "",
      siege_social: "",
      phone_1: "",
      phone_2: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      compagniesService
        .create(values)
        .then(
          (response) => {
            toast(`Comagnie ajoutée avec succès.`, {
              type: "success",
              theme: "colored",
            });
            handleReset();
            callback();
            setOpen(false);
          },
          (reason) => {
            toast(`${reason?.response?.data || reason?.message}`, {
              type: "error",
              theme: "colored",
            });
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const {
    values,
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    handleBlur,
    handleChange,
    handleReset,
  } = formik;
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      as={Form}
      size="tiny"
    >
      <Modal.Header>Ajouter Une compagnie</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form.Field required error={"denomination" in errors}>
            <label>Dénomination</label>
            <Input
              value={values.denomination}
              onChange={handleChange("denomination")}
              onBlur={handleBlur("denomination")}
            />
            <small className="field-error">{errors.denomination}</small>
          </Form.Field>
          <Form.Field error={"siege_social" in errors}>
            <label>Siège social</label>
            <Input
              value={values.siege_social}
              onChange={handleChange("siege_social")}
              onBlur={handleBlur("siege_social")}
            />
            <small className="field-error">{errors.siege_social}</small>
          </Form.Field>
          <Form.Group widths={"equal"}>
            <Form.Field error={'phone_1' in errors}>
              <label>Téléphone principal</label>
              <Input
                value={values.phone_1}
                onChange={handleChange("phone_1")}
                onBlur={handleBlur("phone_1")}
              />
              <small className="field-error">{errors.phone_1}</small>
            </Form.Field>
            <Form.Field error={'phone_2' in errors}>
              <label>Téléphone secondaire</label>
              <Input
                value={values.phone_2}
                onChange={handleChange("phone_2")}
                onBlur={handleBlur("phone_2")}
              />
              <small className="field-error">{errors.phone_2}</small>
            </Form.Field>
          </Form.Group>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          type="submit"
          loading={isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default CompanyFormModal;
