import React from "react";
import {
  Grid,
  GridRow,
  Header,
  Input,
  Segment,
} from "semantic-ui-react";
import DaylyProductionChart from "../../components/charts/DaylyProductionChart";
import ValidatedSouscriptionsChart from "../../components/charts/ValidatedSouscriptionsChart";
import Page from "../../components/Page";

function Dashboard() {
  return (
    <Page title="Dashboard">
      <GridRow className="DashBoard">
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Header>Dashboard</Header>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input placeholder="Rechercher..." className="search">
              <input name="search" />
            </Input>
          </Grid.Column>
        </Grid>

        <Grid>
          <Grid.Column>
            <Segment>
              <DaylyProductionChart />
            </Segment>
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column width={16}>
            <Segment
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                maxHeight: "300px",
              }}
            >
              <ValidatedSouscriptionsChart />
            </Segment>
          </Grid.Column>
        </Grid>
      </GridRow>
    </Page>
  );
}

export default Dashboard;
