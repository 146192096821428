import React, { useEffect } from "react";
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Segment,
  Table,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import * as poolGarantiesService from "../../../../services/gestion_production/pool/garantiesService";
import * as companyGarantiesService from "../../../../services/gestion_production/company/garantiesService";
import Swal from "sweetalert2";

const schema = Yup.object().shape({
  directive_uuid: Yup.string().required("Valeur obligatoire"),
  items: Yup.array(
    Yup.object().shape({
      uuid: Yup.string()
        .nullable()
        .transform((value, originalValue) =>
          originalValue === "" ? null : value
        ),
      code: Yup.string().required("Valeur obligatoire"),
      is_active: Yup.number().nullable().default(0),
      garantie_uuid: Yup.string().uuid().required("Valeur obligatoire"),
    })
  ),
});

function CompanyListeDesGaranties({ directiveUuid }) {
  const [isModeEdit, setIsModeEdit] = React.useState(false);
  const [poolGaranties, setPoolGaranties] = React.useState([]);

  const formik = useFormik({
    initialValues: {
      directive_uuid: "",
      items: [
        {
          uuid: null,
          code: "",
          is_active: 0,
          garantie_uuid: "",
        },
      ],
    },
    validationSchema: schema,
    onSubmit: () => {
      formik.setSubmitting(true);
      companyGarantiesService
        .storeBatch(formik.values)
        .then(
          (res) => {
            toast.success("Opération réussie");
            fetchItems(directiveUuid);
          },
          (err) => {
            toast.error("Opération échouée");
          }
        )
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
  });

  const addNewItem = () => {
    const items = [...formik.values.items];
    items.push({
      uuid: null,
      code: "",
      is_active: 0,
    });
    formik.setFieldValue("items", items);
  };

  const deleteItem = (index) => {
    const items = [...formik.values.items];
    items.splice(index, 1);
    formik.setFieldValue("items", items);
  };

  const fetchItems = (directiveUuid) => {
    companyGarantiesService
      .getGaranties(directiveUuid)
      .then(
        (res) => {
          const items = [];
          res.data.forEach((item) => {
            items.push({
              uuid: item.uuid,
              code: item.code,
              is_active: item.is_active,
              garantie_uuid:
                item.pool_directives_tarifaires_has_listes_garanties?.uuid,
            });
          });
          formik.setFieldValue("items", items);
        },
        (error) => {
          toast.error("Opération échouée ");
        }
      )
      .catch((error) => toast.error("Opération échouée "));
  };

  const handleRemoveItem = (index) => {
    Swal.fire({
      title: "Etes-vous sûr?",
      text: "Vous ne pourrez pas revenir en arrière!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer!",
    }).then((result) => {
      if (result.isConfirmed) {
        const item = formik.values.items[index];
        if (!item.uuid) {
          deleteItem(index);
          return true;
        }
        companyGarantiesService.removeItem(item.uuid).then(
          (res) => {
            toast.success("Opération réussie");
            fetchItems(directiveUuid);
            deleteItem(index);
          },
          (err) => {
            toast.error("Opération échouée");
          }
        );
      }
    });
  };

  const fetchPoolGaranties = () => {
    poolGarantiesService
      .getGaranties(directiveUuid)
      .then(
        (res) => {
         
          setPoolGaranties(
            res.data?.pool_directives_tarifaires_has_listes_garanties || []
          );
        },
        (error) => {
          toast.error("Opération échouée");
        }
      )
      .catch((error) => toast.error("Opération échouée "));
  };

  const addRequiredGaranties = () => {
    const requiredGaranties = poolGaranties?.filter(
      (garantie) => garantie.is_required === 1
    );

    const items = [...formik.values.items];

    requiredGaranties.forEach((garantie) => {
      // check if item already exists
      const existingItem = items.find(
        (item) => item.garantie_uuid === garantie.uuid
      );
      if (existingItem) return;

      items.push({
        uuid: null,
        code: "",
        is_active: 1,
        garantie_uuid: garantie.uuid,
      });
    });
    formik.setFieldValue("items", items);
  };

  useEffect(() => {
    fetchPoolGaranties();
  }, []);

  useEffect(() => {
    formik.setFieldValue("directive_uuid", directiveUuid);
  }, []);

  useEffect(() => {
    if (directiveUuid) fetchItems(directiveUuid);
  }, [directiveUuid]);

  return (
    <GridRow>
      <Grid.Column className="!mt-6">
        <Segment>
          <div className="flex justify-between items-center">
            <Header className="!mb-0">
              Liste des garanties de la compagnie
            </Header>
            <Checkbox
              toggle
              label="Mode édition"
              onChange={(e, { checked }) => {
                setIsModeEdit(checked);
                if (checked) addRequiredGaranties();
              }}
              checked={isModeEdit}
            />
          </div>
          <Divider />
          <Table striped celled textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell collapsing width={1}>
                  #
                </Table.HeaderCell>
                <Table.HeaderCell width={6}>Garantie</Table.HeaderCell>
                <Table.HeaderCell width={3}>code</Table.HeaderCell>
                <Table.HeaderCell width={2}>Garantie active</Table.HeaderCell>
                {isModeEdit ? (
                  <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
                ) : null}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {formik.values?.items?.map((item, index) => (
                <Table.Row key={index}>
                  <Table.Cell>{index + 1}</Table.Cell>
                  <Table.Cell textAlign="left">
                    {isModeEdit ? (
                      <Dropdown
                        fluid
                        search
                        selection
                        value={item.garantie_uuid}
                        disabled={
                          poolGaranties?.find(
                            (garantie) => garantie.uuid === item.garantie_uuid
                          )?.is_required === 1
                        }
                        options={[
                          {
                            key: "",
                            text: "Choisir...",
                            value: "",
                          },
                        ].concat(
                          poolGaranties?.map((garantie) => ({
                            key: garantie.uuid,
                            text: `${garantie.liste_garanties.name} - (${
                              garantie.is_required === 1
                                ? "Obligatoire"
                                : "Facultatif"
                            })`,
                            value: garantie.uuid,
                          }))
                        )}
                        onChange={(e, { value }) => {
                          const poolItem = poolGaranties.find(
                            (garantie) => garantie.uuid === value
                          );

                          if (poolItem?.is_required === 1) {
                            return false;
                          }
                          const items = [...formik.values.items];
                          items[index].garantie_uuid = value;
                          formik.setFieldValue("items", items);
                        }}
                      />
                    ) : (
                      <div>
                        {`${
                          poolGaranties?.find(
                            (garantie) => garantie.uuid === item.garantie_uuid
                          )?.liste_garanties.name
                        }`}
                        {poolGaranties?.find(
                          (garantie) => garantie.uuid === item.garantie_uuid
                        )?.is_required === 1 && (
                          <sup>
                            <Icon name="lock" color="red" />
                          </sup>
                        )}
                      </div>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {isModeEdit ? (
                      <Input
                        fluid
                        value={item.code}
                        onChange={(e) => {
                          const items = [...formik.values.items];
                          items[index].code = e.target.value;
                          formik.setFieldValue("items", items);
                        }}
                      />
                    ) : (
                      item.code
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {isModeEdit ? (
                      <Checkbox
                        slider
                        checked={item.is_active === 1 ? true : false}
                        onChange={(e, { checked }) => {
                          const items = [...formik.values.items];
                          items[index].is_active = checked ? 1 : 0;
                          formik.setFieldValue("items", items);
                        }}
                        disabled={
                          poolGaranties?.find(
                            (garantie) => garantie.uuid === item.garantie_uuid
                          )?.is_required === 1
                        }
                      />
                    ) : item.is_active === 1 ? (
                      "Oui"
                    ) : (
                      "Non"
                    )}
                  </Table.Cell>
                  {isModeEdit ? (
                    <Table.Cell>
                      {poolGaranties?.find(
                        (garantie) => garantie.uuid === item.garantie_uuid
                      )?.is_required === 1 ? (
                        <Icon name="lock" color="red" />
                      ) : (
                        <Icon
                          name="trash"
                          color="red"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleRemoveItem(index);
                          }}
                        />
                      )}
                    </Table.Cell>
                  ) : null}
                </Table.Row>
              ))}
            </Table.Body>
            {isModeEdit ? (
              <Table.Footer fullWidth>
                <Table.Row>
                  <Table.HeaderCell colspan={isModeEdit ? 5 : 4}>
                    <Button
                      className="!ml-4"
                      icon="add"
                      size="tiny"
                      circular
                      floated="left"
                      onClick={() => {
                        addNewItem();
                      }}
                    />
                    <Button
                      content="Enregistrer"
                      icon="save"
                      labelPosition="left"
                      color="green"
                      type="submit"
                      floated="right"
                      size="tiny"
                      onClick={() => {
                        formik.handleSubmit();
                      }}
                    />
                    <Button
                      content="Annuler"
                      icon="cancel"
                      labelPosition="left"
                      color="red"
                      floated="right"
                      size="tiny"
                      loading={formik.isSubmitting}
                      disabled={formik.isSubmitting}
                      onClick={() => {
                        setIsModeEdit(false);
                        formik.resetForm();
                        fetchItems(directiveUuid);
                      }}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            ) : null}
          </Table>
        </Segment>
      </Grid.Column>
    </GridRow>
  );
}

export default CompanyListeDesGaranties;
