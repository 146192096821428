import React, { useEffect } from "react";
import { Button, Form, Input, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as agencyRequestsService from "../../../services/gestion_attestations/agency/requestsService";

const validationSchema = yup.object().shape({
  quantity: yup
    .number("Veuillez renseigner une quantité valide")
    .min(1, "Veuillez renseigner une quantité valide")
    .required("Veuillez renseigner la quantité"),
});

function AgencyRequestAttestationFormModal({
  callback = () => {},
  open = false,
  setOpen,
  selectedRequestId,
  handleCloseModal = () => {},
}) {
  const formik = useFormik({
    initialValues: {
      quantity: 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const request = selectedRequestId
        ? agencyRequestsService.update(selectedRequestId, values)
        : agencyRequestsService.create(values);
      request
        .then((response) => {
          toast.success(
            selectedRequestId
              ? "Demande d'attestation mise à jour"
              : "Demande d'attestation ajoutée"
          );
          setOpen(false);
          callback();
          formik.resetForm();
          handleCloseModal();
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.error ||
              "Une erreur est survenue lors de " +
                (selectedRequestId ? "la mise à jour" : "l'ajout") +
                " de la demande d'attestation"
          );
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
  });

  const {
    values,
    errors,
    handleSubmit,
    isSubmitting,
    handleBlur,
    handleChange,
  } = formik;

  const fetchData = (selectedRequestId) => {
    agencyRequestsService
      .fetchOne(selectedRequestId)
      .then((response) => {
        if (typeof response.data === "object") {
          const data = response.data;
          formik.setValues(
            {
              quantity: data.quantity,
              attestations_stocks_uuid: data.attestations_stocks?.uuid,
            },
            true
          );
        }
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.error ||
            "Une erreur est survenue lors de la récupération"
        );
      })
      .finally(() => {});
  };

  useEffect(() => {
    formik.resetForm();
    if (selectedRequestId !== null && open) {
      fetchData(selectedRequestId);
    }
  }, [selectedRequestId, open]);

  return (
    <Modal
      onClose={() => {
        handleCloseModal();
      }}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      size="tiny"
      closeIcon
    >
      <Modal.Header>
        {selectedRequestId
          ? "Mettre à jour la demande d'attestation"
          : "Ajouter une nouvelle demande d'attestation"}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description className="ui form">
          <Form.Field required error={"quantity" in errors}>
            <label>Quantité d'attestations</label>
            <Input
              type="number"
              name="quantity"
              value={values.quantity}
              onChange={handleChange("quantity")}
              onBlur={handleBlur("quantity")}
            />
            <small className="field-error">{errors.quantity}</small>
          </Form.Field>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          onClick={() => {
            handleCloseModal();
          }}
        >
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          type="submit"
          loading={isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default AgencyRequestAttestationFormModal;
