import { api } from "../../../axios/api";

export const createAgencyDistribution = async (data) => {
  return api.post(
    "/gestion-attestations/intermediaires/agencies-distributions",
    data
  );
};

export const updateAgencyDistribution = async (uuid, data) => {
  return api.put(
    `/gestion-attestations/intermediaires/agencies-distributions/${uuid}`,
    data
  );
};

export const deleteAgencyDistribution = async (uuid) => {
  return api.delete(
    `/gestion-attestations/intermediaires/agencies-distributions/${uuid}`
  );
};
