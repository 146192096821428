import React, {  } from "react";
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Divider,
  Grid,
  GridRow,
} from "semantic-ui-react";
import Page from "../../../../components/Page";

import SouscriptionMonoForm from "../../../../components/production/souscriptions/SouscriptionMonoForm";

function SouscriptionMonoPage() {
  return (
    <Page title="Ajouter une souscription">
      <GridRow className="Souscriptions">
        <Grid.Row className="!mb-5">
          <Grid.Column width={16}>
            <Breadcrumb>
              <BreadcrumbSection link>Agence</BreadcrumbSection>
              <BreadcrumbDivider icon="right chevron" />
              <BreadcrumbSection link>
                Gestion de la production
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection active>Souscription</BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection active>Mono</BreadcrumbSection>
            </Breadcrumb>
            <Divider />
          </Grid.Column>
        </Grid.Row>

        <GridRow>
          <SouscriptionMonoForm />
        </GridRow>
      </GridRow>
    </Page>
  );
}

export default SouscriptionMonoPage;
