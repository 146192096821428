import React, { useEffect, useState } from "react";
import Page from "../../../../components/Page";
import {
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Label,
  List,
  Segment,
  Table,
  Breadcrumb,
  BreadcrumbSection,
  BreadcrumbDivider,
} from "semantic-ui-react";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import * as deliveriesService from "../../../../services/gestion_attestations/company/deliveries";
import { Link } from "react-router-dom";
import MagasinStockAttributionFormModal from "../../../../components/modalForms/gestion_attestations/MagasinStockAttributionFormModal";
import * as magasinsStockService from "../../../../services/gestion_attestations/company/magasinsStockService";
import StockDoughnutChart from "../../../../components/charts/StockDoughnutChart";

function DeliveryDetails() {
  const [delivery, setDelivery] = useState(null);
  const [magasinStocks, setMagasinStocks] = useState([]);
  const [
    selectedMagasinStockAttributionUuid,
    setSelectedMagasinStockAttributionUuid,
  ] = useState(null);
  const [magasinAttributionModalOpen, setMagasinAttributionModalOpen] =
    useState(false);
  const uuid = window.location.pathname.split("/").pop();

  const fetchDelivery = async () => {
    try {
      const { data } = await deliveriesService.fetchOne(uuid);
      setDelivery(data);
    } catch (error) {
      toast.error(
        error?.response?.data?.error ||
          "Une erreur est survenue lors de la recuperation de la livraison"
      );
    }
  };

  const fetchMagasinsStocks = async () => {
    try {
      const { data } = await magasinsStockService.fetchByDelivery(
        delivery?.uuid
      );
      setMagasinStocks(data);
    } catch (error) {
      toast.error(
        error?.response?.data?.error ||
          "Une erreur est survenue lors de la récupération des stocks"
      );
    }
  };

  const handleDeleteMagasinStockClick = async (magasinStock) => {
    Swal.fire({
      title: "Etes-vous sur de vouloir supprimer ce stock ?",
      text: "Vous ne pourrez pas reprendre cette action !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer",
      cancelButtonText: "Annuler",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await magasinsStockService
          .deleteOne(magasinStock.uuid)
          .then(() => {
            toast.success("Stock supprimé avec succes");
            fetchMagasinsStocks();
            fetchDelivery();
          })
          .catch((error) => {
            toast.error(
              error?.response?.data?.error ||
                "Une erreur est survenue lors de la suppression du stock"
            );
          });
      }
    });
  };

  useEffect(() => {
    fetchDelivery();
  }, []);

  useEffect(() => {
    if (delivery !== null) {
      fetchMagasinsStocks();
    }
  }, [delivery]);

  return (
    <Page title="Détails de la commande">
      <GridRow className="Souscriptions">
        <Grid.Row className="!mb-5">
          <Grid.Column width={16}>
            <Breadcrumb>
              <BreadcrumbSection link>Compagnie</BreadcrumbSection>
              <BreadcrumbDivider icon="right chevron" />
              <BreadcrumbSection link>
                Gestion des attestations
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection link as={Link} to="/company/stocks/commandes">
                Commandes
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection
                link
                as={Link}
                to={`/company/stocks/commandes/details/${delivery?.attestations_stocks?.attestations_orders?.uuid}`}
              >
                Commande
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection active>
                Détails de la livraison
              </BreadcrumbSection>
            </Breadcrumb>
            <Divider />
          </Grid.Column>
        </Grid.Row>
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid>
              <Grid.Column>
                <Header>
                  Détails de la livraison [
                  {delivery?.idattestations_deliveries
                    ?.toString()
                    .padStart(6, "0")}
                  ]
                </Header>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          ></Grid.Column>
        </Grid>
        <>
          <GridRow as={Segment}>
            <Label ribbon>Détails de la livraison</Label>
            <Grid stackable columns={3} style={{ marginTop: "5px" }}>
              <Grid.Column>
                <List>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Numéro de livraison</List.Header>
                      <List.Description>
                        {delivery?.idattestations_deliveries
                          ?.toString()
                          .padStart(6, "0")}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Quantité livrée</List.Header>
                      <List.Description>
                        {new Intl.NumberFormat().format(
                          delivery?.usage?.totalQty
                        )}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column>
                <List>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Numéro du stocks</List.Header>
                      <List.Description>
                        {delivery?.attestations_stocks?.idattestations_stocks
                          ?.toString()
                          .padStart(6, "0") || "-"}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Série du stocks</List.Header>
                      <List.Description>
                        {delivery?.attestations_stocks
                          ? `
                          ${delivery?.attestations_stocks?.start_serial}
                          - ${delivery?.attestations_stocks?.end_serial}
                          ` || "-"
                          : "-"}{" "}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column>
                <List>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Date de livraison</List.Header>
                      <List.Description>
                        {dayjs(delivery?.created_at).format("DD/MM/YYYY HH:mm")}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Plage de la série</List.Header>
                      <List.Description>
                        {delivery?.start_serial
                          ? `${delivery?.start_serial} - ${delivery?.end_serial}`
                          : "-"}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid>
          </GridRow>

          <>
            <StockDoughnutChart
              total={delivery?.usage?.totalQty}
              consumed={delivery?.usage?.consumedQty}
              remaining={delivery?.usage?.remainingQty}
              labels={[
                {
                  label: "Reçue",
                  value: delivery?.usage?.consumedQty,
                  color: "#FF6384",
                },
                {
                  label: "Restant",
                  value: delivery?.usage?.remainingQty,
                  color: "#36A2EB",
                },
              ]}
            />
            <GridRow as={Segment}>
              <div className="ui label top attached !flex !justify-between">
                <h3>Liste des attributions</h3>
                {delivery?.usage?.remainingQty > 0 && (
                  <div
                    className="basic"
                    style={{ cursor: "pointer" }}
                    onClick={() => setMagasinAttributionModalOpen(true)}
                  >
                    <Icon name="add" />
                    Distribuer à un magasin
                  </div>
                )}
              </div>
              <Table basic="very">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Numéro</Table.HeaderCell>
                    <Table.HeaderCell>Magasin</Table.HeaderCell>
                    <Table.HeaderCell>Quantité</Table.HeaderCell>
                    <Table.HeaderCell>Plage</Table.HeaderCell>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell collapsing>Action</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {magasinStocks?.map(
                    (magasinStock) =>
                      magasinStock.attestations_magasins.is_default === 0 && (
                        <Table.Row>
                          <Table.Cell>
                            {magasinStock?.idattestations_magasins_stocks
                              ?.toString()
                              .padStart(6, "0")}
                          </Table.Cell>
                          <Table.Cell>
                            {magasinStock?.attestations_magasins?.name}
                          </Table.Cell>
                          <Table.Cell>
                            {new Intl.NumberFormat().format(
                              parseInt(magasinStock?.end_serial) -
                                parseInt(magasinStock?.start_serial) +
                                1
                            )}
                          </Table.Cell>

                          <Table.Cell>
                            {`${magasinStock?.start_serial} - ${magasinStock?.end_serial}`}
                          </Table.Cell>
                          <Table.Cell>
                            {dayjs(magasinStock?.created_at).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </Table.Cell>
                          <Table.Cell className="text-right">
                            <Dropdown
                              pointing="right"
                              icon={<Icon name="ellipsis vertical" />}
                            >
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  icon={<Icon name="edit" />}
                                  content="Editer"
                                  onClick={() => {
                                    setMagasinAttributionModalOpen(true);
                                    setSelectedMagasinStockAttributionUuid(
                                      magasinStock?.uuid
                                    );
                                  }}
                                />
                                <Dropdown.Item
                                  icon={<Icon name="trash" />}
                                  content="Supprimer"
                                  onClick={() => {
                                    handleDeleteMagasinStockClick(magasinStock);
                                  }}
                                />
                              </Dropdown.Menu>
                            </Dropdown>
                          </Table.Cell>
                        </Table.Row>
                      )
                  )}
                </Table.Body>
              </Table>
            </GridRow>
            <GridRow as={Segment} className="!hidden">
              <div className="ui label top attached !flex !justify-between">
                <h3>Liste des demandes</h3>
                <div className="basic" style={{ cursor: "pointer" }}>
                  <Icon name="add" />
                  Faire une demande
                </div>
              </div>
              <Table basic="very">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Numéro</Table.HeaderCell>
                    <Table.HeaderCell>Quantité demandée</Table.HeaderCell>
                    <Table.HeaderCell>Plage</Table.HeaderCell>
                    <Table.HeaderCell>Statut</Table.HeaderCell>
                    <Table.HeaderCell>Date de livraison</Table.HeaderCell>
                    <Table.HeaderCell collapsing>Action</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body></Table.Body>
              </Table>
            </GridRow>
          </>
        </>
      </GridRow>

      <MagasinStockAttributionFormModal
        setOpen={setMagasinAttributionModalOpen}
        open={magasinAttributionModalOpen}
        deliveryUuid={delivery?.uuid}
        handleCloseModal={() => {
          setMagasinAttributionModalOpen(false);
          setSelectedMagasinStockAttributionUuid(null);
        }}
        callback={() => {
          setMagasinAttributionModalOpen(false);
          setSelectedMagasinStockAttributionUuid(null);
          fetchDelivery();
          fetchMagasinsStocks();
        }}
        selectedItemId={selectedMagasinStockAttributionUuid}
      />
    </Page>
  );
}

export default DeliveryDetails;
