import React, { useEffect, useState } from "react";
import Page from "../../../../components/Page";
import {
  Divider,
  Grid,
  GridRow,
  Label,
  List,
  Message,
  Segment,
  Breadcrumb,
  BreadcrumbSection,
  BreadcrumbDivider,
  ListItem,
  ListContent,
  ListHeader,
  ListDescription,
} from "semantic-ui-react";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import * as requestsService from "../../../../services/gestion_attestations/company/distributionsRequestsService";
import CompanyDistributionModalForm from "../../../../components/modalForms/gestion_attestations/CompanyDistributionModalForm";

function DistributionsRequestsDetails() {
  const [request, setRequest] = useState(null);
  const uuid = window.location.pathname.split("/").pop();
  const [openDistributionModal, setOpenDistributionModal] = useState(false);

  const fetchRequest = async () => {
    try {
      const { data } = await requestsService.fetchOne(uuid);
      setRequest(data);
    } catch (error) {
      toast.error(
        error?.response?.data?.error ||
          "Une erreur est survenue lors de la recuperation de la demande"
      );
    }
  };

  useEffect(() => {
    fetchRequest();
  }, []);

  return (
    <Page title="Détails de la demande">
      <GridRow className="Souscriptions">
        <Grid.Row className="!mb-5">
          <Grid.Column width={16}>
            <Breadcrumb>
              <BreadcrumbSection link>Pool</BreadcrumbSection>
              <BreadcrumbDivider icon="right chevron" />
              <BreadcrumbSection link>
                Gestion des attestations
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection
                link
                as={Link}
                to={`/company/stocks/distributions-requests`}
              >
                Demandes
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right chevron" />
              <BreadcrumbSection active>détail de la demande</BreadcrumbSection>
            </Breadcrumb>
            <Divider />
          </Grid.Column>
        </Grid.Row>
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          ></Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          ></Grid.Column>
        </Grid>
        <>
          <GridRow as={Segment}>
            <Label ribbon>Détails</Label>
            <Grid stackable columns={3} style={{ marginTop: "5px" }}>
              <Grid.Column>
                <List>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Numéro de demande</List.Header>
                      <List.Description>
                        {request?.idattestations_distributions_requests
                          ?.toString()
                          .padStart(6, "0")}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Quantité demandée</List.Header>
                      <List.Description>
                        {request?.quantity &&
                          new Intl.NumberFormat().format(
                            request?.quantity
                          )}{" "}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column>
                <List>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">
                        {request?.creative_entity === "agence"
                          ? "Agence"
                          : "Intermédiaire"}
                      </List.Header>
                      <List.Description>
                        {request?.entity?.denomination}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Statut</List.Header>
                      <List.Description>
                        {request?.attestations_distributions
                          ? "Livrée"
                          : "En cours"}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column>
                <List>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Date de demande</List.Header>
                      <List.Description>
                        {dayjs(request?.created_at).format("DD/MM/YYYY HH:mm")}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="tag" />
                    <List.Content>
                      <List.Header as="a">Numéro de distribution</List.Header>
                      <List.Description>
                        {request?.attestations_distributions
                          ? request?.attestations_distributions?.idattestations_distributions
                              ?.toString()
                              .padStart(6, "0")
                          : "----"}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid>
          </GridRow>

          <Grid as={Segment}>
            <Grid.Column width={10}>
              <Message
                header="Note"
                content={`
                  ${
                    request?.attestations_distributions
                      ? "La Distribution pour cette demande a été enregistrée le " +
                        dayjs(
                          request?.attestations_distributions?.created_at
                        ).format("DD/MM/YYYY HH:mm") +
                        ". La plage de la Distribution est " +
                        request?.attestations_distributions?.start_serial +
                        " - " +
                        request?.attestations_distributions?.end_serial
                      : "Vous pouvez ajouter une Distribution de en réponse de cette demande."
                  }
                  `}
              />
              <Message
                header="Avertissement"
                warning
                content="Vous ne pouvez pas modifier une Distribution si elle  a été envoyés si une partie a été envoyée."
              />
            </Grid.Column>
            <Grid.Column width={6}>
              <div className="ui attached fluid segment">
                <div className="ui top attached label !flex !justify-between !items-center">
                  <div>Détails de la distribution</div>
                  <div
                    className="cursor-pointer"
                    onClick={() => setOpenDistributionModal(true)}
                  >
                    <i
                      className={
                        request?.attestations_distributions
                          ? "edit icon"
                          : "plus icon"
                      }
                    />
                    {request?.attestations_distributions
                      ? "Modifier"
                      : "Ajouter"}
                  </div>
                </div>
              </div>
              {request?.attestations_distributions ? (
                <List relaxed celled>
                  <ListItem>
                    <ListContent>
                      <ListHeader as="a">Magasin</ListHeader>
                      <ListDescription>
                        {
                          request?.attestations_distributions
                            ?.attestations_magasins_stocks
                            ?.attestations_magasins?.name
                        }
                      </ListDescription>
                    </ListContent>
                  </ListItem>
                  <ListItem>
                    <ListContent>
                      <ListHeader as="a">
                        Plage de distribution du stock{" "}
                      </ListHeader>
                      <ListDescription>
                        {`
                     ${request?.attestations_distributions?.attestations_magasins_stocks?.start_serial}
                     -
                     ${request?.attestations_distributions?.attestations_magasins_stocks?.end_serial}
                      `}
                      </ListDescription>
                    </ListContent>
                  </ListItem>
                  <ListItem>
                    <ListContent>
                      <ListHeader as="a">Quantité distribuée</ListHeader>
                      <ListDescription>
                        {`
                        ${new Intl.NumberFormat().format(
                          parseInt(
                            request?.attestations_distributions?.end_serial
                          ) -
                            parseInt(
                              request?.attestations_distributions?.start_serial
                            ) +
                            1
                        )}                          
                        `}
                      </ListDescription>
                    </ListContent>
                  </ListItem>
                </List>
              ) : null}
            </Grid.Column>
          </Grid>
        </>
      </GridRow>

      <CompanyDistributionModalForm
        selectedItemId={request?.uuid}
        open={openDistributionModal}
        setOpen={setOpenDistributionModal}
        callback={() => {
          setOpenDistributionModal(false);
          fetchRequest();
        }}
        handleCloseModal={() => setOpenDistributionModal(false)}
      />
    </Page>
  );
}

export default DistributionsRequestsDetails;
