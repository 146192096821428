import React from "react";
import {
  Grid,
  List,
} from "semantic-ui-react";
function ParticulierDetails({ souscripteur }) {
  return (
    <Grid stackable columns={3} style={{ marginTop: "5px" }}>
      <Grid.Column>
        <List>
          <List.Item>
            <List.Icon name="tag" />
            <List.Content>
              <List.Header as="a">Noms & Prénoms</List.Header>
              <List.Description>{`${souscripteur?.first_name} ${souscripteur?.last_name}`}</List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="tag" />
            <List.Content>
              <List.Header as="a">Téléphone</List.Header>
              <List.Description>
                {souscripteur?.phone || "Non défini"}
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="tag" />
            <List.Content>
              <List.Header as="a">Pièce officielle</List.Header>
              <List.Description>
                {souscripteur?.phone || "Non défini"}
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="tag" />
            <List.Content>
              <List.Header as="a">Pays</List.Header>
              <List.Description>
                {souscripteur?.phone || "Non défini"}
              </List.Description>
            </List.Content>
          </List.Item>
        </List>
      </Grid.Column>
      <Grid.Column>
        <List>
          <List.Item>
            <List.Icon name="tag" />
            <List.Content>
              <List.Header as="a">Adresse</List.Header>
              <List.Description>
                {souscripteur?.address || "Non défini"}
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="tag" />
            <List.Content>
              <List.Header as="a">Email</List.Header>
              <List.Description>
                {souscripteur?.email || "Non défini"}
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="tag" />
            <List.Content>
              <List.Header as="a">Numéro de la pièce</List.Header>
              <List.Description>
                {souscripteur?.email || "Non défini"}
              </List.Description>
            </List.Content>
          </List.Item>
        </List>
      </Grid.Column>
      <Grid.Column>
        <List>
          <List.Item>
            <List.Icon name="tag" />
            <List.Content>
              <List.Header as="a">Date de naissance</List.Header>
              <List.Description>
                {souscripteur?.rccm || "Non défini"}
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="tag" />
            <List.Content>
              <List.Header as="a">Lieu de naissance</List.Header>
              <List.Description>
                {souscripteur?.web || "Non défini"}
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="tag" />
            <List.Content>
              <List.Header as="a">Profession</List.Header>
              <List.Description>
                {souscripteur?.web || "Non défini"}
              </List.Description>
            </List.Content>
          </List.Item>
        </List>
      </Grid.Column>
    </Grid>
  );
}

export default ParticulierDetails;
