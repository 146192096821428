import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Label,
  List,
  Segment,
  Table,
} from "semantic-ui-react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Page from "../../components/Page";
import * as intermediairesService from "../../services/intermediairesService";
import IntermediaireUserFormModal from "../../components/modalForms/IntermediaireUserFormModal";
import { useRecoilValue } from "recoil";
import { authState } from "../../atoms/authState";
import { USERS_ROLE_ENUMS } from "../../constants";
import IntermediaireAgenceFormModal from "../../components/modalForms/IntermediaireAgenceFormModal";

function IntermediaireDetails() {
  const [intermediaire, setIntermediaire] = useState();
  const [openUserModal, setOpenUserModal] = useState(false);
  const [openIntermediaireAgencyModal, setOpenIntermediaireAgencyModal] =
    useState(false);
  const authValue = useRecoilValue(authState);
  const params = useParams();
  const navigate = useNavigate();

  const fetchIntermediaire = async () => {
    try {
      const response = await ([
        USERS_ROLE_ENUMS.AGENT_GENERAL_USER,
        USERS_ROLE_ENUMS.COURTIER_USER,
      ].includes(authValue?.user?.user_role)
        ? intermediairesService.fetchOwner()
        : intermediairesService.fetchOne(params?.uuid || ""));

      if (response?.status === 200) {
        setIntermediaire(response?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    fetchIntermediaire();
    return () => {};
  }, [params?.uuid]);

  return (
    <Page title="Intermédiaire détails">
      <GridRow className="IntermediaireDetails">
        <Header>Détails de l'intermédiaire</Header>
        <GridRow as={Segment}>
          <Label ribbon>Intermédiaire</Label>
          <Grid stackable columns={3} style={{ marginTop: "5px" }}>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Dénomination</List.Header>
                    <List.Description>
                      {intermediaire?.denomination}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Contacts</List.Header>
                    <List.Description>
                      {intermediaire?.phone_1 || "-"}/
                      {intermediaire?.phone_2 || "-"}
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Siège Social</List.Header>
                    <List.Description>
                      {intermediaire?.siege_social}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Email</List.Header>
                    <List.Description>
                      {intermediaire?.email || "Non défini"}
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">RCCM/</List.Header>
                    <List.Description>
                      {intermediaire?.rccm || "Non défini"}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Web/</List.Header>
                    <List.Description>
                      {intermediaire?.web || "Non défini"}
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid>
        </GridRow>
        <GridRow as={Segment}>
          <Grid stackable>
            <Grid.Column
              width={8}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Header>Liste des utilisateurs</Header>
            </Grid.Column>
            <Grid.Column
              width={8}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Input placeholder="Rechercher..." className="search">
                <input name="search" />
              </Input>

              <Button
                style={{ marginLeft: "10px" }}
                circular
                icon={<Icon name="add" />}
                onClick={() => setOpenUserModal(true)}
              />
            </Grid.Column>
          </Grid>

          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Nom</Table.HeaderCell>
                <Table.HeaderCell>Prénom</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Téléphones</Table.HeaderCell>
                <Table.HeaderCell>Admin</Table.HeaderCell>
                <Table.HeaderCell>Actif</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Actions </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {intermediaire?.users_intermediaires?.map(
                (user_intermediaire, index) => {
                  return (
                    <Table.Row key={`${user_intermediaire.idusers_compagnies}`}>
                      <Table.Cell>{index + 1}</Table.Cell>
                      <Table.Cell>
                        {user_intermediaire?.users?.last_name}
                      </Table.Cell>
                      <Table.Cell>
                        {user_intermediaire?.users?.first_name}
                      </Table.Cell>
                      <Table.Cell>
                        {user_intermediaire?.users?.email}
                      </Table.Cell>
                      <Table.Cell>
                        {user_intermediaire?.users?.phone}
                      </Table.Cell>
                      <Table.Cell>{`${
                        user_intermediaire?.is_admin === 1 ? "OUI" : "NON"
                      }`}</Table.Cell>
                      <Table.Cell>{`${
                        user_intermediaire?.users?.active === 1 ? "OUI " : "NON"
                      }`}</Table.Cell>
                      <Table.Cell textAlign="right">
                        <Dropdown
                          pointing="right"
                          icon={<Icon name="ellipsis vertical" />}
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item
                              icon={<Icon name="eye" />}
                              content="Détails"
                            />
                            <Dropdown.Item
                              icon={<Icon name="edit" />}
                              content="Editer"
                            />
                            <Dropdown.Item
                              icon={<Icon name="eye" />}
                              content="Supprimer"
                            />
                          </Dropdown.Menu>
                        </Dropdown>
                      </Table.Cell>
                    </Table.Row>
                  );
                }
              )}
            </Table.Body>
          </Table>
        </GridRow>
        <GridRow as={Segment} style={{ marginTop: "30px" }}>
          <Grid stackable>
            <Grid.Column
              width={8}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Header>Liste des Agences</Header>
            </Grid.Column>
            <Grid.Column
              width={8}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Input placeholder="Rechercher..." className="search">
                <input name="search" />
              </Input>
              {![
                USERS_ROLE_ENUMS.POOL_USER,
                USERS_ROLE_ENUMS.ROOT_ADMIN,
              ].includes(authValue?.user?.user_role) && (
                <Button
                  style={{ marginLeft: "10px" }}
                  circular
                  icon={<Icon name="add" />}
                  onClick={() => setOpenIntermediaireAgencyModal(true)}
                />
              )}
            </Grid.Column>
          </Grid>

          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Dénomination</Table.HeaderCell>
                <Table.HeaderCell>Téléphones</Table.HeaderCell>
                <Table.HeaderCell>Adresse</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {intermediaire?.intermediaires_agencies?.map((agency, index) => {
                return (
                  <Table.Row key={`${agency.intermediaires_agencies}`}>
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>{agency.denomination}</Table.Cell>
                    <Table.Cell>
                      {agency.phone_1}/{agency.phone_2}
                    </Table.Cell>
                    <Table.Cell>{agency.adress}</Table.Cell>
                    <Table.Cell textAlign="right">
                      <Dropdown
                        pointing="right"
                        icon={<Icon name="ellipsis vertical" />}
                      >
                        <Dropdown.Menu>
                          <Dropdown.Item
                            icon={<Icon name="eye" />}
                            content="Détails"
                            onClick={() =>
                              navigate(
                                `/intermediaire/agences/details/${agency.idintermediaires_agencies}`
                              )
                            }
                          />
                          <Dropdown.Item
                            icon={<Icon name="edit" />}
                            content="Editer"
                          />
                          <Dropdown.Item
                            icon={<Icon name="eye" />}
                            content="Supprimer"
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </GridRow>
      </GridRow>
      <IntermediaireUserFormModal
        open={openUserModal}
        setOpen={setOpenUserModal}
        intermediaire={intermediaire}
        callback={fetchIntermediaire}
        type_intermediaire={intermediaire?.type_intermediaire}
      />
      <IntermediaireAgenceFormModal
        open={openIntermediaireAgencyModal}
        setOpen={setOpenIntermediaireAgencyModal}
        callback={fetchIntermediaire}
      />
    </Page>
  );
}

export default IntermediaireDetails;
