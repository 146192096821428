import React, { useEffect, useState } from "react";
import Page from "../../../../components/Page";
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Button,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Menu,
  Message,
  Segment,
  Table,
} from "semantic-ui-react";
import * as companyOrdersService from "../../../../services/gestion_attestations/company/ordersService";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import CompanyAttestationCommandeForm from "../../../../components/modalForms/gestion_attestations/CompanyAttestationCommandeForm";

function CommandesList() {
  const [open, setOpen] = useState(false);
  const [commandes, setCommandes] = useState([]);
  const [selectedCommandeId, setSelectedCommandeId] = useState(null);
  const [pagination, setPagination] = useState({});

  const [selectedPage, setSelectedPage] = useState(1);

  const fetchCommandes = async () => {
    try {
      const { data } = await companyOrdersService.fetchAll({
        page: selectedPage,
      });
      setCommandes(data.orders || []);
      setPagination({
        totalPages: data.totalPages,
        nextPage: data.nextPage,
        prevPage: data.prevPage,
        currentPage: data.currentPage,
        total: data.total,
      });
    } catch (error) {
      toast.error(
        "Une erreur est survenue lors de la recuperation des commandes"
      );
    }
  };

  const handleUpdateClick = (commande) => {
    setSelectedCommandeId(commande.idattestations_orders);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedCommandeId(null);
    setOpen(false);
  };

  const handleDeleteClick = (commande) => {
    Swal.fire({
      title: "Etes-vous sur de vouloir supprimer cette commande ?",
      text: "Vous ne pourrez pas reprendre cette action !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        companyOrdersService
          .deleteOne(commande.idattestations_orders)
          .then(() => {
            toast.success("Commande d'attestation supprimée avec succes");
            fetchCommandes();
          })
          .catch((error) => {
            toast.error(
              "Une erreur est survenue lors de la suppression de la commande d'attestation"
            );
          });
      }
    });
  };

  useEffect(() => {
    fetchCommandes();
  }, [selectedPage]);

  return (
    <Page title="Liste des commandes d'attestation">
      <GridRow className="Souscriptions">
        <Grid.Row className="!mb-5">
          <Grid.Column width={16}>
            <Breadcrumb>
              <BreadcrumbSection link>Compagnie</BreadcrumbSection>
              <BreadcrumbDivider icon="right chevron" />
              <BreadcrumbSection link>
                Gestion des attestations
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection link as={Link} to="/company/stocks/commandes">
                Commandes
              </BreadcrumbSection>
            </Breadcrumb>
            <Divider />
          </Grid.Column>
        </Grid.Row>
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid>
              <Grid.Column>
                <Header>
                  Liste des Commandes d'attestation [{pagination.total || 0}]
                </Header>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input placeholder="Rechercher..." className="search !hidden">
              <input name="search" />
            </Input>
            <Button
              className="!ml-4"
              onClick={() => {
                setSelectedCommandeId(null);
                setOpen(true);
              }}
            >
              <Icon name="add" /> Ajouter
            </Button>
          </Grid.Column>
        </Grid>
        <>
          <Segment className="!min-h-auto">
            <Table striped celled sortable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell>
                    Qté <br></br> commandée
                  </Table.HeaderCell>
                  <Table.HeaderCell>Plage</Table.HeaderCell>
                  <Table.HeaderCell>
                    Qté reçue <br></br> par le Pool{" "}
                  </Table.HeaderCell>
                  {/* <Table.HeaderCell>Statut</Table.HeaderCell> */}
                  <Table.HeaderCell>Date de commande</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {commandes?.length > 0 ? (
                  commandes.map((commande, index) => (
                    <Table.Row
                      key={index}
                      className={`${
                        commande.attestations_stocks ? "" : "warning"
                      }`}
                    >
                      <Table.Cell>{index + 1}</Table.Cell>
                      <Table.Cell>
                        {commande.quantity &&
                          new Intl.NumberFormat("fr-FR").format(
                            commande.quantity
                          )}
                      </Table.Cell>
                      <Table.Cell>
                        {commande.attestations_stocks?.start_serial
                          ? `${commande.attestations_stocks?.start_serial} - ${commande.attestations_stocks?.end_serial}`
                          : "-- --"}
                      </Table.Cell>

                      <Table.Cell>
                        {commande.usage?.totalQty
                          ? new Intl.NumberFormat("fr-FR").format(
                              commande.usage?.totalQty
                            )
                          : "---"}
                      </Table.Cell>
                      <Table.Cell>
                        {dayjs(commande.created_at).format("DD/MM/YYYY HH:mm")}
                      </Table.Cell>
                      <Table.Cell width={1} textAlign="right">
                        <Dropdown
                          pointing="right"
                          icon={<Icon name="ellipsis vertical" />}
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item
                              as={Link}
                              icon={<Icon name="eye" />}
                              content="Détails"
                              to={`/company/stocks/commandes/details/${commande.uuid}`}
                            />
                            <Dropdown.Item
                              icon={<Icon name="edit" />}
                              content="Editer"
                              onClick={() => handleUpdateClick(commande)}
                              disabled={commande.attestations_stocks ? true : false}
                            />
                            <Dropdown.Item
                              icon={<Icon name="eye" />}
                              content="Supprimer"
                              onClick={() => handleDeleteClick(commande)}
                              disabled={commande.attestations_stocks ? true : false}
                            />
                          </Dropdown.Menu>
                        </Dropdown>
                      </Table.Cell>
                    </Table.Row>
                  ))
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan="7">
                      <Message
                        info
                        header="Aucune commande"
                        content="Aucune commande n'a été trouvé"
                      />
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
              {commandes.length > 0 && (
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell colSpan={7}>
                      <Menu floated="right" pagination>
                        <Menu.Item
                          as="a"
                          icon
                          onClick={() => setSelectedPage(pagination?.prevPage)}
                        >
                          <Icon name="chevron left" />
                        </Menu.Item>
                        <Menu.Item as="a">
                          {pagination?.currentPage} sur {pagination?.totalPages}
                        </Menu.Item>
                        <Menu.Item
                          as="a"
                          icon
                          onClick={() => setSelectedPage(pagination?.nextPage)}
                        >
                          <Icon name="chevron right" />
                        </Menu.Item>
                      </Menu>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              )}
            </Table>
          </Segment>
        </>
      </GridRow>

      <CompanyAttestationCommandeForm
        open={open}
        setOpen={setOpen}
        callback={fetchCommandes}
        selectedCommandeId={selectedCommandeId}
        handleCloseModal={handleCloseModal}
      />
    </Page>
  );
}

export default CommandesList;
