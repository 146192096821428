import React, { useEffect, useState } from "react";
import Page from "../../../../components/Page";
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Button,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Menu,
  Segment,
  Table,
} from "semantic-ui-react";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import * as poolRequestsService from "../../../../services/gestion_attestations/pool/requestsService";
import { fetchAll as fetchComagnies } from "../../../../services/comagniesService";
import { deleteOne as removeDelivery } from "../../../../services/gestion_attestations/pool/deliveriesService";

function RequestsList() {
  const [requests, setRequests] = useState([]);
  const [pagination, setPagination] = useState({});
  const [search, setSearch] = useState("");
  const [companies, setCompanies] = useState([]);
  const [selectedCompanyUuid, setSelectedCompanyUuid] = useState("DEFAULT");

  const [selectedPage, setSelectedPage] = useState(1);

  const fetchRequests = async () => {
    try {
      const { data } = await poolRequestsService.fetchAll({
        page: selectedPage,
        search,
        companyUuid:
          selectedCompanyUuid === "DEFAULT" ? null : selectedCompanyUuid,
      });
      setRequests(data.requests || []);
      setPagination({
        totalPages: data.totalPages,
        nextPage: data.nextPage,
        prevPage: data.prevPage,
        currentPage: data.currentPage,
        total: data.total,
      });
    } catch (error) {
      toast.error(
        "Une erreur est survenue lors de la recuperation des requests"
      );
    }
  };

  const handleDeleteClick = (request) => {
    Swal.fire({
      title: "Etes-vous sur de vouloir supprimer cette demande ?",
      text: "Vous ne pourrez pas reprendre cette action !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        removeDelivery(request?.attestations_deliveries?.uuid)
          .then(() => {
            toast.success("Livraison supprimée avec succes");
            fetchRequests();
          })
          .catch((error) => {
            toast.error(
              "Une erreur est survenue lors de la suppression de la livraison d'attestation"
            );
          });
      }
    });
  };

  const fetchAllCompanies = async () => {
    try {
      const { data } = await fetchComagnies();
      setCompanies(data || []);
    } catch (error) {
      toast.error(
        "Une erreur est survenue lors de la recuperation des compagnies"
      );
    }
  };

  useEffect(() => {
    fetchRequests();
  }, [selectedPage, search, selectedCompanyUuid]);

  useEffect(() => {
    fetchAllCompanies();
  }, []);

  return (
    <Page title="Liste des requests d'attestation">
      <GridRow className="Souscriptions">
        <Grid.Row className="!mb-5">
          <Grid.Column width={16}>
            <Breadcrumb>
              <BreadcrumbSection link>Compagnie</BreadcrumbSection>
              <BreadcrumbDivider icon="right chevron" />
              <BreadcrumbSection link>
                Gestion des attestations
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection link as={Link} to="/company/stocks/demandes">
                Demandes d'attestions
              </BreadcrumbSection>
            </Breadcrumb>
            <Divider />
          </Grid.Column>
        </Grid.Row>
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid>
              <Grid.Column>
                <Header>
                  Liste des demandes d'attestation [{pagination.total || 0}]
                </Header>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              gap: "10px",
            }}
          >
            <Input placeholder="Rechercher..." className="search">
              <input
                name="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Input>
            <Dropdown
              placeholder="Selectionner une compagnie"
              search
              selection
              options={[]
                .concat(
                  companies.map((company) => ({
                    key: company.uuid,
                    value: company.uuid,
                    text: company.denomination,
                  }))
                )
                .concat([
                  {
                    key: "DEFAULT",
                    text: "Toutes les compagnies",
                    value: "DEFAULT",
                  },
                ])}
              onChange={(e, { value }) => setSelectedCompanyUuid(value)}
            />
          </Grid.Column>
        </Grid>
        <>
          <Segment className="!min-h-auto">
            <Table striped celled sortable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell>Compagnie</Table.HeaderCell>
                  <Table.HeaderCell>Quantité</Table.HeaderCell>
                  <Table.HeaderCell>Plage</Table.HeaderCell>
                  <Table.HeaderCell>Date de commande</Table.HeaderCell>
                  <Table.HeaderCell>Qté envoyée</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {requests.map((request, index) => (
                  <Table.Row
                    key={index}
                    className={`${
                      request.attestations_deliveries ? "" : "warning"
                    }`}
                  >
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>
                      {
                        request.attestations_stocks?.attestations_orders
                          ?.compagnies?.denomination
                      }
                    </Table.Cell>
                    <Table.Cell>
                      {new Intl.NumberFormat("fr-FR").format(request.quantity)}
                    </Table.Cell>
                    <Table.Cell>
                      {request.attestations_stocks?.start_serial
                        ? `${request.attestations_stocks?.start_serial} - ${request.attestations_stocks?.end_serial}`
                        : "-- --"}
                    </Table.Cell>

                    <Table.Cell>
                      {dayjs(request.created_at).format("DD/MM/YYYY HH:mm")}
                    </Table.Cell>
                    <Table.Cell>
                      {request?.attestations_deliveries
                        ? new Intl.NumberFormat("fr-FR").format(
                            parseInt(
                              request?.attestations_deliveries?.end_serial
                            ) -
                              parseInt(
                                request?.attestations_deliveries?.start_serial
                              ) +
                              1
                          )
                        : "--"}
                    </Table.Cell>
                    <Table.Cell>
                      {request?.attestations_deliveries
                        ? "Livrée"
                        : "En attente"}
                    </Table.Cell>

                    <Table.Cell width={1} textAlign="right">
                      <Dropdown
                        pointing="right"
                        icon={<Icon name="ellipsis vertical" />}
                      >
                        <Dropdown.Menu>
                          <Dropdown.Item
                            as={Link}
                            icon={<Icon name="eye" />}
                            content="Détails"
                            to={`/pool/stocks/demandes/details/${request.uuid}`}
                          />
                          {request?.attestations_deliveries && (
                            <Dropdown.Item
                              icon={<Icon name="trash" />}
                              content="Retirer la livraison"
                              onClick={() => handleDeleteClick(request)}
                            />
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
              {requests.length > 0 && (
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell colSpan="9">
                      <Menu floated="right" pagination>
                        <Menu.Item
                          as="a"
                          icon
                          onClick={() => setSelectedPage(pagination?.prevPage)}
                        >
                          <Icon name="chevron left" />
                        </Menu.Item>
                        <Menu.Item as="a">
                          {pagination?.currentPage} sur {pagination?.totalPages}
                        </Menu.Item>
                        <Menu.Item
                          as="a"
                          icon
                          onClick={() => setSelectedPage(pagination?.nextPage)}
                        >
                          <Icon name="chevron right" />
                        </Menu.Item>
                      </Menu>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              )}
            </Table>
          </Segment>
        </>
      </GridRow>
    </Page>
  );
}

export default RequestsList;
