import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Accordion, Icon, Menu } from "semantic-ui-react";

function IntermediaireMenus() {
  const [activeIndex, setActiveIndex] = useState("dashboard");

  const handleItemClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? "" : index;
    setActiveIndex(newIndex);
  };
  return (
    <Accordion
      as={Menu}
      fluid
      vertical={true}
      style={{ borderRadius: 0, border: "none" }}
    >
      <Menu.Item
        as={Link}
        to="intermediaire/dashboard"
        active={activeIndex === "dashboard"}
        index={"dashboard"}
        onClick={handleItemClick}
      >
        <span>
          <Icon name="dashboard" /> Dashboard
        </span>
      </Menu.Item>
      <Menu.Item
        as={Link}
        to="intermediaire/souscripteurs"
        active={activeIndex === "souscripteurs"}
        index={"souscripteurs"}
        onClick={handleItemClick}
      >
        <span>
          <Icon name="users" /> Souscripteurs
        </span>
      </Menu.Item>
      <Menu.Item>
        <Accordion.Title
          active={activeIndex === "production"}
          onClick={handleItemClick}
          index="production"
        >
          <Icon name="bus" /> Production
          <i className="right dropdown icon"></i>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === "production"}>
          <Menu.Menu>
            <Menu.Item as={Link} to="intermediaire/souscriptions">
              Souscriptions
            </Menu.Item>
            <Menu.Item as="a">Déclaration de sinistre</Menu.Item>
          </Menu.Menu>
        </Accordion.Content>
      </Menu.Item>

      <Menu.Item>
        <Accordion.Title
          index="etats"
          active={activeIndex === "etats"}
          onClick={handleItemClick}
        >
          <Icon name="file alternate" /> Etats
          <i className="right dropdown icon"></i>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === "etats"}>
          <Menu.Menu>
            <Menu.Item as="a">Bordereaux de production du mois</Menu.Item>
            <Menu.Item as="a">Bordereaux des sinistres réglés</Menu.Item>
            <Menu.Item as="a">Bordereaux des avis de sinistre</Menu.Item>
          </Menu.Menu>
        </Accordion.Content>
      </Menu.Item>

      <Menu.Item>
        <Accordion.Title
          index="stocks"
          active={activeIndex === "stocks"}
          onClick={handleItemClick}
        >
          <Icon name="box" /> Stocks
          <i className="right dropdown icon"></i>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === "stocks"}>
          <Menu.Menu>
            <Menu.Item as={Link} to="intermediaire/stocks/demandes">
              Demandes d'attestions
            </Menu.Item>
          </Menu.Menu>
        </Accordion.Content>
      </Menu.Item>
      <Menu.Item>
        <Accordion.Title
          index="stocks/reseaux"
          active={activeIndex === "stocks/reseaux"}
          onClick={handleItemClick}
        >
          <Icon name="box" /> Stocks Reseaux
          <i className="right dropdown icon"></i>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === "stocks/reseaux"}>
          <Menu.Menu>
            <Menu.Item
              as={Link}
              to="intermediaire/stocks/distributions-requests"
            >
              Demandes d'attestions
            </Menu.Item>
          </Menu.Menu>
        </Accordion.Content>
      </Menu.Item>
      <Menu.Item>
        <Accordion.Title
          index="gestion"
          onClick={handleItemClick}
          active={activeIndex === "gestion"}
        >
          <Icon name="car crash" /> Gestion des sinistres
          <i className="right dropdown icon"></i>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === "gestion"}>
          <Menu.Menu>
            <Menu.Item as="a">Déclaration d'un sinistre</Menu.Item>
            <Menu.Item as="a">Estimation</Menu.Item>
            <Menu.Item as="a">Règlement</Menu.Item>
          </Menu.Menu>
        </Accordion.Content>
      </Menu.Item>

      <Menu.Item>
        <Accordion.Title
          index="cession"
          active={activeIndex === "cession"}
          onClick={handleItemClick}
        >
          <Icon name="expand alternate" /> Cessions & Rétrocessions
          <i className="right dropdown icon"></i>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === "cession"}>
          <Menu.Menu>
            <Menu.Item as="a">Cessions</Menu.Item>
            <Menu.Item as="a">Rétrocessions</Menu.Item>
          </Menu.Menu>
        </Accordion.Content>
      </Menu.Item>

      <Menu.Item
        as={Link}
        to="intermediaire/details"
        active={activeIndex === "monEntreprise"}
        index={"details"}
        onClick={handleItemClick}
      >
        <span>
          <Icon name="building" /> Mon Entreprise
        </span>
      </Menu.Item>
      <Menu.Item
        as={Link}
        to="intermediaire/partenaires"
        active={activeIndex === "partenaires"}
        index={"partenaires"}
        onClick={handleItemClick}
      >
        <span>
          <Icon name="share alternate" /> Mes partenaires
        </span>
      </Menu.Item>
    </Accordion>
  );
}

export default IntermediaireMenus;
