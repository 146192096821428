import { api } from "../axios/api";

export const findAll = async () => {
  return api.get(`souscriptions`);
};

export const fetchOne = async (id) => {
  return api.get(`souscriptions/${id}`);
};
export const create = async (data) => {
  return api.post(`souscriptions`, data);
};
