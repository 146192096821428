import React from "react";
import { Routes, Route } from "react-router-dom";
import AssuresDetails from "../pages/assures/AssuresDetails";
import AssuresList from "../pages/assures/AssuresList";
import CompagniesList from "../pages/comagnies/CompagniesList";
import CompanyDetails from "../pages/comagnies/CompanyDetails";
import Dashboard from "../pages/dashboard/Dashboard";
import SouscripteurDetails from "../pages/souscripteurs/SouscripteurDetails";
import SouscripteursList from "../pages/souscripteurs/SouscripteursList";
import UsersList from "../pages/users/UsersList";
import SouscriptionMonoList from "../pages/souscriptions/SouscriptionMonoList";
import RequestsList from "../pages/module_gestion_attestation/intermediaire_agency/requests/RequestsList";
import RequestDetails from "../pages/module_gestion_attestation/intermediaire_agency/requests/RequestsDetails";

function IntermediaireAgencyRoutes() {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<Dashboard />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="details" element={<CompanyDetails />} />
        <Route path="administration/compagnies/*">
          <Route index element={<CompagniesList />} />
          <Route path="details/:uuid" element={<CompanyDetails />} />
        </Route>
        <Route path="administration/users/*">
          <Route index element={<UsersList />} />
          <Route path="details/:uuid" element={<CompanyDetails />} />
        </Route>
        <Route path="assures/*">
          <Route index element={<AssuresList />} />
          <Route path="details/:id" element={<AssuresDetails />} />
        </Route>
        <Route path="souscripteurs/*">
          <Route index element={<SouscripteursList />} />
          <Route path="details/:uuid" element={<SouscripteurDetails />} />
        </Route>
        <Route path="souscriptions/*">
          <Route path="mono" element={<SouscriptionMonoList />} />
          <Route path="details/:id" element={<AssuresDetails />} />
        </Route>
        <Route path="agences/*">
          <Route path="mono" element={<SouscriptionMonoList />} />
          <Route path="details/:id" element={<AssuresDetails />} />
        </Route>
        <Route path="stocks/*">
          <Route path="demandes/*">
            <Route index element={<RequestsList />} />
            <Route path="details/:uuid" element={<RequestDetails />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default IntermediaireAgencyRoutes;
