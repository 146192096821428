import { api } from "../../axios/api";

export const getAll = async (uuid) => {
  return api.get(
    `/gestion-production/directives-tarifaires-values/${uuid}/directives-tarifaires`
  );
};

export const create = async (data) => {
  return api.post("/gestion-production/directives-tarifaires-values", data);
};

export const update = async (uuid, data) => {
  return api.put(
    "/gestion-production/directives-tarifaires-values/" + uuid,
    data
  );
};

export const fetchOne = async (uuid) => {
  return api.get("/gestion-production/directives-tarifaires-values/" + uuid);
};

export const getByActiveDirectivesTarifaires = async () => {
  return api.get(
    `/gestion-production/directives-tarifaires-values/active/directives-tarifaires`
  );
};
