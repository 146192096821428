import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Label,
  Segment,
  Table,
} from "semantic-ui-react";
import { useParams } from "react-router-dom";
import Page from "../../components/Page";
import * as soucripteursService from "../../services/souscripteursService";
import ParticulierDetails from "../../components/souscripteurs/ParticulierDetails";

function SouscripteurDetails() {
  const [souscripteur, setSouscripteur] = useState();
  const params = useParams();
  const fetchSouscripteur = async () => {
    try {
      const response = await soucripteursService.fetchOne(params?.uuid);

      if (response?.status === 200) {
        setSouscripteur(response?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (params?.uuid) {
      fetchSouscripteur();
    }
    return () => {};
  }, [params?.uuid]);
  return (
    <Page title="Détails du souscripteur">
      <GridRow className="SouscripteurDetails">
        <Header>Détails du souscripteur</Header>
        <GridRow as={Segment}>
          <Label ribbon>{souscripteur?.groupe_souscripteur}</Label>
          {souscripteur?.groupe_souscripteur === "PARTICULIER" ? (
            <ParticulierDetails souscripteur={souscripteur} />
          ) : null}
        </GridRow>
        <GridRow as={Segment}>
          <Grid stackable>
            <Grid.Column
              width={8}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Header>Contrats</Header>
            </Grid.Column>
            <Grid.Column
              width={8}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Input placeholder="Rechercher..." className="search">
                <input name="search" />
              </Input>
              <Button
                style={{ marginLeft: "10px" }}
                circular
                icon={<Icon name="add" />}
              />
            </Grid.Column>
          </Grid>

          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Nom</Table.HeaderCell>
                <Table.HeaderCell>Prénom</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Téléphones</Table.HeaderCell>
                <Table.HeaderCell>Admin</Table.HeaderCell>
                <Table.HeaderCell>Actif</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {/*  {company?.users_compagnies?.map((user_company, index) => {
                return (
                  <Table.Row key={`${user_company.idusers_compagnies}`}>
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>{user_company?.users?.last_name}</Table.Cell>
                    <Table.Cell>{user_company?.users?.first_name}</Table.Cell>
                    <Table.Cell>{user_company?.users?.email}</Table.Cell>
                    <Table.Cell>{user_company?.users?.phone}</Table.Cell>
                    <Table.Cell>{`${
                      user_company?.is_admin === 1 ? "OUI" : "NON"
                    }`}</Table.Cell>
                    <Table.Cell>{`${
                      user_company?.users?.active === 1 ? "OUI" : "NON"
                    }`}</Table.Cell>
                    <Table.Cell textAlign="right">
                      <Dropdown
                        pointing="right"
                        icon={<Icon name="ellipsis vertical" />}
                      >
                        <Dropdown.Menu>
                          <Dropdown.Item
                            icon={<Icon name="eye" />}
                            content="Détails"
                          />
                          <Dropdown.Item
                            icon={<Icon name="edit" />}
                            content="Editer"
                          />
                          <Dropdown.Item
                            icon={<Icon name="eye" />}
                            content="Supprimer"
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                );
              })} */}
            </Table.Body>
          </Table>
        </GridRow>
      </GridRow>
    </Page>
  );
}

export default SouscripteurDetails;
