import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LoginPage from "./pages/auth/LoginPage";

import "react-toastify/dist/ReactToastify.min.css";
import DashboardLayout from "./components/DashboardLayout";
import Dashboard from "./pages/dashboard/Dashboard";
import PoolRoutes from "./Routes/PoolRoutes";
import { useRecoilState } from "recoil";
import { authState } from "./atoms/authState";
import ProtectedRoute from "./components/ProtectedRoute";
import { getUserFromStorage } from "./utils/auth";
import CompanyRoutes from "./Routes/CompanyRoutes";
import IntermediaireRoutes from "./Routes/IntermediaireRoutes";
import AgencyRoutes from "./Routes/AgencyRoutes";
import IntermediaireAgencyRoutes from "./Routes/IntermediaireAgencyRoutes";

function App() {
  const [authValue, setAuthValue] = useRecoilState(authState);

  useEffect(() => {
    let userData = getUserFromStorage();
    if (!userData) {
      setAuthValue(null);
    }
    setAuthValue(userData);
    return () => {};
  }, [setAuthValue]);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute authValue={authValue}>
                <DashboardLayout />
              </ProtectedRoute>
            }
          >
            <Route path="pool/*" element={<PoolRoutes />} />
            <Route path="company/*" element={<CompanyRoutes />} />
            <Route path="agency/*" element={<AgencyRoutes />} />
            <Route path="intermediaire/*" element={<IntermediaireRoutes />} />
            <Route
              path="intermediaire-agency/*"
              element={<IntermediaireAgencyRoutes />}
            />
          </Route>
          <Route path="connexion" element={<LoginPage />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
