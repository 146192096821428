import { api } from "../axios/api";

export const fetchAll = async () => {
  return api.get(`pieces-officielles`);
};
export const fetchOne = async (id) => {
  return api.get(`pieces-officielles/${id}`);
};
export const create = async (data) => {
  return api.post(`pieces-officielles`, data);
};
