export const DIRECTIVE_TARIFAIRE_FIELD_TYPES = [
  {
    key: "amount",
    text: "Montant",
    value: "amount",
  },
  {
    key: "percent",
    text: "Pourcentage",
    value: "percent",
  },
];

export const DIRECTIVE_TARIFAIRE_FIELDS = [
  {
    key: "prime_nette_individuelle_conducteur",
    text: "Prime nette individuelle conducteur",
  },
  {
    key: "frais_gestion_pool",
    text: "Frais de gestion du pool",
  },
  {
    key: "cout_police",
    text: "Cout de police",
  },
  {
    key: "taxe_contrat_assurance",
    text: "Taxe sur le contrat d'assurance",
  },
  {
    key: "carte_rose",
    text: "Carte rose",
  },
  {
    key: "droit_de_timbre_automobile",
    text: "Droit de timbre automobile",
  },
  {
    key: "tarif_bris_glaces",
    text: "Tarif de bris glaces",
  },
  {
    key: "taux_max_commission",
    text: "Taux maximum de commission",
  },
];

export const COMPANY_DIRECTIVE_TARIFAIRE_FIELDS = [
  {
    key: "taux_prime_dommage",
    text: "Taux de prime dommage",
  },
];

export const GARANTIES_VOL_ENUMS = {
  VOL: "Vol",
  VOL_A_MAIN_ARMEE: "Vol à main armée",
  VOL_SIMPLE: "Vol simple",
};

export const GARANTIES_INCENDIE_ENUMS = {
  INCENDIE: "Incendie",
};

export const BRIS_DE_GLACES_ENUMS = {
  BRIS_DE_GLACES: "Bris de glaces",
};
export const ASSISTANCE_AUTO_ENUMS = {
  ASSISTANCE_AUTO: "Assistance automobile",
};

export const GARANTIES_DOMMAGE_VEHICULE_ENUMS = {
  GARANTIES_DOMMAGE_VEHICULE: "Garantie dommage au véhicule",
  FRANCHISE_DOMMAGE_VEHICULE: "Franchise",
};

export const RESPONSABILITE_CIVILE = "Responsabilité civile (RC)";
export const INDIVIDUELLE_ACCIDENT_CONDUCTEUR =
  "Individuelle accident conducteur (IAC)";
