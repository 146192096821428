import React, { useState, useEffect } from "react";
import { Button, Modal } from "semantic-ui-react";
import {
  PDFViewer,
  Page as PageView,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import currency from "currency.js";
import * as policeServices from "../../services/gestion_production/policesService";

function ConditionsParticulieresPreviewModal({
  open = false,
  setOpen,
  idpolices = null,
}) {
  const [loading, setLoading] = useState(false);
  const [police, setPolice] = useState(false);

  const fetchPolice = async () => {
    try {
      const response = await policeServices.findOne(idpolices);

      if (response?.status === 200) {
        setPolice(response?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    /*  if (params?.uuid) {
     
    } */ /* fetchPolice(); */
    return () => {};
  }, [idpolices]);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      closeIcon
      size="fullscreen"
      onMount={() => {
        if (idpolices) {
          fetchPolice();
        }
      }}
    >
      <Modal.Header style={{ fontSize: '12px' }}>Preview</Modal.Header>
      <Modal.Content style={{ padding: "0px" }}>
        <Modal.Description>
          <PDFViewer style={{ width: "100%", height: "100vh" }}>
            <Document>
              {police && (
                <PageView size="A4" style={styles.page}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 5
                    }}
                  >
                    <View style={{ width: "30%"}}>
                      <Image
                        src={require("../../assets/images/nsia.png")}
                        style={{ width: 100, height: 50 }}
                      />
                    </View>
                    <View style={{ width: "70%"}}>
                      <Text style={{ textAlign: "center", fontSize: 26, textTransform: "uppercase" }}>
                        Conditions particulières
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View style={{ width: "50%", padding: 3 }}>
                      <View style={styles.sectionItem}>
                        <View>
                          <Text style={{ fontWeight: "bold" }}>
                            Date de souscription
                          </Text>
                        </View>
                        <View>
                          <Text>
                            {dayjs(police?.avenants[0]?.created_at).format(
                              "DD-MM-YYYY"
                            )}
                          </Text>
                        </View>
                      </View>
                      <View style={styles.sectionItem}>
                        <View>
                          <Text style={{ fontWeight: "bold" }}>
                            Date d'éffet
                          </Text>
                        </View>
                        <View>
                          <Text>
                            {dayjs(police?.avenants[0]?.date_effet).format(
                              "DD-MM-YYYY"
                            )}
                          </Text>
                        </View>
                      </View>
                      <View style={styles.sectionItem}>
                        <View>
                          <Text style={{ fontWeight: 1000 }}>
                            Heure d'effet
                          </Text>
                        </View>
                        <View>
                          <Text>
                            {dayjs(police?.avenants[0]?.date_effet).format(
                              "HH:mm"
                            )}
                          </Text>
                        </View>
                      </View>
                      <View style={styles.sectionItem}>
                        <View>
                          <Text style={{ fontWeight: "bold" }}>
                            Date d'échéance
                          </Text>
                        </View>
                        <View>
                          <Text>
                            {dayjs(police?.avenants[0]?.date_effet)
                              .add(
                                Number(
                                  police?.avenants[0]?.renouvellement.slice(-2)
                                ),
                                "months"
                              )
                              .add(-1, "day")
                              .format("DD-MM-YYYY")}
                          </Text>
                        </View>
                      </View>
                      <View style={styles.sectionItem}>
                        <View>
                          <Text style={{ fontWeight: "bold" }}>
                            Heure d'échéance
                          </Text>
                        </View>
                        <View>
                          <Text>
                            {dayjs(police?.avenants[0]?.date_effet)
                              .add(
                                Number(
                                  police?.avenants[0]?.renouvellement.slice(-2)
                                ),
                                "months"
                              )
                              .add(-1, "day")
                              .format("HH:mm")}
                          </Text>
                        </View>
                      </View>
                      <View style={styles.sectionItem}>
                        <View>
                          <Text style={{ fontWeight: "bold" }}>
                            Durée totale du contrat
                          </Text>
                        </View>
                        <View>
                          <Text>
                            {dayjs(police?.avenants[0]?.date_effet)
                              .add(
                                Number(
                                  police?.avenants[0]?.renouvellement.slice(-2)
                                ),
                                "months"
                              )
                              .add(-1, "day")
                              .diff(
                                police?.avenants[0]?.date_effet,
                                "days"
                              )}{" "}
                            jours
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View style={{ width: "50%", padding: 3 }}>
                      <View style={styles.sectionItem}>
                        <View>
                          <Text style={{ fontWeight: "bold" }}>Branche</Text>
                        </View>
                        <View>
                          <Text>
                            {
                              police?.souscriptions?.categories_contrats
                                ?.libelle
                            }
                          </Text>
                        </View>
                      </View>
                      <View style={styles.sectionItem}>
                        <View>
                          <Text style={{ fontWeight: "bold" }}>
                            Numéro de police
                          </Text>
                        </View>
                        <View>
                          <Text>{`TPV-${police?.num_police || "N/A"}`}</Text>
                        </View>
                      </View>
                      <View style={styles.sectionItem}>
                        <View>
                          <Text style={{ fontWeight: "bold" }}>
                            Statut du document
                          </Text>
                        </View>
                        <View>
                          <Text>
                            {police?.avenants[police?.avenants?.length - 1]
                              ?.facturation?.payments
                              ? "Contrat Validé"
                              : "Contrat Non Validé"}
                          </Text>
                        </View>
                      </View>
                      <View style={styles.sectionItem}>
                        <View>
                          <Text style={{ fontWeight: "bold" }}>
                            Bureau de souscription
                          </Text>
                        </View>
                        <View>
                          <Text>
                            {police?.souscriptions?.compagnies?.denomination}
                          </Text>
                        </View>
                      </View>
                      <View style={styles.sectionItem}>
                        <View>
                          <Text style={{ fontWeight: "bold" }}>
                            Intermédiaire
                          </Text>
                        </View>
                        <View>
                          <Text>
                            {police?.souscriptions?.compagnies?.denomination}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View style={{ width: "50%", padding: 3 }}>
                      <View
                        style={{
                          padding: 8,
                          backgroundColor: "#c0c0c0",
                          marginBottom: 10,
                        }}
                      >
                        <Text
                          style={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >{`Souscripteur : ${police?.souscriptions?.souscripteurs_souscriptions_idsouscripteursTosouscripteurs?.last_name} ${police?.souscriptions?.souscripteurs_souscriptions_idsouscripteursTosouscripteurs?.first_name}`}</Text>
                      </View>
                      <View style={styles.sectionItem}>
                        <View>
                          <Text style={{ fontWeight: "bold" }}>Tél:</Text>
                        </View>
                        <View>
                          <Text>
                            {
                              police?.souscriptions
                                ?.souscripteurs_souscriptions_idsouscripteursTosouscripteurs
                                ?.phone
                            }
                          </Text>
                        </View>
                      </View>
                      <View style={styles.sectionItem}>
                        <View>
                          <Text style={{ fontWeight: "bold" }}>E-mail</Text>
                        </View>
                        <View>
                          <Text>
                            {
                              police?.souscriptions
                                ?.souscripteurs_souscriptions_idsouscripteursTosouscripteurs
                                ?.email
                            }
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View style={{ width: "50%", padding: 3 }}>
                      <View
                        style={{
                          padding: 8,
                          backgroundColor: "#c0c0c0",
                          marginBottom: 10,
                        }}
                      >
                        <Text
                          style={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >{`Assuré : ${police?.avenants[0]?.assures?.denomination}`}</Text>
                      </View>
                      <View style={styles.sectionItem}>
                        <View>
                          <Text style={{ fontWeight: "bold" }}>Profession</Text>
                        </View>
                        <View>
                          <Text></Text>
                        </View>
                      </View>
                      <View style={styles.sectionItem}>
                        <View>
                          <Text style={{ fontWeight: "bold" }}>Adresse</Text>
                        </View>
                        <View>
                          <Text>{police?.avenants[0]?.assures?.address}</Text>
                        </View>
                      </View>
                    </View>
                  </View>

                  <View
                    style={{
                      paddingBottom: 5,
                      borderBottom: 1,
                      borderBottomColor: "black",
                      marginBottom: 4,
                    }}
                  >
                    <View
                      style={{
                        paddingBottom: 5,
                        borderBottom: 1,
                        borderBottomColor: "black",
                        marginBottom: 4,
                        marginTop: 10,
                      }}
                    >
                      <Text style={{ textAlign: "center", fontSize: 14 }}>
                        Conditions
                      </Text>
                    </View>
                    <View style={styles.sectionItem}>
                      <View>
                        <Text style={{ fontWeight: "bold" }}>
                          Condition de reconduction
                        </Text>
                      </View>
                      <View>
                        <Text></Text>
                      </View>
                    </View>
                    <View style={styles.sectionItem}>
                      <View>
                        <Text style={{ fontWeight: "bold" }}>
                          Condition de cessation du contrat
                        </Text>
                      </View>
                      <View>
                        <Text>Aliénation de la chose assurée</Text>
                      </View>
                    </View>
                    <View style={styles.sectionItem}>
                      <View>
                        <Text style={{ fontWeight: "bold" }}>
                          Condition de résiliation
                        </Text>
                      </View>
                      <View>
                        <Text>Au moins deux mois avant échéance</Text>
                      </View>
                    </View>
                    <View style={styles.sectionItem}>
                      <View>
                        <Text style={{ fontWeight: "bold" }}>
                          Condition de prorogation
                        </Text>
                      </View>
                      <View>
                        <Text>
                          En cas de suspension de garanties d'au moins quatres
                          semaines.
                        </Text>
                      </View>
                    </View>
                  </View>

                  <View>
                    <View
                      style={{
                        padding: 8,
                        backgroundColor: "#c0c0c0",
                        marginBottom: 10,
                      }}
                    >
                      <Text
                        style={{
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          textAlign: "center",
                        }}
                      >
                        Informations Vehicule & tarification
                      </Text>
                    </View>
                    <View
                      style={{
                        justifyContent: "space-between",
                        flexDirection: "row",
                      }}
                    >
                      <View style={[styles.sectionItem, { paddingRight: 10 }]}>
                        <View>
                          <Text style={{ fontWeight: "bold" }}>
                            Immatriculation
                          </Text>
                        </View>
                        <View>
                          <Text>
                            {police?.avenants[0]?.vehicules.immatriculation}
                          </Text>
                        </View>
                      </View>
                      <View style={[styles.sectionItem, { paddingRight: 10 }]}>
                        <View>
                          <Text style={{ fontWeight: "bold" }}>Marque</Text>
                        </View>
                        <View>
                          <Text>{police?.avenants[0]?.vehicules.marque}</Text>
                        </View>
                      </View>
                      <View style={[styles.sectionItem, { paddingRight: 10 }]}>
                        <View>
                          <Text style={{ fontWeight: "bold" }}>N° Série</Text>
                        </View>
                        <View>
                          <Text>
                            {police?.avenants[0]?.vehicules.numero_chassis}
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        justifyContent: "space-between",
                        flexDirection: "row",
                      }}
                    >
                      <View style={[styles.sectionItem, { paddingRight: 10 }]}>
                        <View>
                          <Text style={{ fontWeight: "bold" }}>
                            Immat. Précedente
                          </Text>
                        </View>
                        <View>
                          <Text>
                            {
                              police?.avenants[0]?.vehicules
                                .immatriculation_precedente
                            }
                          </Text>
                        </View>
                      </View>
                      <View style={[styles.sectionItem, { paddingRight: 10 }]}>
                        <View>
                          <Text style={{ fontWeight: "bold" }}>Type</Text>
                        </View>
                        <View>
                          <Text></Text>
                        </View>
                      </View>
                      <View style={[styles.sectionItem, { paddingRight: 10 }]}>
                        <View>
                          <Text style={{ fontWeight: "bold" }}>
                            Mise en circulation
                          </Text>
                        </View>
                        <View>
                          <Text>
                            {dayjs(
                              police?.avenants[0]?.vehicules
                                .date_mise_circulation
                            ).format("DD-MM-YYYY")}
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        justifyContent: "space-between",
                        flexDirection: "row",
                      }}
                    >
                      <View style={[styles.sectionItem, { paddingRight: 10 }]}>
                        <View>
                          <Text style={{ fontWeight: "bold" }}>
                            Places assises
                          </Text>
                        </View>
                        <View>
                          <Text>
                            {police?.avenants[0]?.vehicules?.nombre_places}
                          </Text>
                        </View>
                      </View>
                      <View style={[styles.sectionItem, { paddingRight: 10 }]}>
                        <View>
                          <Text style={{ fontWeight: "bold" }}>
                            Zone de circ.
                          </Text>
                        </View>
                        <View>
                          <Text>
                            {" : "}
                            {
                              police?.avenants[0]?.vehicules?.zones_circulation
                                ?.libelle
                            }
                          </Text>
                        </View>
                      </View>
                      <View style={[styles.sectionItem, { paddingRight: 10 }]}>
                        <View>
                          <Text style={{ fontWeight: "bold" }}>
                            Source Energie
                          </Text>
                        </View>
                        <View>
                          <Text>
                            {
                              police?.avenants[0]?.vehicules?.sources_energie
                                ?.libelle
                            }
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        justifyContent: "space-between",
                        flexDirection: "row",
                      }}
                    >
                      <View style={[styles.sectionItem, { paddingRight: 10 }]}>
                        <View>
                          <Text style={{ fontWeight: "bold" }}>
                            Valeur à neuf
                          </Text>
                        </View>
                        <View>
                          <Text>
                            {currency(
                              police?.avenants[0]?.vehicules?.valeur_a_neuf
                            ).format({
                              style: "currency",
                              currency: " ",
                              symbol: " ",
                            })}
                          </Text>
                        </View>
                      </View>
                      <View style={[styles.sectionItem, { paddingRight: 10 }]}>
                        <View>
                          <Text style={{ fontWeight: "bold" }}>
                            Valeur Vénale
                          </Text>
                        </View>
                        <View>
                          <Text>
                            {currency(
                              police?.avenants[0]?.vehicules?.valeur_venale
                            ).format({
                              style: "currency",
                              currency: " ",
                              symbol: " ",
                            })}
                          </Text>
                        </View>
                      </View>
                      <View style={[styles.sectionItem, { paddingRight: 10 }]}>
                        <View>
                          <Text style={{ fontWeight: "bold" }}>N° Attest.</Text>
                        </View>
                        <View>
                          <Text>
                            {police?.avenants[0]?.vehicules?.numero_attestation}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>

                  <View style={{ borderBottom:1, paddingBottom: 4 }}>
                    <View
                      style={{
                        padding: 8,
                        backgroundColor: "#c0c0c0",
                        marginBottom: 10,
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 10,
                        borderWidth: 1
                      }}
                    >
                      <View style={{ width: "60%" }}>
                        <Text>Liste des garanties souscrites</Text>
                      </View>
                      <View style={{ width: "20%" }}>
                        <Text>Capitaux</Text>
                      </View>
                      <View style={{ width: "30%" }}>
                        <View>
                          <Text
                            style={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            Franchise
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                          }}
                        >
                          <View>
                            <Text
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            >
                              Taux
                            </Text>
                          </View>
                          <View>
                            <Text
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            >
                              Min.
                            </Text>
                          </View>
                          <View>
                            <Text
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            >
                              Max.
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <View style={{ width: "60%" }}>
                        <Text>Recours tiers incendie (RTI)</Text>
                      </View>
                      <View style={{ width: "20%" }}>
                        <Text></Text>
                      </View>
                      <View style={{ width: "30%" }}>
                        <View>
                          <Text
                            style={{ fontWeight: "bold", textAlign: "center" }}
                          ></Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                          }}
                        >
                          <View>
                            <Text
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            ></Text>
                          </View>
                          <View>
                            <Text
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            ></Text>
                          </View>
                          <View>
                            <Text
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            ></Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <View style={{ width: "60%" }}>
                        <Text>Recours défense</Text>
                      </View>
                      <View style={{ width: "20%" }}>
                        <Text></Text>
                      </View>
                      <View style={{ width: "30%" }}>
                        <View>
                          <Text
                            style={{ fontWeight: "bold", textAlign: "center" }}
                          ></Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                          }}
                        >
                          <View>
                            <Text
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            ></Text>
                          </View>
                          <View>
                            <Text
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            ></Text>
                          </View>
                          <View>
                            <Text
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            ></Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <View style={{ width: "60%" }}>
                        <Text>Responsabilité civile circulation</Text>
                      </View>
                      <View style={{ width: "20%" }}>
                        <Text></Text>
                      </View>
                      <View style={{ width: "30%" }}>
                        <View>
                          <Text
                            style={{ fontWeight: "bold", textAlign: "center" }}
                          ></Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                          }}
                        >
                          <View>
                            <Text
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            ></Text>
                          </View>
                          <View>
                            <Text
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            ></Text>
                          </View>
                          <View>
                            <Text
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            ></Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <View style={{ width: "60%" }}>
                        <Text>Coût de police</Text>
                      </View>
                      <View style={{ width: "20%" }}>
                        <Text></Text>
                      </View>
                      <View style={{ width: "30%" }}>
                        <View>
                          <Text
                            style={{ fontWeight: "bold", textAlign: "center" }}
                          ></Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                          }}
                        >
                          <View>
                            <Text
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            ></Text>
                          </View>
                          <View>
                            <Text
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            ></Text>
                          </View>
                          <View>
                            <Text
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            ></Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <View style={{ width: "60%" }}>
                        <Text>RC AUTO</Text>
                      </View>
                      <View style={{ width: "20%" }}>
                        <Text></Text>
                      </View>
                      <View style={{ width: "30%" }}>
                        <View>
                          <Text
                            style={{ fontWeight: "bold", textAlign: "center" }}
                          ></Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                          }}
                        >
                          <View>
                            <Text
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            ></Text>
                          </View>
                          <View>
                            <Text
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            ></Text>
                          </View>
                          <View>
                            <Text
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            ></Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>

                  <View
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      borderBottom: 1
                    }}
                  >
                    <View style={{ width: "70%", marginRight: "10%" }}>
                      <Text style={{ fontWeight: "bold" }}>
                        La prise d'effet du contrat est subordonnée au paiement
                        de la prime par le souscripteur. La prime est payable au
                        domicile de l'assureur ou de l'intermédiaire dans les
                        conditions prévues à l'article
                      </Text>
                    </View>
                    <View style={{ width: "30%" }}>
                      <View style={[styles.sectionItem, { paddingRight: 10 }]}>
                        <View>
                          <Text style={{ fontWeight: "bold" }}>
                            Prime Nte Ind. Cond.
                          </Text>
                        </View>
                        <View>
                          <Text>
                            {currency(
                              police?.avenants[police?.avenants.length - 1]
                                ?.facturation
                                ?.prime_nette_individuelle_conducteur
                            ).format({
                              style: "currency",
                              currency: " ",
                              symbol: " ",
                              separator: " ",
                            })}{" "}
                          </Text>
                        </View>
                      </View>
                      <View style={[styles.sectionItem, { paddingRight: 10 }]}>
                        <View>
                          <Text style={{ fontWeight: "bold" }}>
                            Prime Nette Totale
                          </Text>
                        </View>
                        <View>
                          <Text>
                            {currency(
                              police?.avenants[police?.avenants.length - 1]
                                ?.facturation?.prime_nette_totale
                            ).format({
                              style: "currency",
                              currency: " ",
                              symbol: " ",
                              separator: " ",
                            })}{" "}
                          </Text>
                        </View>
                      </View>
                      <View style={[styles.sectionItem, { paddingRight: 10 }]}>
                        <View>
                          <Text style={{ fontWeight: "bold" }}>
                            Coût de police
                          </Text>
                        </View>
                        <View>
                          <Text>
                            {currency(
                              police?.avenants[police?.avenants.length - 1]
                                ?.facturation?.cout_police
                            ).format({
                              style: "currency",
                              currency: " ",
                              symbol: " ",
                              separator: " ",
                            })}{" "}
                          </Text>
                        </View>
                      </View>
                      <View style={[styles.sectionItem, { paddingRight: 10 }]}>
                        <View>
                          <Text style={{ fontWeight: "bold" }}>Taxes</Text>
                        </View>
                        <View>
                          <Text>
                            {currency(
                              police?.avenants[police?.avenants.length - 1]
                                ?.facturation?.taxes
                            ).format({
                              style: "currency",
                              currency: " ",
                              symbol: " ",
                              separator: " ",
                            })}{" "}
                          </Text>
                        </View>
                      </View>
                      <View style={[styles.sectionItem, { paddingRight: 10 }]}>
                        <View>
                          <Text style={{ fontWeight: "bold" }}>
                            Frais gest. Pool
                          </Text>
                        </View>
                        <View>
                          <Text>
                            {currency(
                              police?.avenants[police?.avenants.length - 1]
                                ?.facturation?.frais_gestion_pool
                            ).format({
                              style: "currency",
                              currency: " ",
                              symbol: " ",
                              separator: " ",
                            })}{" "}
                          </Text>
                        </View>
                      </View>
                      <View style={[styles.sectionItem, { paddingRight: 10 }]}>
                        <View>
                          <Text style={{ fontWeight: "bold" }}>Carte Rose</Text>
                        </View>
                        <View>
                          <Text>
                            {currency(
                              police?.avenants[police?.avenants.length - 1]
                                ?.facturation?.carte_rose
                            ).format({
                              style: "currency",
                              currency: " ",
                              symbol: " ",
                              separator: " ",
                            })}
                          </Text>
                        </View>
                      </View>
                      <View style={[styles.sectionItem, { paddingRight: 10 }]}>
                        <View>
                          <Text style={{ fontWeight: "bold" }}>
                            Droit Timbre
                          </Text>
                        </View>
                        <View>
                          <Text>
                            {currency(
                              police?.avenants[police?.avenants.length - 1]
                                ?.facturation?.droit_timbre_automobile
                            ).format({
                              style: "currency",
                              currency: " ",
                              symbol: " ",
                              separator: " ",
                            })}
                          </Text>
                        </View>
                      </View>
                      <View style={[styles.sectionItem, { paddingRight: 10, borderTop: 1, paddingTop: 4 }]}>
                        <View>
                          <Text style={{ fontWeight: "bold" }}>
                            Total à payer
                          </Text>
                        </View>
                        <View>
                          <Text>
                            {currency(
                              police?.avenants[police?.avenants.length - 1]
                                ?.facturation?.total_a_payer
                            ).format({
                              style: "currency",
                              currency: " ",
                              symbol: " ",
                              separator: " ",
                            })}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>

                  <View style={{ marginTop: 10, fontWeight: "bold" }}>
                    <Text>
                      *Le souscripteur reconnaît avoir reçu une copie des
                      conditions générales qui sont partie intégrante des
                      présentes conditions particulières
                    </Text>
                  </View>
                  <View
                    style={{
                      marginTop: 10,
                      fontWeight: "bold",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Text>
                      *Le Souscripteur {`\n`} (Signature précédée de la mention
                      "Lu et Approuvé")
                    </Text>
                    <View>
                      <Text style={{ textAlign: "right" }}>
                        Pour la compagnie
                      </Text>
                    </View>
                  </View>
                </PageView>
              )}
            </Document>
          </PDFViewer>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          Fermer
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default ConditionsParticulieresPreviewModal;
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFF",
    padding: 20,
    fontSize: 9,
  },
  section: {
    padding: 20,
  },
  sectionItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 8,
    paddingRight: 100,
  },
});
