import { api } from "../axios/api";

export const fetchAll = async () => {
  return api.get(`categories-contrats`);
};

export const fetchOne = async (id) => {
  return api.get(`categories-contrats/${id}`);
};
export const create = async (data) => {
  return api.post(`categories-contrats`, data);
};
