import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Divider,
  Grid,
  GridRow,
  Header,
  Icon,
  Segment,
  Table,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Formik } from "formik";
import * as directivesTarifairesValueService from "../../../../services/gestion_production/directivesTarifairesValueService";
import { useRecoilValue } from "recoil";
import { authState } from "../../../../atoms/authState";
import {
  DIRECTIVE_TARIFAIRE_FIELD_TYPES,
  DIRECTIVE_TARIFAIRE_FIELDS,
} from "../../../../utils/constants";

const validateValueSchema = Yup.object({
  name: Yup.string().required("Valeur obligatoire"),
  field_type: Yup.string().required("Valeur obligatoire"),
  value: Yup.string().required("Valeur obligatoire"),
  directive_uuid: Yup.string().required("Valeur obligatoire"),
  uuid: Yup.string().uuid("Valeur invalide").nullable(),
});

function DirectivesTarifairesValues({ directiveUuid }) {
  const userData = useRecoilValue(authState);
  const [isModeEdit, setIsModeEdit] = React.useState(false);
  const [directivesTarifairesValues, setDirectivesTarifairesValues] = useState(
    []
  );
  const [loading, setLoading] = useState(false);

  const fetchDirectivesTarifairesValues = async () => {
    setLoading(true);
    directivesTarifairesValueService
      .getAll(directiveUuid)
      .then((res) => {
        setDirectivesTarifairesValues(res.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || "Une erreur est survenue");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchDirectivesTarifairesValues();
  }, [directiveUuid]);

  return (
    <GridRow>
      <Grid.Column className="!mt-6">
        <Segment>
          <div className="flex justify-between items-center">
            <Header className="!mb-0">Valeurs tarifaires</Header>
            {["ROOT_ADMIN", "POOL_USER"].includes(
              userData?.user?.user_role
            ) && (
                <Checkbox
                  toggle
                  label="Mode édition"
                  onChange={(e, { checked }) => {
                    setIsModeEdit(checked);
                    fetchDirectivesTarifairesValues();
                  }}
                  checked={isModeEdit}
                />
              )}
          </div>
          <Divider />
          <Table striped celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={6}>Nom de la valeur </Table.HeaderCell>
                <Table.HeaderCell width={3}>Type</Table.HeaderCell>
                <Table.HeaderCell width={3}>Valeur</Table.HeaderCell>
                {isModeEdit ? (
                  <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
                ) : null}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {!loading &&
                DIRECTIVE_TARIFAIRE_FIELDS.map((field) => {
                  const initialValue =
                    directivesTarifairesValues?.find(
                      (value) => value.name === field.key
                    ) || {};
                  return (
                    <Formik
                      key={field.name}
                      initialValues={{
                        name: field.key,
                        field_type: initialValue.field_type || "",
                        value: initialValue.value || "",
                        directive_uuid: directiveUuid,
                        uuid: initialValue.uuid || null,
                      }}
                      validationSchema={validateValueSchema}
                      onSubmit={(values, actions) => {
                        let data = {
                          name: values.name,
                          field_type: values.field_type,
                          value: values.value,
                          directive_uuid: values.directive_uuid,
                        };
                        let requestToSend = values.uuid
                          ? directivesTarifairesValueService.update(
                            values.uuid,
                            data
                          )
                          : directivesTarifairesValueService.create(data);

                        actions.setSubmitting(true);

                        requestToSend
                          .then((res) => {
                            toast.success("Opération réussie");
                            fetchDirectivesTarifairesValues();
                          })
                          .catch((err) => {
                            toast.error("Opération échouée");
                          })
                          .finally(() => {
                            actions.setSubmitting(false);
                          });
                      }}
                    >
                      {(formik) => (
                        <Table.Row>
                          <Table.Cell>{field.text}</Table.Cell>
                          <Table.Cell>
                            {isModeEdit ? (
                              <div className="ui form">
                                <select
                                  name="field_type"
                                  onChange={formik.handleChange}
                                  value={formik.values.field_type}
                                >
                                  <option value="">Choisir...</option>
                                  {DIRECTIVE_TARIFAIRE_FIELD_TYPES.map(
                                    (type) => (
                                      <option key={type.key} value={type.value}>
                                        {type.text}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            ) : (
                              DIRECTIVE_TARIFAIRE_FIELD_TYPES.find(
                                (type) =>
                                  type.value === formik.values.field_type
                              )?.text || "Valeur"
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {isModeEdit ? (
                              <div className="ui form">
                                <input
                                  name="value"
                                  placeholder="Valeur"
                                  type="number"
                                  onChange={formik.handleChange}
                                  value={formik.values.value}
                                />
                              </div>
                            ) : (
                              <div>
                                {new Intl.NumberFormat("fr-FR").format(
                                  formik.values.value
                                )}
                              </div>
                            )}
                          </Table.Cell>

                          {isModeEdit ? (
                            <Table.Cell textAlign="right">
                              <Button
                                size="tiny"
                                icon
                                circular
                                onClick={() => {
                                  formik.submitForm();
                                }}
                                disabled={formik.isSubmitting}
                                loading={formik.isSubmitting}
                              >
                                <Icon name="save" />
                              </Button>
                            </Table.Cell>
                          ) : null}
                        </Table.Row>
                      )}
                    </Formik>
                  );
                })}
            </Table.Body>
          </Table>
        </Segment>
      </Grid.Column>
    </GridRow>
  );
}

export default DirectivesTarifairesValues;
