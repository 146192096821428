import React, { useEffect } from "react";
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Segment,
  Table,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import * as insurancesDurationsService from "../../../../services/gestion_production/insurancesDurationsService";
import * as poolInsurancesDurations from "../../../../services/gestion_production/pool/insurancesDurationsService";
import Swal from "sweetalert2";
import { useRecoilValue } from "recoil";
import { authState } from "../../../../atoms/authState";

const schema = Yup.object().shape({
  directive_uuid: Yup.string().required("Valeur obligatoire"),
  items: Yup.array(
    Yup.object().shape({
      uuid: Yup.string()
        .nullable()
        .transform((value, originalValue) =>
          originalValue === "" ? null : value
        ),
      percentage: Yup.number().required("Valeur obligatoire"),
      insurance_duration_uuid: Yup.string()
        .uuid()
        .required("Valeur obligatoire"),
    })
  ),
});

function InsurancesDurations({ directiveUuid }) {
  const userData = useRecoilValue(authState);
  const [durations, setDurations] = React.useState([]);
  const [isModeEdit, setIsModeEdit] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      directive_uuid: "",
      items: [
        {
          uuid: null,
          percentage: "",
          insurance_duration_uuid: "",
        },
      ],
    },
    validationSchema: schema,
    onSubmit: () => {
      formik.setSubmitting(true);
      poolInsurancesDurations
        .storeBatch(formik.values)
        .then(
          (res) => {
            toast.success("Opération réussie");
            fetchItems(directiveUuid);
          },
          (err) => {
            toast.error("Opération échouée");
          }
        )
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
  });

  const addNewItem = () => {
    const items = [...formik.values.items];
    items.push({
      uuid: null,
      percentage: "",
      insurance_duration_uuid: "",
    });
    formik.setFieldValue("items", items);
  };

  const getListeDesGaranties = async () => {
    await insurancesDurationsService.getInsurancesDurations().then((res) => {
      setDurations(res.data);
    });
  };

  const deleteItem = (index) => {
    const items = [...formik.values.items];
    items.splice(index, 1);
    formik.setFieldValue("items", items);
  };

  const fetchItems = () => {
    setIsLoading(true);
    poolInsurancesDurations
      .getGaranties(directiveUuid)
      .then(
        (res) => {
          const items = [];
          res.data.pool_directives_tarifaires_has_insurances_durations.forEach(
            (item) => {
              items.push({
                uuid: item.uuid,
                percentage: item.percentage,
                insurance_duration_uuid: item.insurances_durations.uuid,
              });
            }
          );

          formik.setFieldValue("directive_uuid", directiveUuid);
          formik.setFieldValue("items", items);
        },
        (error) => {
          toast.error("Opération échouée");
        }
      )
      .catch((error) => toast.error("Opération échouée"))
      .finally(() => setIsLoading(false));
  };

  const handleRemoveItem = (index) => {
    Swal.fire({
      title: "Etes-vous sûr?",
      text: "Vous ne pourrez pas revenir en arrière!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer!",
    }).then((result) => {
      if (result.isConfirmed) {
        const item = formik.values.items[index];
        if (!item.uuid) {
          deleteItem(index);
          return true;
        }
        poolInsurancesDurations.removeItem(item.uuid).then(
          (res) => {
            toast.success("Opération réussie");
            fetchItems(directiveUuid);
            deleteItem(index);
          },
          (err) => {
            toast.error("Opération échouée");
          }
        );
      }
    });
  };

  useEffect(() => {
    getListeDesGaranties();
    formik.setFieldValue("directive_uuid", directiveUuid);
  }, []);

  useEffect(() => {
    if (directiveUuid) fetchItems();
  }, [directiveUuid]);

  return (
    <GridRow>
      <Grid.Column className="!mt-6">
        <Segment loading={isLoading}>
          <div className="flex justify-between items-center">
            <Header className="!mb-0">Durées d'assurance</Header>
            {["ROOT_ADMIN", "POOL_USER"].includes(
              userData?.user?.user_role
            ) && (
                <Checkbox
                  toggle
                  label="Mode édition"
                  onChange={(e, { checked }) => {
                    setIsModeEdit(checked);
                    fetchItems();
                  }}
                  checked={isModeEdit}
                />
              )}
          </div>
          <Divider />
          <Table striped celled textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell collapsing width={1}>
                  #
                </Table.HeaderCell>
                <Table.HeaderCell width={6}>Nom de la durée </Table.HeaderCell>
                <Table.HeaderCell width={3}>
                  % de la prime annuelle{" "}
                </Table.HeaderCell>
                {isModeEdit ? (
                  <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
                ) : null}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {!isLoading && formik.values?.items?.map((item, index) => (
                <Table.Row key={index}>
                  <Table.Cell>{index + 1}</Table.Cell>
                  <Table.Cell textAlign="left">
                    {isModeEdit ? (
                      <Dropdown
                        fluid
                        search
                        selection
                        value={item.insurance_duration_uuid}
                        options={[
                          {
                            key: "",
                            text: "Choisir...",
                            value: "",
                          },
                        ].concat(
                          durations.map((duration) => ({
                            key: duration.uuid,
                            text: `${duration.months_number} mois`,
                            value: duration.uuid,
                          }))
                        )}
                        onChange={(e, { value }) => {
                          const items = [...formik.values.items];
                          items[index].insurance_duration_uuid = value;
                          formik.setFieldValue("items", items);
                        }}
                      />
                    ) : (
                      `${durations.find(
                        (d) => d.uuid === item.insurance_duration_uuid
                      )?.months_number
                      } mois`
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {isModeEdit ? (
                      <Input
                        fluid
                        value={item.percentage}
                        onChange={(e) => {
                          const items = [...formik.values.items];
                          items[index].percentage = e.target.value;
                          formik.setFieldValue("items", items);
                        }}
                        type="number"
                        min={1}
                        max={100}
                        step={1}
                      />
                    ) : (
                      item.percentage
                    )}
                  </Table.Cell>

                  {isModeEdit ? (
                    <Table.Cell>
                      <Icon
                        name="trash"
                        color="red"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleRemoveItem(index);
                        }}
                      />
                    </Table.Cell>
                  ) : null}
                </Table.Row>
              ))}
            </Table.Body>
            {isModeEdit ? (
              <Table.Footer fullWidth>
                <Table.Row>
                  <Table.HeaderCell colspan={isModeEdit ? 5 : 4}>
                    <Button
                      className="!ml-4"
                      icon="add"
                      size="tiny"
                      circular
                      floated="left"
                      onClick={() => {
                        addNewItem();
                      }}
                    />
                    <Button
                      content="Enregistrer"
                      icon="save"
                      labelPosition="left"
                      color="green"
                      type="submit"
                      floated="right"
                      size="tiny"
                      onClick={() => {
                        formik.handleSubmit();
                      }}
                    />
                    <Button
                      content="Annuler"
                      icon="cancel"
                      labelPosition="left"
                      color="red"
                      floated="right"
                      size="tiny"
                      loading={formik.isSubmitting}
                      disabled={formik.isSubmitting}
                      onClick={() => {
                        setIsModeEdit(false);
                        fetchItems();
                      }}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            ) : null}
          </Table>
        </Segment>
      </Grid.Column>
    </GridRow>
  );
}

export default InsurancesDurations;
