import React, { useState, useEffect } from "react";
import { Button, Divider, Form, Input } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as souscripteursService from "../../services/souscripteursService";
import * as civilitiesService from "../../services/civilitiesService";
import * as piecesOfficiellesService from "../../services/piecesOfficiellesService";
import * as jobsService from "../../services/jobsService";
import dayjs from "dayjs";

const validationSchema = yup.object().shape({
  first_name: yup.string().max(150).required("Le prénom est obligatoire"),
  last_name: yup.string().max(150).required("Le nom est obligatoire"),
  idcivilities: yup.string().required("Le titre est obligatoire"),
  email: yup.string().email("L'email est invalide"),
  address: yup.string().max(255).required("L'adresse est obligatoire"),
  date_naissance: yup.date(),
  date_delivrance_piece: yup.date(),
  lieu_naissance: yup.string().max(255),
  lieu_delivrance_piece: yup.string().max(255),
  numero_piece: yup.string().max(50),
  phone: yup
    .string()
    .min(8)
    .max(16)
    .required("Le numéro de téléphone est obligatoire"),
  groupe_souscripteur: yup
    .string()
    .oneOf(["PARTICULIER"])
    .required("Le groupe du souscripteur est obligatoire"),
  idcountries: yup.number().notRequired(),
  idjobs: yup.number().notRequired(),
  idpieces_officielles: yup.number().notRequired(),
});
function ParticulierForm({
  callback = () => {},
  hideModalCallback = () => {},
  open = false,
  setOpen,
  countries = [],
  souscripteur = null,
}) {
  const [civilities, setCivilities] = useState([]);
  const [piecesOfficielles, setPiecesOfficielles] = useState([]);
  const [jobs, setJobs] = useState([]);

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      idcivilities: 1,
      email: "",
      address: "",
      date_naissance: "",
      date_delivrance_piece: "",
      lieu_naissance: "",
      lieu_delivrance_piece: "",
      numero_piece: "",
      phone: "",
      idjobs: "",
      idcountries: "",
      idpieces_officielles: 1,
      groupe_souscripteur: "PARTICULIER",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const operation = souscripteur
        ? souscripteursService.update(souscripteur.uuid, values)
        : souscripteursService.create(values);

      operation
        .then(
          (response) => {
            toast(
              `Souscripteur ${
                souscripteur ? "modifié" : "ajouté"
              } avec succès.`,
              {
                type: "success",
                theme: "colored",
              }
            );
            handleReset();
            callback();
            setOpen(false);
            hideModalCallback();
          },
          (reason) => {
            toast(
              `${reason?.response?.data?.error || "Une erreur est survenue"}`,
              {
                type: "error",
                theme: "colored",
              }
            );
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const fetchCivilities = async () => {
    try {
      const response = await civilitiesService.fetchAll();
      if (response.status === 200 && Array.isArray(response.data)) {
        setCivilities(response.data);
      }
    } catch (error) {}
  };
  const fetchPiecesOfficielles = async () => {
    try {
      const response = await piecesOfficiellesService.fetchAll();
      if (response.status === 200 && Array.isArray(response.data)) {
        setPiecesOfficielles(response.data);
      }
    } catch (error) {}
  };
  const fetchJobs = async () => {
    try {
      const response = await jobsService.fetchAll();
      if (response.status === 200 && Array.isArray(response.data)) {
        setJobs(response.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (open) {
      fetchCivilities();
      fetchPiecesOfficielles();
      fetchJobs();
    }
  }, [open]);

  const {
    values,
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    handleBlur,
    handleChange,
    handleReset,
    setFieldValue,
  } = formik;

  React.useEffect(() => {
    formik.resetForm();
    if (souscripteur) {
      formik.setValues({
        first_name: souscripteur.first_name,
        last_name: souscripteur.last_name,
        idcivilities: souscripteur.idcivilities || 1,
        email: souscripteur.email || "",
        address: souscripteur.address || "",
        date_naissance:
          souscripteur.date_naissance?.length > 0
            ? dayjs(souscripteur.date_naissance).format("YYYY-MM-DD")
            : "",
        date_delivrance_piece:
          souscripteur.date_delivrance_piece?.length > 0
            ? dayjs(souscripteur.date_delivrance_piece).format("YYYY-MM-DD")
            : "",
        lieu_naissance: souscripteur.lieu_naissance,
        lieu_delivrance_piece: souscripteur.lieu_delivrance_piece || "",
        numero_piece: souscripteur.numero_piece || "",
        phone: souscripteur.phone || "",
        idjobs: souscripteur.idjobs || "",
        idcountries: souscripteur.idcountries || "",
        idpieces_officielles: souscripteur.idpieces_officielles || "",
        groupe_souscripteur: souscripteur.groupe_souscripteur || "PARTICULIER",
      });
    }
  }, [souscripteur]);
  return (
    <Form size="tiny">
      <Form.Group>
        <Form.Field error={"idcivilities" in errors} width={4}>
          <label>Titre</label>
          <Form.Select
            search
            fluid
            value={values.idcivilities}
            onChange={(e, data) => setFieldValue("idcivilities", data.value)}
            placeholder="Choisr..."
            options={civilities.map((civility) => ({
              key: `${civility.idcivilities}`,
              text: `${civility.libelle}`,
              value: civility.idcivilities,
            }))}
          />
          <small className="field-error">{errors.idcivilities}</small>
        </Form.Field>
        <Form.Field width={6} error={"last_name" in errors} required>
          <label>Nom</label>
          <Input
            value={values.last_name}
            onChange={handleChange("last_name")}
            onBlur={handleBlur("last_name")}
          />
          <small className="field-error">{errors.last_name}</small>
        </Form.Field>
        <Form.Field width={6} error={"first_name" in errors} required>
          <label>Prénom</label>
          <Input
            value={values.first_name}
            onChange={handleChange("first_name")}
            onBlur={handleBlur("first_name")}
          />
          <small className="field-error">{errors.first_name}</small>
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Field width={4} error={"date_naissance" in errors}>
          <label>Date de naissance</label>
          <Input
            type="date"
            value={values.date_naissance}
            onChange={handleChange("date_naissance")}
            onBlur={handleBlur("date_naissance")}
          />
          <small className="field-error">{errors.date_naissance}</small>
        </Form.Field>
        <Form.Field width={6} error={"lieu_naissance" in errors}>
          <label>Lieu de naissance</label>
          <Input
            value={values.lieu_naissance}
            onChange={handleChange("lieu_naissance")}
            onBlur={handleBlur("lieu_naissance")}
          />
          <small className="field-error">{errors.lieu_naissance}</small>
        </Form.Field>
        <Form.Field error={"idcountries" in errors} width={6}>
          <label>Pays d'origine</label>
          <Form.Select
            search
            fluid
            value={values.idcountries}
            onChange={(e, data) => setFieldValue("idcountries", data.value)}
            placeholder="Choisr..."
            options={[
              {
                key: "",
                value: "",
                text: "Choisir...",
              },
              ...countries.map((country) => ({
                key: country.idcountries,
                value: country.idcountries,
                text: country.libelle,
              })),
            ]}
          />
          <small className="field-error">{errors.idcountries}</small>
        </Form.Field>
      </Form.Group>
      <Divider />
      <Form.Group>
        <Form.Field error={"idpieces_officielles" in errors} width={4}>
          <label>Pièce officielle</label>
          <Form.Select
            search
            value={values.idpieces_officielles}
            fluid
            onChange={(e, data) =>
              setFieldValue("idpieces_officielles", data.value)
            }
            placeholder="Choisr..."
            options={piecesOfficielles.map((piece) => ({
              key: `${piece.idpieces_officielles}`,
              text: `${piece.libelle}`,
              value: piece.idpieces_officielles,
            }))}
          />
          <small className="field-error">{errors.idpieces_officielles}</small>
        </Form.Field>
        <Form.Field width={6} error={"numero_piece" in errors}>
          <label>Numéro de la pièce</label>
          <Input
            value={values.numero_piece}
            onChange={handleChange("numero_piece")}
            onBlur={handleBlur("numero_piece")}
          />
          <small className="field-error">{errors.numero_piece}</small>
        </Form.Field>
        <Form.Field width={6} error={"lieu_delivrance_piece" in errors}>
          <label>Lieu de délivrance</label>
          <Input
            value={values.lieu_delivrance_piece}
            onChange={handleChange("lieu_delivrance_piece")}
            onBlur={handleBlur("lieu_delivrance_piece")}
          />
          <small className="field-error">{errors.lieu_delivrance_piece}</small>
        </Form.Field>
      </Form.Group>
      <Divider />
      <Form.Group>
        <Form.Field error={"idjobs" in errors} width={4}>
          <label>Profession</label>
          <Form.Select
            search
            fluid
            onChange={(e, data) => setFieldValue("idjobs", data.value)}
            placeholder="Choisr..."
            value={values.idjobs}
            options={[
              {
                key: "",
                value: "",
                text: "Choisir...",
              },
              ...jobs.map((job) => ({
                key: job.idjobs,
                value: job.idjobs,
                text: job.libelle,
              })),
            ]}
          />
          <small className="field-error">{errors.idjobs}</small>
        </Form.Field>
        <Form.Field error={"phone" in errors} required width={6}>
          <label>Téléphone</label>
          <Input
            value={values.phone}
            onChange={handleChange("phone")}
            onBlur={handleBlur("phone")}
          />
          <small className="field-error">{errors.phone}</small>
        </Form.Field>
        <Form.Field error={"email" in errors} width={6}>
          <label>Email</label>
          <Input
            value={values.email}
            onChange={handleChange("email")}
            onBlur={handleBlur("email")}
          />
          <small className="field-error">{errors.email}</small>
        </Form.Field>
      </Form.Group>
      <Form.Field required error={"address" in errors}>
        <label>Adresse</label>
        <Input
          value={values.address}
          onChange={handleChange("address")}
          onBlur={handleBlur("address")}
        />
        <small className="field-error">{errors.adress}</small>
      </Form.Field>
      <Divider />
      <Form.Field style={{ textAlign: "right" }}>
        <Button
          color="black"
          onClick={() => {
            handleReset();
            setOpen(false);
            hideModalCallback();
          }}
        >
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          type="submit"
          loading={isSubmitting}
        />
      </Form.Field>
    </Form>
  );
}

export default ParticulierForm;
