import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Button,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Pagination,
  Segment,
  Table,
} from "semantic-ui-react";
import Swal from "sweetalert2";
import SouscripteursFormModal from "../../components/modalForms/SouscripteursFormModal";
import Page from "../../components/Page";

import * as souscripteursService from "../../services/souscripteursService";
import { toast } from "react-toastify";
function SouscripteursList() {
  const [souscripteurs, setSouscripteurs] = useState([]);
  const [selecttedSouscripteurUuid, setSelecttedSouscripteurUuid] =
    useState(null);
  const [pagination, setPagination] = useState({
    totalCount: 0,
    totalPages: 0,
    currentPage: 1,
    nextPage: null,
    prevPage: null,
  });
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const fetchSouscripteurs = async ({
    search = "",
    page = 1,
    limit = 10,
  } = {}) => {
    try {
      const response = await souscripteursService.fetchAll({
        search,
        page,
        limit,
      });
      if (response.status === 200 && response.data) {
        setSouscripteurs(response.data.souscripteurs);
        setPagination({
          totalCount: response.data.totalCount,
          totalPages: response.data.totalPages,
          currentPage: response.data.currentPage,
          nextPage: response.data.nextPage,
          prevPage: response.data.prevPage,
        });
      }
    } catch (error) {
      toast.error(error.message || "Erreur lors de la récupération des souscripteurs");
    }
  };

  const handlePageChange = (e, { activePage }) => {
    setPagination({ ...pagination, currentPage: activePage });
  };

  const handleEditSouscripteur = (uuid) => {
    setSelecttedSouscripteurUuid(uuid);
    setOpen(true);
  };

  useEffect(() => {
    fetchSouscripteurs();
    return () => {};
  }, [pagination.currentPage]);

  const hideModalCallback = () => {
    setSelecttedSouscripteurUuid(null);
  };

  const handleDeleteSouscripteur = (uuid) => {
    Swal.fire({
      title: "Suppression du souscripteur",
      text: "Vous ne pourrez pas récupérer ce souscripteur!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer!",
    }).then((result) => {
      if (result.isConfirmed) {
        souscripteursService.deleteSouscripteur(uuid).then(
          (response) => {
            if (response.status === 200) {
              setSouscripteurs(
                souscripteurs.filter(
                  (souscripteur) => souscripteur.uuid !== uuid
                )
              );
            }
          },
          (reason) => {
            Swal.fire({
              title: "Erreur",
              text: "Erreur lors de la suppression du souscripteur",
              icon: "error",
            });
          }
        );
      }
    });
  };

  const handleSearch = (e) => {
    setPagination({ ...pagination, currentPage: 1 });
    fetchSouscripteurs({ search: e.target.value });
  };

  return (
    <Page title="Souscripteurs">
      <GridRow className="Souscripteurs">
        <Grid.Row className="!mb-5">
          <Grid.Column width={16}>
            <Breadcrumb>
              <BreadcrumbSection link>Agence</BreadcrumbSection>
              <BreadcrumbDivider icon="right chevron" />
              <BreadcrumbSection link>
                Gestion de la production
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection active>Souscripteurs</BreadcrumbSection>
            </Breadcrumb>
            <Divider />
          </Grid.Column>
        </Grid.Row>
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Header>Liste des Souscripteurs</Header>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input placeholder="Rechercher..." className="search">
              <input name="search" onChange={handleSearch} />
            </Input>
            <Button
              style={{ marginLeft: "10px" }}
              circular
              icon={<Icon name="add" />}
              onClick={() => setOpen(true)}
            />
          </Grid.Column>
        </Grid>
        <>
          <Segment>
            <Table striped celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell collapsing>#</Table.HeaderCell>
                  <Table.HeaderCell>#Code</Table.HeaderCell>
                  <Table.HeaderCell>Nom</Table.HeaderCell>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell>email</Table.HeaderCell>
                  <Table.HeaderCell>Téléphone</Table.HeaderCell>
                  <Table.HeaderCell collapsing textAlign="right">
                    Actions
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {souscripteurs.map((souscripteur, index) => {
                  return (
                    <Table.Row key={`${souscripteur.num}`}>
                      <Table.Cell>{index + 1}</Table.Cell>
                      <Table.Cell>{`${souscripteur.num}`}</Table.Cell>
                      <Table.Cell>
                        {souscripteur.groupe_souscripteur === "ENTREPRISE"
                          ? `${souscripteur.denomination}`
                          : `${souscripteur.last_name} ${souscripteur.first_name}`}
                      </Table.Cell>
                      <Table.Cell>
                        {souscripteur.groupe_souscripteur}
                      </Table.Cell>
                      <Table.Cell>{souscripteur.email}</Table.Cell>
                      <Table.Cell>{souscripteur.phone}</Table.Cell>
                      <Table.Cell textAlign="right">
                        <Dropdown
                          pointing="right"
                          icon={<Icon name="ellipsis vertical" />}
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item
                              icon={<Icon name="eye" />}
                              content="Détails"
                              onClick={() =>
                                navigate(`details/${souscripteur.uuid}`)
                              }
                            />
                            <Dropdown.Item
                              icon={<Icon name="edit" />}
                              content="Editer"
                              onClick={() =>
                                handleEditSouscripteur(souscripteur.uuid)
                              }
                            />
                            <Dropdown.Item
                              icon={<Icon name="eye" />}
                              content="Supprimer"
                              onClick={() =>
                                handleDeleteSouscripteur(souscripteur.uuid)
                              }
                            />
                          </Dropdown.Menu>
                        </Dropdown>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="7" textAlign="right">
                    <Pagination
                      totalPages={pagination.totalPages}
                      currentPage={pagination.currentPage}
                      onPageChange={handlePageChange}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Segment>
        </>
      </GridRow>
      <SouscripteursFormModal
        callback={() => {
          fetchSouscripteurs();
        }}
        open={open}
        setOpen={setOpen}
        uuid={selecttedSouscripteurUuid}
        hideModalCallback={hideModalCallback}
      />
    </Page>
  );
}

export default SouscripteursList;
