import { api } from "../../../axios/api";

export const getAvariesByDirectiveTatifaire = async (directive_uuid) => {
  return api.get(
    `/gestion-production/garanties-vol-incendie/${directive_uuid}/directives-tarifaires`
  );
};

export const create = async (data) => {
  return api.post("/gestion-production/garanties-vol-incendie", data);
};
export const update = async (uuid, data) => {
  return api.put("/gestion-production/garanties-vol-incendie/" + uuid, data);
};

export const removeItem = async (uuid) => {
  return api.delete(`/gestion-production/garanties-vol-incendie/${uuid}`);
};

export const getByActiveDirectiveTarifaires = async (params) => {
  return api.get(
    `/gestion-production/garanties-vol-incendie/active/directives-tarifaires`,
    { params }
  );
};
