import React from "react";
import {
  Button,
  Dropdown,
  Grid,
  GridRow,
  Icon,
  Input,
  Modal,
  Table,
} from "semantic-ui-react";
import * as policesService from "../../services/gestion_production/policesService";
import dayjs from "dayjs";

function SearchPoliceModal({
  open,
  setOpen = () => {},
  setOperationType,
  onCloseCallBack = () => {},
  setSelectedPoliceId = () => {},
}) {
  const [polices, setPolices] = React.useState([]);

  const searchPolices = async (params = {}) => {
    try {
      const response = await policesService.findAll(params);
      if (response.status === 200 && Array.isArray(response.data)) {
        setPolices(response.data);
      }
    } catch (error) {}
  };
  return (
    <Modal
      onClose={() => {
        setOpen(false);
      }}
      onUnmount={() => {
        setPolices([]);
        onCloseCallBack();
      }}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      size="small"
    >
      <Modal.Header>Rechercher une police d'assurance</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <GridRow>
            <Grid>
              <Grid.Column width={16}>
                <Input
                  placeholder="Rechercher..."
                  className="search"
                  style={{ width: "100%" }}
                >
                  <input
                    name="search"
                    onChange={(e) => {
                      if (e.target.value.length >= 3) {
                        searchPolices({ search: e.target.value });
                      } else {
                        setPolices([]);
                      }
                    }}
                  />
                </Input>
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column width={16}>
                <Table striped>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>#</Table.HeaderCell>
                      <Table.HeaderCell>Numéro</Table.HeaderCell>
                      <Table.HeaderCell>Immatriculation</Table.HeaderCell>
                      <Table.HeaderCell>Souscripteur</Table.HeaderCell>
                      <Table.HeaderCell>Création</Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        Actions
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {polices.map((police, index) => {
                      return (
                        <Table.Row key={police.id}>
                          <Table.Cell>{index + 1}</Table.Cell>
                          <Table.Cell>{police.num_police}</Table.Cell>
                          <Table.Cell>
                            {police.avenants[0]?.vehicules?.immatriculation ||
                              "N/A"}
                          </Table.Cell>
                          <Table.Cell>
                            {
                              police.souscriptions
                                ?.souscripteurs_souscriptions_idsouscripteursTosouscripteurs
                                ?.first_name
                            }{" "}
                            {
                              police.souscriptions
                                ?.souscripteurs_souscriptions_idsouscripteursTosouscripteurs
                                ?.last_name
                            }
                          </Table.Cell>
                          <Table.Cell>
                            {dayjs(police.created_at).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </Table.Cell>
                          <Table.Cell textAlign="right">
                            <Dropdown
                              pointing="right"
                              style={{ zIndex: 999 }}
                              icon={null}
                              trigger={
                                <Button
                                  style={{ marginLeft: "10px" }}
                                  circular
                                  icon={<Icon name="add" />}
                                />
                              }
                            >
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  content="Détails"
                                  onClick={() => {}}
                                />

                                <Dropdown.Item content="Résiliation" />
                                <Dropdown.Item
                                  content="Suspension"
                                  onClick={() => {
                                    setOperationType("suspension");
                                    setOpen(false);
                                    setSelectedPoliceId(police.idpolices);
                                  }}
                                />
                                <Dropdown.Item content="Modification de prise d'éffet" />
                                <Dropdown.Item
                                  content="Changement d'immatriculation"
                                  onClick={() => {
                                    setOperationType("changement-immatriculation");
                                    setOpen(false);
                                    setSelectedPoliceId(police.idpolices);
                                  }}
                                />
                                <Dropdown.Item content="Remise en vigueur" />
                                <Dropdown.Item content="Incorporation" />
                                <Dropdown.Item content="Retrait" />
                                <Dropdown.Item content="Emission d'attestation" />
                                <Dropdown.Item content="Extension des garanties" />
                                <Dropdown.Item
                                  content="Avenant de changement de nom"
                                  onClick={() => {
                                    setOperationType("changement-de-nom");
                                    setOpen(false);
                                    setSelectedPoliceId(police.idpolices);
                                  }}
                                />
                              </Dropdown.Menu>
                            </Dropdown>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid>
          </GridRow>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>Fermer</Button>
      </Modal.Actions>
    </Modal>
  );
}

export default SearchPoliceModal;
