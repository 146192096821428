import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Divider,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Segment,
  Table,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";

import * as tableauGarantiesService from "../../../../services/gestion_production/tableauGarantiesRcService";
import { useRecoilValue } from "recoil";
import { authState } from "../../../../atoms/authState";

function TableauGarantiesRcAn({ directiveUuid }) {
  const userData = useRecoilValue(authState);
  const [puissancesFiscalesCols, setPuissancesFiscalesCols] = useState([]);
  const [tableauGaranties, setTableauGaranties] = useState([]);
  const [garantiesLines, setGarantiesLines] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModeEdit, setIsModeEdit] = useState(false);

  const handleAddNewItemClick = () => {
    if (puissancesFiscalesCols.length === 0) {
      toast.error("Ajouter au moins une puissance fiscale");
      return;
    }
    let newline = {
      id: uuid(),
      minPlaceValue: 0,
      maxPlaceValue: 0,
      puissancesFiscalesValues: puissancesFiscalesCols?.map((item, index) => {
        return {
          colId: item.id,
          value: 0,
        };
      }),
    };

    setGarantiesLines([...garantiesLines, newline]);
  };

  const handleRemovePuissanceFicaleCol = (id) => {
    let newCols = puissancesFiscalesCols?.filter((item, idx) => item.id !== id);
    let newLines = garantiesLines?.map((item, idx) => {
      return {
        ...item,
        puissancesFiscalesValues: item.puissancesFiscalesValues.filter(
          (col) => col.colId !== id
        ),
      };
    });

    setGarantiesLines(newLines);
    setPuissancesFiscalesCols(newCols);
  };

  const handleRemoveGarantieLine = (index) => {
    setGarantiesLines(garantiesLines.filter((item) => item.id !== index));
  };

  const handlePlacesChange = (id, value, name) => {
    setGarantiesLines(
      garantiesLines.map((item, idx) => {
        if (item.id === id) {
          return {
            ...item,
            [name]: isNaN(Number(value)) ? 0 : Number(value || 0),
          };
        }
        return item;
      })
    );
  };

  const handleFiscalColChange = (index, value, name) => {
    setPuissancesFiscalesCols(
      puissancesFiscalesCols.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            [name]: isNaN(Number(value)) ? 0 : Number(value || 0),
          };
        }
        return item;
      })
    );
  };

  const handleGarantieValueChange = (id, colId, value) => {
    setGarantiesLines(
      garantiesLines.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            puissancesFiscalesValues: item.puissancesFiscalesValues.map(
              (col) => {
                if (col.colId === colId) {
                  return {
                    ...col,
                    value: isNaN(Number(value)) ? 0 : Number(value || 0),
                  };
                }
                return col;
              }
            ),
          };
        }
        return item;
      })
    );
  };

  const addNewForceFiscalCol = () => {
    let newId = uuid();
    setPuissancesFiscalesCols([
      ...puissancesFiscalesCols,
      {
        id: newId,
        minValue: 0,
        maxValue: 0,
      },
    ]);

    setGarantiesLines(
      garantiesLines.map((item) => {
        return {
          ...item,
          puissancesFiscalesValues: [
            ...item.puissancesFiscalesValues,
            {
              colId: newId,
              value: 0,
            },
          ],
        };
      })
    );
  };

  const handleSubmit = async () => {
    try {
      if (garantiesLines.length === 0) {
        toast.error("Ajouter au moins une garantie");
        return;
      }
      if (puissancesFiscalesCols?.length === 0) {
        toast.error("Ajouter au moins une puissance fiscale");
        return;
      }

      let data = {
        directive_uuid: directiveUuid,
        garanties_lines: garantiesLines,
        puissances_fiscales_cols: puissancesFiscalesCols,
      };

      setIsSubmitting(true);

      const requestOp = tableauGaranties?.uuid
        ? tableauGarantiesService.updateBatch(tableauGaranties.uuid, data)
        : tableauGarantiesService.createBatch(data);
      const response = await requestOp;

      if (response.status === 200 || response.status === 201) {
        fetchTableauGaranties();
        toast.success("Enregistrement effectué avec succes");
      }
    } catch (error) {
      toast.error("Erreur lors de l'enregistrement");
    } finally {
      setIsSubmitting(false);
    }
  };

  const fetchTableauGaranties = async () => {
    try {
      const { data } =
        await tableauGarantiesService.getTableauGarantiesByDirectivesTarifaires(
          directiveUuid
        );
      if (data) {
        setTableauGaranties(data);
        if (
          data &&
          typeof data.tableau_garantie_puissance_fiscale_cols === "object"
        ) {
          setPuissancesFiscalesCols(
            data.tableau_garantie_puissance_fiscale_cols?.map((item, index) => {
              return {
                id: item.uuid,
                minValue: item.min_value,
                maxValue: item.max_value,
              };
            })
          );
        }

        if (data && Array.isArray(data.tableau_garantie_vehicule_data)) {
          setGarantiesLines(
            data.tableau_garantie_vehicule_data?.map((item, index) => {
              return {
                id: item.uuid,
                minPlaceValue: item.places_start_at,
                maxPlaceValue: item.places_end_at,
                puissancesFiscalesValues: item.tableau_garantie_values?.map(
                  (col) => {
                    return {
                      colId: col.tableau_garantie_puissance_fiscale_cols.uuid,
                      value: col.value,
                    };
                  }
                ),
              };
            })
          );
        }
      }
    } catch (error) { }
  };

  useEffect(() => {
    fetchTableauGaranties();
  }, [uuid]);

  return (
    <GridRow>
      <Grid.Column className="!mt-6">
        <Segment>
          <div className="flex justify-between items-center">
            <Header className="!mb-0">Tableau des garanties RC (1 An)</Header>
            {["ROOT_ADMIN", "POOL_USER"].includes(userData?.user?.user_role) && (
              <Checkbox
                toggle
                label="Mode édition"
                onClick={(data, { checked }) => {
                  setIsModeEdit(!isModeEdit);
                  fetchTableauGaranties();
                }}
                checked={isModeEdit}
              />
            )}
          </div>
          <Divider />
          <Table striped celled textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell collapsing>#</Table.HeaderCell>
                <Table.HeaderCell rowspan={2} collapsing>
                  Nombre de place autorisée <br /> en sus de celle du conducteur
                </Table.HeaderCell>
                <Table.HeaderCell
                  colspan={puissancesFiscalesCols?.length}
                  textAlign="center"
                >
                  FORCE FISCALE EN CV
                  {isModeEdit ? (
                    <Button
                      className="!ml-4"
                      icon="add"
                      size="tiny"
                      circular
                      onClick={() => {
                        addNewForceFiscalCol();
                      }}
                    />
                  ) : null}
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell></Table.HeaderCell>
                {puissancesFiscalesCols?.map((col, index) => (
                  <Table.HeaderCell>
                    {isModeEdit ? (
                      <>
                        <div className="ui form !justify-center flex">
                          <div className="ui fields">
                            <div className="ui  field">
                              <Input
                                style={{ width: "50px" }}
                                onChange={(e) => {
                                  handleFiscalColChange(
                                    index,
                                    e.target.value,
                                    "minValue"
                                  );
                                }}
                                value={col.minValue}
                              />
                            </div>
                            <div className="ui field">
                              <Input
                                style={{ width: "50px" }}
                                value={col.maxValue}
                                onChange={(e) => {
                                  handleFiscalColChange(
                                    index,
                                    e.target.value,
                                    "maxValue"
                                  );
                                }}
                              />
                            </div>
                            <Icon
                              name="trash"
                              color="red"
                              className="cursor-pointer !mt-4"
                              size="small"
                              onClick={() =>
                                handleRemovePuissanceFicaleCol(col.id)
                              }
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      ` ${col.minValue} à ${col.maxValue === 0 ? "∞" : col.maxValue
                      }`
                    )}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {garantiesLines?.map((item, index) => (
                <Table.Row>
                  <Table.Cell>{index + 1}</Table.Cell>
                  <Table.Cell>
                    {isModeEdit ? (
                      <>
                        <div className="ui form !justify-center flex">
                          <div className="ui fields">
                            <div className="ui  field">
                              <Input
                                type="number"
                                style={{ width: "100px" }}
                                value={item.minPlaceValue}
                                onChange={(e) =>
                                  handlePlacesChange(
                                    item.id,
                                    e.target.value,
                                    "minPlaceValue"
                                  )
                                }
                              />
                            </div>
                            <div className="ui  field">
                              <Input
                                style={{ width: "100px" }}
                                value={item.maxPlaceValue}
                                type="number"
                                onChange={(e) =>
                                  handlePlacesChange(
                                    item.id,
                                    e.target.value,
                                    "maxPlaceValue"
                                  )
                                }
                              />
                            </div>
                            <Icon
                              name="trash"
                              color="red"
                              className="cursor-pointer !mt-4"
                              size="small"
                              onClick={() => handleRemoveGarantieLine(item.id)}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <strong>
                        {`${item.minPlaceValue} à ${item.maxPlaceValue}`}
                      </strong>
                    )}
                  </Table.Cell>
                  {puissancesFiscalesCols?.map((col, idx) => (
                    <Table.Cell>
                      {isModeEdit ? (
                        <div className="ui form !justify-center flex">
                          <div className="ui  field">
                            <Input
                              style={{ width: "120px" }}
                              type="number"
                              onChange={(e) =>
                                handleGarantieValueChange(
                                  item.id,
                                  col.id,
                                  e.target.value
                                )
                              }
                              value={
                                item.puissancesFiscalesValues?.find(
                                  (_col) => _col.colId === col.id
                                )?.value
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        `${new Intl.NumberFormat().format(
                          item.puissancesFiscalesValues?.find(
                            (_col) => _col.colId === col.id
                          )?.value
                        )}`
                      )}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </Table.Body>
            {isModeEdit && (
              <Table.Footer fullWidth>
                <Table.Row>
                  <Table.HeaderCell
                    colspan={puissancesFiscalesCols?.length + 2}
                  >
                    <Button
                      className="!ml-4"
                      icon="add"
                      size="tiny"
                      circular
                      floated="left"
                      onClick={() => {
                        handleAddNewItemClick();
                      }}
                    />
                    <Button
                      content="Enregistrer"
                      icon="save"
                      labelPosition="left"
                      color="green"
                      type="submit"
                      floated="right"
                      size="tiny"
                      onClick={() => {
                        handleSubmit();
                      }}
                      disabled={
                        garantiesLines?.length === 0 ||
                        puissancesFiscalesCols?.length === 0 ||
                        isSubmitting
                      }
                      loading={isSubmitting}
                    />
                    <Button
                      content="Annuler"
                      icon="cancel"
                      labelPosition="left"
                      color="red"
                      floated="right"
                      size="tiny"
                      onClick={() => {
                        setIsModeEdit(false);
                        setIsSubmitting(false);
                        fetchTableauGaranties();
                      }}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            )}
          </Table>
        </Segment>
      </Grid.Column>
    </GridRow>
  );
}

export default TableauGarantiesRcAn;
