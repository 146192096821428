import React, { useEffect } from "react";
import { Button, Checkbox, Form, Input, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import * as garantiesComplementairesService from "../../../../services/gestion_production/company/garantiesComplementairesService";

const validationSchema = yup.object().shape({
  name: yup.string().min(2).max(255).required(),
  code: yup.string().min(2).max(255).required(),
  is_active: yup.number().default(0),
});
function GarantiesComplementairesModalForm({
  callback = () => {},
  open = false,
  setOpen,
  selectedItemId,
  handleCloseModal = () => {},
}) {
  const formik = useFormik({
    initialValues: {
      name: "",
      code: "",
      is_active: 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const request = selectedItemId
        ? garantiesComplementairesService.update(selectedItemId, values)
        : garantiesComplementairesService.create(values);

      request
        .then((response) => {
          toast.success(
            `Garantie ${selectedItemId ? "mise à jour" : "ajoutée"}`
          );
          handleCloseModal();
          callback();
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.error ||
              `Une erreur est survenue lors de ${
                selectedItemId ? "la mise à jour" : "l'ajout"
              } de la garantie`
          );
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
    validateOnBlur: false,
    validateOnChange: false,
  });

  const {
    values,
    errors,
    handleSubmit,
    isSubmitting,
    handleBlur,
    handleChange,
  } = formik;

  useEffect(() => {
    formik.resetForm();
    if (selectedItemId) {
      garantiesComplementairesService
        .fetchOne(selectedItemId)
        .then((response) => {
          if (typeof response.data === "object") {
            const data = response.data;
            formik.setFieldValue("name", data.name);
            formik.setFieldValue("code", data.code);
            formik.setFieldValue("is_active", data.is_active);
          }
        });
    }

    return () => {};
  }, [selectedItemId]);

  return (
    <Modal
      onClose={() => {
        handleCloseModal();
      }}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      size="tiny"
      closeIcon
      onUnmount={() => {
        formik.resetForm();
      }}
    >
      <Modal.Header>
        {selectedItemId ? "Modifier" : "Ajouter"} une garantie
      </Modal.Header>
      <Modal.Content>
        <Modal.Description className="ui form">
          <div className="mt-4">
            <Form.Field required error={"name" in errors}>
              <label>Nom de la garantie</label>
              <Input
                type="text"
                name="name"
                value={values.name}
                onChange={handleChange("name")}
                onBlur={handleBlur("name")}
              />
              <small className="field-error">{errors.name}</small>
            </Form.Field>
            <Form.Field required error={"code" in errors}>
              <label>Code de la garantie</label>
              <input
                type="text"
                name="code"
                value={values.code}
                onChange={handleChange("code")}
                onBlur={handleBlur("code")}
              />
              <small className="field-error">{errors.code}</small>
            </Form.Field>
            <Form.Field required error={"is_active" in errors}>
              <Checkbox
                toggle
                label="Actif"
                name="is_active"
                checked={values.is_active}
                onChange={(e, { checked }) => {
                  formik.setFieldValue("is_active", checked ? 1 : 0);
                }}
              />
            </Form.Field>
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          onClick={() => {
            handleCloseModal();
          }}
        >
          Annuler
        </Button>
        <Button
          content="Enregistrer"
          icon="save"
          onClick={handleSubmit}
          positive
          type="submit"
          loading={isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default GarantiesComplementairesModalForm;
